import "./DashboardStatistics.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { TimeValueGraph } from "../time-value-graph/TimeValueGraph";
import AgencyOwnerApi, {
  GlobalAgencyStats,
  UserGrowthStats,
} from "../../api/AgencyOwnerApi";
import {
  PropertyCountByTypeStats,
  PropertyViewStats,
} from "../../api/AdminApi";
import PropertyApi from "../../api/PropertyApi";
import { BarGraph } from "../bar-graph/BarGraph";

ChartJS.register(
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export function DashboardStatistics() {
  const { t } = useTranslation();

  const [globalStats, setGlobalStats] = useState<GlobalAgencyStats>({
    numberOfProperties: 0,
    numberOfUsers: 0,
    numberOfRegisteredClients: 0,
  });
  const [userGrowthStats, setUserGrowthStats] = useState<UserGrowthStats>({
    data: [],
  });
  const [propertyViewStats, setPropertyViewStats] = useState<PropertyViewStats>(
    {
      data: [],
    }
  );
  const [propertyCountByTypeViewStats, setPropertyCountByTypeViewStats] =
    useState<PropertyCountByTypeStats>({
      data: [],
    });

  useEffect(() => {
    AgencyOwnerApi.getGlobalStats().then((g) => {
      setGlobalStats(g);
    });
    AgencyOwnerApi.getUserGrowthStats().then((d) => setUserGrowthStats(d));
    PropertyApi.getPropertyViewStats().then((d) => setPropertyViewStats(d));
    PropertyApi.getCountPerType().then((d) =>
      setPropertyCountByTypeViewStats(d)
    );
  }, []);

  return (
    <div className={"admin-statistics"}>
      <div className={"statistics"}>
        <div className={"item"}>
          <div>
            <div className={"title"}>{t("statistics.nrProperties")}</div>
            <div className={"value"}>{globalStats.numberOfProperties}</div>
          </div>
        </div>
        <div className={"item"}>
          <div>
            <div className={"title"}>{t("statistics.nrUsers")}</div>
            <div className={"value"}>{globalStats.numberOfUsers}</div>
          </div>
        </div>
        <div className={"item"}>
          <div>
            <div className={"title"}>{t("statistics.nrClients")}</div>
            <div className={"value"}>
              {globalStats.numberOfRegisteredClients}
            </div>
          </div>
        </div>
      </div>
      <div className={"statistics"}>
        <div className={"item"}>
          <TimeValueGraph
            stats={userGrowthStats}
            title={t("graphs.userGrowth")}
          />
        </div>
        <div className={"item"}>
          <TimeValueGraph
            stats={propertyViewStats}
            title={t("graphs.propertyViews")}
            noLabels={true}
            cumulative={false}
          />
        </div>
      </div>
      <div className={"statistics"}>
        <div className={"item"}>
          <BarGraph
            stats={propertyCountByTypeViewStats}
            title={t("graphs.registrationPerProperty")}
          />
        </div>
      </div>
    </div>
  );
}
