import React, { useEffect, useState } from "react";
import "./AddPropertyExternal.css";
import { PropertyForm } from "../../components/property-form/PropertyForm";
import { useParams } from "react-router-dom";
import ExternalPropertyApi from "../../api/ExternalPropertyApi";
import { ExternalPropertyItem } from "../../api/ExternalPropertyTypes";
import { getUser } from "../../util/getUser";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { t } from "i18next";

export function AddPropertyExternal() {
  const { propertyId } = useParams();
  const [externalProperty, setExternalProperty] = useState<
    ExternalPropertyItem | undefined
  >(undefined);

  useEffect(() => {
    const user = getUser();
    if (!user) {
      return;
    }

    const agencyId = user.agencyId;
    const fetchExternalProperty = async () => {
      if (propertyId) {
        try {
          const data = await ExternalPropertyApi.getById(agencyId, propertyId);
          setExternalProperty(data);
        } catch (error) {
          toast.error(t("general.somethingWrong"));
          Sentry.captureException(error);
        }
      }
    };
    fetchExternalProperty();
  }, [propertyId]);

  return (
    <div className="page page-content-no-margin">
      <div className="form-container">
        <div className="form-wrapper">
          <PropertyForm property={externalProperty} />
        </div>
      </div>
    </div>
  );
}
