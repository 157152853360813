import React, { useEffect, useState } from "react";
import "./DocumentPreview.css";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { PropertyDocument } from "../property-form/PropertyForm";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";

export function DocumentPreview({
  file,
  setFiles,
  index,
}: {
  file: PropertyDocument;
  setFiles: React.Dispatch<React.SetStateAction<PropertyDocument[]>>;
  index: number;
}) {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (file instanceof File) {
      setUrl(file.name);
    } else {
      setUrl(file.url);
    }
  }, [file]);

  return (
    <div className={"document-preview"}>
      {url ? (
        <>
          <Cross
            className={"remove-button"}
            onClick={() => {
              setFiles((prevState) =>
                prevState.filter((f) => {
                  if (f instanceof File && file instanceof File) {
                    return f.name !== file.name;
                  }
                  return f !== file;
                })
              );
            }}
          />
          {file instanceof File ? (
            url
          ) : (
            <a href={url} target={"_blank"}>
              {capitalizeFirstLetter(file.type.replaceAll("_", " ")) +
                ` (${index + 1})`}
            </a>
          )}
        </>
      ) : null}
    </div>
  );
}
