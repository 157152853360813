import React, { useRef } from "react";
import "./Textarea.css";

export function Textarea({
  className,
  value,
  onChange,
  placeholder,
  required,
  image,
  alt,
  disabled,
  rows,
}: {
  className?: string;
  value: string;
  onChange: (v: string) => void;
  placeholder: string;
  required: boolean;
  image?: string;
  alt?: string;
  disabled?: boolean;
  rows?: number;
}) {
  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <div className="textarea-with-img">
      {image ? <img className="textarea-img" src={image} alt={alt} /> : null}
      <textarea
        ref={ref}
        className={`form-field ${className ? className : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        rows={rows || 10}
        cols={55}
        required={required}
        disabled={disabled}
      />
      {!image && (
        <label
          className={"label"}
          onClick={() => {
            if (ref.current) {
              ref.current.focus();
            }
          }}
        >
          {placeholder} {required ? "*" : ""}
        </label>
      )}
    </div>
  );
}
