import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCaretRight } from "react-icons/rx";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { FilterName, getFilterI18nKey } from "../../enums/FilterName";
import { BooleanRadioGroup } from "./BooleanRadioGroup";
import { RangeSlider } from "./RangeSlider";
import { Range } from "./Range";
import FilterValueApi, { FilterValues } from "../../api/FilterValueApi";
import { MultiSelect, MultiSelectItem } from "./MultiSelect";
import "./PropertyFilter.css";
import { getInitialMinAndMax } from "../../util/filters";
import { Garden } from "../../enums/Garden";
import { Garage } from "../../enums/Garage";
import { getOrientationLabel, Orientation } from "../../enums/Orientation";
import { SingleSelect } from "./SingleSelect";
import { Pool } from "../../enums/Pool";

type SelectItem = {
  label: string;
  value: string;
};

function FilterComponent({
  className,
  filterName,
  filterParams,
  filterValues,
  setFilterParams,
}: {
  className: string;
  filterName: FilterName;
  filterParams: PropertyFilterParams;
  filterValues: FilterValues;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
}) {
  const { t } = useTranslation();

  let filterComponent: JSX.Element;

  const filterValue = filterParams[filterName];

  switch (filterName) {
    case FilterName.FavouritesOnly:
      filterComponent = (
        <BooleanRadioGroup
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          initialFilterValue={filterValue === "true"}
        />
      );
      break;

    case FilterName.Price: {
      const [initialMin, initialMax] = getInitialMinAndMax(filterValue);
      filterComponent = (
        <RangeSlider
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          totalMin={filterValues.priceMin}
          totalMax={filterValues.priceMax}
          initialMin={initialMin}
          initialMax={initialMax}
        />
      );
      break;
    }

    case FilterName.Bedrooms: {
      const [initialMin, initialMax] = getInitialMinAndMax(filterValue);
      filterComponent = (
        <Range
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          totalMin={filterValues.bedroomsMin}
          totalMax={filterValues.bedroomsMax}
          initialMin={initialMin}
          initialMax={initialMax}
        />
      );
      break;
    }

    case FilterName.Bathrooms: {
      const [initialMin, initialMax] = getInitialMinAndMax(filterValue);
      filterComponent = (
        <Range
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          totalMin={filterValues.bathroomsMin}
          totalMax={filterValues.bathroomsMax}
          initialMin={initialMin}
          initialMax={initialMax}
        />
      );
      break;
    }

    case FilterName.Agency: {
      const agencyIds = filterParams[FilterName.Agency]?.split(",") || [];
      const items: SelectItem[] = filterValues.agencies.map((agency) => ({
        label: agency.name,
        value: agency.id,
      }));
      filterComponent = (
        <MultiSelect
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          items={items}
          initialSelectedValues={agencyIds}
        />
      );
      break;
    }

    case FilterName.Orientation: {
      const orientationValues = Object.values(Orientation);
      const items: SelectItem[] = orientationValues.map((orientation) => ({
        label: getOrientationLabel(orientation),
        value: orientation,
      }));
      filterComponent = (
        <MultiSelect
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          items={items}
          initialSelectedValues={filterValue?.split(",") || []}
        />
      );
      break;
    }

    case FilterName.Garden: {
      const gardenValues = Object.values(Garden);
      const items: SelectItem[] = gardenValues.map((garden) => ({
        label: t(`enums.Garden.${garden}`),
        value: garden,
      }));
      filterComponent = (
        <MultiSelect
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          items={items}
          initialSelectedValues={filterValue?.split(",") || []}
        />
      );
      break;
    }

    case FilterName.Garage: {
      const garageValues = Object.values(Garage);
      const items: SelectItem[] = garageValues.map((garage) => ({
        label: t(`enums.Garage.${garage}`),
        value: garage,
      }));
      filterComponent = (
        <MultiSelect
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          items={items}
          initialSelectedValues={filterValue?.split(",") || []}
        />
      );
      break;
    }

    case FilterName.Pool: {
      const poolValues = Object.values(Pool);
      const items: SelectItem[] = poolValues.map((pool) => ({
        label: t(`enums.Pool.${pool}`),
        value: pool,
      }));
      filterComponent = (
        <MultiSelect
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          items={items}
          initialSelectedValues={filterValue?.split(",") || []}
        />
      );
      break;
    }

    case FilterName.GeneralType: {
      const generalTypes =
        filterParams[FilterName.GeneralType]?.split(",") || [];
      const items: SelectItem[] = Object.keys(filterValues.types).map(
        (type) => ({
          label: type,
          value: type,
        })
      );

      const subItems: Record<string, MultiSelectItem[]> = Object.entries(
        filterValues.types
      ).reduce(
        (acc, [type, subTypes]) => ({
          ...acc,
          [type]: subTypes.map((subType) => ({
            label: subType,
            value: subType,
          })),
        }),
        {}
      );

      filterComponent = (
        <MultiSelect
          filterName={filterName}
          subFilterName={FilterName.SubType}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          subItems={subItems}
          items={items}
          initialSelectedValues={generalTypes}
        />
      );
      break;
    }

    case FilterName.Region: {
      const items: SelectItem[] = filterValues.regions.map((region) => ({
        label: region,
        value: region,
      }));

      const subItems: SelectItem[] = filterParams[filterName]
        ? filterValues.subregions[filterParams[filterName] as string].map(
            (subType) => ({
              label: subType,
              value: subType,
            })
          )
        : [];

      filterComponent = (
        <SingleSelect
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          items={items}
          initialSelectedValue={filterValue}
          includeDefault={true}
          includeSubselect={true}
          subItems={subItems}
          subFilterName={FilterName.SubRegion}
        />
      );
      break;
    }

    case FilterName.InteriorSurface: {
      const [initialMin, initialMax] = getInitialMinAndMax(filterValue);
      filterComponent = (
        <RangeSlider
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          totalMin={filterValues.interiorSurfaceMin}
          totalMax={filterValues.interiorSurfaceMax}
          initialMin={initialMin}
          initialMax={initialMax}
          stepSize={10}
        />
      );
      break;
    }

    case FilterName.TotalSurface: {
      const [initialMin, initialMax] = getInitialMinAndMax(filterValue);
      filterComponent = (
        <RangeSlider
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          totalMin={filterValues.totalSurfaceMin}
          totalMax={filterValues.totalSurfaceMax}
          initialMin={initialMin}
          initialMax={initialMax}
          stepSize={10}
        />
      );
      break;
    }

    case FilterName.TerraceSurface: {
      const [initialMin, initialMax] = getInitialMinAndMax(filterValue);
      filterComponent = (
        <RangeSlider
          filterName={filterName}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          totalMin={filterValues.terraceSurfaceMin}
          totalMax={filterValues.terraceSurfaceMax}
          initialMin={initialMin}
          initialMax={initialMax}
          stepSize={10}
        />
      );
      break;
    }

    default:
      filterComponent = <div>Unknown filter</div>;
  }

  return <div className={className}>{filterComponent}</div>;
}

export function PropertyFilter({
  filterName,
  filterParams,
  setFilterParams,
  collapsable = false,
}: {
  filterName: FilterName;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
  collapsable?: boolean;
}) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(!collapsable);

  const i18nKey = getFilterI18nKey(filterName);

  const [filterValues, setFilterValues] = useState<FilterValues>();

  function onClick() {
    if (collapsable) {
      setCollapsed(!collapsed);
    }
  }

  useEffect(() => {
    FilterValueApi.getFilterValues().then((filterValues) => {
      setFilterValues(filterValues);
    });
  }, []);

  return (
    <div className="property-filter">
      <div className="filter-name-wrapper" onClick={onClick}>
        <div className="filter-name">{t(i18nKey)}</div>
        {collapsable && (
          <RxCaretRight
            className={`filter-caret ${collapsed ? "collapsed" : ""}`}
          />
        )}
      </div>
      <div style={{ display: `${collapsed ? "block" : "none"}` }}>
        {filterValues && (
          <FilterComponent
            className={`filter-component ${collapsed ? "" : "hidden"} ${
              collapsable ? "collapsable" : ""
            }`}
            filterName={filterName}
            filterParams={filterParams}
            filterValues={filterValues}
            setFilterParams={setFilterParams}
          />
        )}
      </div>
    </div>
  );
}
