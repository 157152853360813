import React, { useEffect, useState } from "react";
import "./Payment.css";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import PaymentApi from "../../api/PaymentApi";
import Logo from "../../assets/logo.svg";
import { Link, useSearchParams } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Config } from "../../Config";

const stripePromise = loadStripe(Config.getStripePublicKey());

export function Payment() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    const tier = searchParams.get("tier");

    if (!tier) {
      return;
    }

    PaymentApi.checkout(tier).then((result) => {
      setOptions(result);
    });
  }, [searchParams]);

  return (
    <div className="payment-upper-container flex">
      <div className="payment-container flex">
        <div className="payment-form flex flex-column">
          <img className="payment-logo" src={Logo} alt={"logo"} />
          {options ? (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret: options.client_secret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          ) : null}
          <Link to={routes.paymentAndTierSelection} className="payment-back">
            {t("payments.goBack")}
          </Link>
        </div>
      </div>
    </div>
  );
}
