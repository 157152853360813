export function addThousandSeparators(value: string): string {
  if (value === "") return value;

  const valueWithCommaDecimalSeparator = value.replace(".", ",");

  // add thousand separators to the value, only before the decimal point
  const indexOfDecimal = valueWithCommaDecimalSeparator.indexOf(",");
  const [integerPart, decimalPart] = valueWithCommaDecimalSeparator.split(",");
  const integerPartWithSeparators = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    "."
  );

  return decimalPart ||
    indexOfDecimal === valueWithCommaDecimalSeparator.length - 1
    ? `${integerPartWithSeparators},${decimalPart}`
    : integerPartWithSeparators;
}
