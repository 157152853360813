import "./InvoicesList.css";

import i18n from "../../i18n";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { Invoice } from "../../api/InvoicesApi";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants/routes";

export type CategoryType = "NONE" | "DATE" | "PRICE" | "SUBJECT";

export function InvoicesList({ invoices }: { invoices: Invoice[] }) {
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
  });

  const categoryTypeValues: CategoryType[] = ["DATE", "PRICE", "SUBJECT"];

  return (
    <div className={"invoice-list"}>
      <NavLink
        className="custom-button primary fit-content ml-auto"
        to={routes.subscriptionSettings}
      >
        {t("settings.updateSubscription")}
      </NavLink>
      <div className={"header"}>
        <div className={"items"}>
          {categoryTypeValues.map((el, index) => {
            return (
              <div className={"item"} key={index}>
                {capitalizeFirstLetter(el)}
              </div>
            );
          })}
          <div className={"item"}>Download</div>
        </div>
      </div>
      {invoices.length <= 0 ? (
        <div className={"flex justify-center mt-4"}>{t("invoices.noData")}</div>
      ) : (
        invoices.map((el, index) => {
          return (
            <div key={index} className={"invoice-item"}>
              <div className={"content"}>
                <div className={"item"}>
                  {DateTime.fromISO(el.datetime).toFormat("d/L/y h:mm")}
                </div>
                <div className={"item"}>{formatter.format(el.price / 100)}</div>
                <div className={"item"}>
                  {capitalizeFirstLetter(el.tierType)}
                </div>
                <div className={"item"}>
                  <a download href={el.invoiceUrl}>
                    {t("invoices.download")}
                  </a>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
