import React, { useMemo, useState } from "react";
import "./Registration.css";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as Checkmark } from "../../assets/checkmark.svg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Input } from "../../components/input/Input";
import UserApi from "../../api/UserApi";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { countries } from "../../constants/Countries";
import { Select } from "../../components/select/Select";
import { regions } from "../../constants/Regions";
import { Button } from "../../components/button/Button";
import { isValidPhoneNumber } from "../../util/isValidPhoneNumber";
import { AxiosError } from "axios";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";

export function Registration() {
  const { t } = useTranslation();

  const defaultRegions: string[] = useMemo(
    () => Object.keys(regions).sort(),
    []
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState<string>("ES");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [agencyName, setAgencyName] = useState("");
  const [agencyEmail, setAgencyEmail] = useState("");
  const [agencyReference, setAgencyReference] = useState("");
  const [agencyStreet, setAgencyStreet] = useState("");
  const [agencyNumber, setAgencyNumber] = useState("");
  const [agencyCity, setAgencyCity] = useState("");
  const [agencyCountryCode, setAgencyCountryCode] = useState("ES");
  const [agencyVat, setAgencyVat] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [defaultRegion, setDefaultRegion] = useState<string>(
    defaultRegions[12]
  );

  async function onFormSubmit() {
    try {
      if (!isValidPhoneNumber(phoneNumber)) {
        toast(t("registration.invalidPhoneNumber"), { type: "error" });
        return;
      }

      await toast.promise(
        UserApi.createAgencyOwner(
          capitalizeFirstLetter(firstName),
          lastName,
          nationality,
          phoneNumber,
          email,
          agencyName,
          agencyReference,
          agencyEmail,
          agencyStreet,
          agencyNumber,
          agencyCity,
          agencyCountryCode,
          agencyVat,
          defaultRegion
        ),
        {
          pending: t("registration.creating"),
          error: t("general.somethingWrong"),
        }
      );
      setFormSubmitted(true);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 400) {
        // show additional toast with cause of error
        toast.error(t("registration.invalidEmail"));
      }
    }
  }
  return (
    <div className={"registration-upper-container flex"}>
      <div className={"registration-container flex"}>
        <div className={"registration-form"}>
          <Logo />
          {formSubmitted ? (
            <div className={"confirm-message"}>
              <div className={"checkmark"}>
                <Checkmark />
              </div>
              <div>{t("registration.confirmMessage")}</div>
            </div>
          ) : (
            <>
              <div className={"title"}>{t("registration.signup")}</div>
              <div>{t("registration.disclaimer")}</div>
              <form
                className={"form"}
                onSubmit={async (e) => {
                  e.preventDefault();
                  await onFormSubmit();
                }}
              >
                <div className={"subtitle"}>
                  {t("registration.agencyInformation")}
                </div>
                <Input
                  value={agencyName}
                  onChange={setAgencyName}
                  placeholder={t("registration.agencyName")}
                  required={true}
                  type="text"
                />
                <Input
                  value={agencyEmail}
                  onChange={setAgencyEmail}
                  placeholder={t("registration.agencyEmail")}
                  required={true}
                  type="email"
                />
                <div className={"horizontal-input"}>
                  <Select
                    value={defaultRegion}
                    onChange={setDefaultRegion}
                    label={t("registration.defaultRegion")}
                    required={true}
                    options={defaultRegions.map((region: string) => {
                      return {
                        label: region,
                        value: region,
                      };
                    })}
                  />
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    value={agencyStreet}
                    onChange={setAgencyStreet}
                    placeholder={t("registration.street")}
                    required={true}
                    type="text"
                  />
                  <Input
                    value={agencyNumber}
                    onChange={setAgencyNumber}
                    placeholder={t("registration.number")}
                    required={true}
                    type="text"
                  />
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    value={agencyCity}
                    onChange={setAgencyCity}
                    placeholder={t("registration.city")}
                    required={true}
                    type="text"
                  />
                  <Select
                    value={agencyCountryCode}
                    onChange={setAgencyCountryCode}
                    label={t("registration.country")}
                    required={true}
                    options={countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                  />
                </div>
                <Input
                  value={agencyVat}
                  onChange={setAgencyVat}
                  placeholder={t("registration.agencyVAT")}
                  required={true}
                  type="text"
                />
                <div className={"subtitle mt-4"}>
                  {t("registration.agencyOwnerInformation")}
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    value={firstName}
                    onChange={setFirstName}
                    placeholder={t("registration.firstName")}
                    required={true}
                    type="text"
                  />
                  <Input
                    value={lastName}
                    onChange={setLastName}
                    placeholder={t("registration.lastName")}
                    required={true}
                    type="text"
                  />
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    placeholder={t("registration.phoneNumber")}
                    required={true}
                    type="tel"
                  />
                  <Select
                    value={nationality}
                    onChange={setNationality}
                    label={t("registration.nationality")}
                    required={true}
                    options={countries.map((country) => {
                      return {
                        label: country.name,
                        value: country.code,
                      };
                    })}
                  />
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    value={email}
                    onChange={setEmail}
                    placeholder={t("registration.email")}
                    required={true}
                    type="email"
                  />
                </div>
                <Button className="mt-4" type="submit" fullWidth>
                  {t("registration.signup")}
                </Button>
              </form>
              <Link
                className={"login-link mt-4 flex justify-center"}
                to={routes.login}
              >
                {t("registration.alreadyAccount")}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
