import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoFilterSharp } from "react-icons/io5";
import PropertyApi from "../../api/PropertyApi";
import Dialog from "../../components/dialog/Dialog";
import { PropertyFilters } from "../../components/property-filters/PropertyFilters";
import { PropertyGrid } from "../../components/property-grid/PropertyGrid";
import {
  LayoutType,
  PropertyList,
} from "../../components/property-list/PropertyList";
import { getFilterParamsFromUrl } from "../../util/filters";
import "./Properties.css";
import { Config } from "../../Config";
import { PropertyItem } from "../../api/PropertyTypes";
import { SortingConfig } from "../../interfaces/SortingConfig";

export function Properties() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const { t } = useTranslation();
  const [properties, setProperties] = useState<PropertyItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [layoutType, setLayoutType] = useState<LayoutType>(LayoutType.GRID);
  const [filterModalShown, setFilterModalShown] = useState(false);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const filterParams = getFilterParamsFromUrl();
    PropertyApi.list(1, Config.getDefaultPageSize(), filterParams)
      .then((p) => {
        setLoading(false);
        setProperties(p.data);
        setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] < 1050) {
      setLayoutType(LayoutType.GRID);
    }
  }, [windowSize]);

  async function retrievePage(page: number, ref?: string) {
    if (Number.isNaN(page)) {
      return;
    }

    if (ref) {
      const property = await PropertyApi.getByRef(ref);
      setProperties(property ? [property] : []);
      setTotalPages(1);
      return;
    }

    const filterParams = getFilterParamsFromUrl();
    PropertyApi.list(
      page,
      Config.getDefaultPageSize(),
      filterParams,
      sortingConfig
    ).then((p) => {
      setProperties(p.data);
      setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
    });
  }

  return (
    <div className="properties-wrapper">
      <PropertyFilters
        className={"main"}
        setProperties={setProperties}
        setTotalPages={(val) => setTotalPages(val)}
      />
      <Dialog
        isOpen={filterModalShown}
        onRequestClose={() => setFilterModalShown(false)}
        title={t("filters.searchProperties")}
        size={"small"}
      >
        <PropertyFilters
          setProperties={(properties) => {
            setProperties(properties);
            setFilterModalShown(false);
          }}
          setTotalPages={setTotalPages}
          className={"mobile"}
        />
      </Dialog>
      <div className="properties-list-and-grid white-padded-box">
        <IoFilterSharp
          className="filter-icon"
          onClick={() => setFilterModalShown(true)}
        />
        {layoutType === LayoutType.LIST && (
          <PropertyList
            properties={properties}
            sortingConfig={sortingConfig}
            setSortingConfig={setSortingConfig}
            retrievePage={(page: number, _, ref?: string) =>
              retrievePage(page, ref)
            }
            totalPages={totalPages}
            enableLayoutSwitch={true}
            enableRefSearch={true}
            layoutType={layoutType}
            setLayoutType={setLayoutType}
            loading={loading}
          />
        )}
        {layoutType === LayoutType.GRID && (
          <PropertyGrid
            properties={properties}
            retrievePage={(page: number, ref?: string) =>
              retrievePage(page, ref)
            }
            totalPages={totalPages}
            enableLayoutSwitch={true}
            enableRefSearch={true}
            layoutType={layoutType}
            setLayoutType={setLayoutType}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}
