import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files
import translationEnglish from "./translations/English/translations.json";

const resources = {
  en: {
    translation: translationEnglish,
  },
};

function toLocaleFormat(code: string): string {
  switch (code) {
    default:
      return "en";
  }
}

i18next.use(initReactI18next).init({
  resources,
  lng: toLocaleFormat(navigator.language), //default language
});

export default i18next;
