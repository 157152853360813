import axios from "axios";
import { Config } from "../Config";
import { LocalStorage } from "../enums/LocalStorage";
import AuthApi from "./AuthApi";

class ApiInstance {
  public getApi() {
    const endpointsWithoutAuthentication = [
      "/auth/login",
      "/auth/request-password-reset",
    ];

    const instance = axios.create({
      baseURL: Config.getBackendUrl(),
      headers: {
        "Content-Type": "application/json",
      },
    });

    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem(LocalStorage.Token);
      const type = localStorage.getItem(LocalStorage.Type);

      if (!config.url) {
        return config;
      }

      if (
        token &&
        type &&
        !endpointsWithoutAuthentication.includes(config.url) &&
        config.headers
      ) {
        config.headers["Authorization"] = `${type} ${token}`;
      }

      return config;
    });

    instance.interceptors.response.use(
      (result) => {
        return Promise.resolve(result);
      },
      (error) => {
        if (
          error !== null &&
          error.response?.status === 401 &&
          !endpointsWithoutAuthentication.includes(error.response.config.url)
        ) {
          AuthApi.logout();
          window.location.href = "/login";
        } else {
          return Promise.reject(error);
        }
      }
    );

    //empty interceptor: no auth required.
    instance.interceptors.response.use();

    return instance;
  }
}

export default new ApiInstance();
