export const PropertyTypes: { [key: string]: string[] } = {
  Apartments: [
    "Apartment",
    "Duplex",
    "Duplex Penthouse",
    "Flat",
    "Ground Floor Apartment",
    "Ground Floor Duplex",
    "Loft",
    "Penthouse",
    "Studio",
    "Triplex",
  ],
  Commercials: [
    "Bar",
    "Building",
    "Business",
    "Commercial Premises",
    "Development Land",
    "Discotheque",
    "Golf Course",
    "Golf Plot",
    "Hotel",
    "Hotel Plot",
    "Industrial Land",
    "Industrial Premises",
    "Investment",
    "Office",
    "Office Units",
    "Residential Plot",
    "Restaurant",
    "Rustic Plot",
    "Shop",
    "Shopping Centre",
    "Supermarket",
  ],
  "Country Property": ["Cortijo", "Country House", "Estate", "Finca"],
  Houses: ["House", "Semi Detached House", "Town House"],
  "Plots and lands": ["Plot"],
  "Unique buildings": ["Castle", "Palace", "Riad", "Unique Building"],
  Villas: ["Bungalow", "Chalet", "Mansion", "Semi Detached Villa", "Villa"],
  Other: ["Boat", "Mooring", "Parking", "Store Room"],
};
export const PropertySubTypesWithoutBeds = [
  "Bar",
  "Building",
  "Business",
  "Commercial Premises",
  "Development Land",
  "Discotheque",
  "Golf Course",
  "Golf Plot",
  "Hotel Plot",
  "Industrial Land",
  "Industrial Premises",
  "Investment",
  "Office",
  "Office Units",
  "Residential Plot",
  "Restaurant",
  "Rustic Plot",
  "Shop",
  "Shopping Centre",
  "Supermarket",
  "Plot",
  "Boat",
  "Mooring",
  "Parking",
  "Store Room",
];
