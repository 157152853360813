import React from "react";
import "./Navigation.css";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";
import { ReactComponent as DoubleArrowLeft } from "../../assets/double-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as DoubleArrowRight } from "../../assets/double-arrow-right.svg";
import { ReactComponent as ArrowLeftGrayed } from "../../assets/arrow-left-grayed.svg";
import { ReactComponent as DoubleArrowLeftGrayed } from "../../assets/double-arrow-left-grayed.svg";
import { ReactComponent as ArrowRightGrayed } from "../../assets/arrow-right-grayed.svg";
import { ReactComponent as DoubleArrowRightGrayed } from "../../assets/double-arrow-right-grayed.svg";
import { useTranslation } from "react-i18next";

export function Navigation({
  className,
  currentPage,
  setCurrentPage,
  totalPages,
}: {
  className?: string;
  currentPage: number;
  setCurrentPage: (newNumber: number) => void;
  totalPages: number;
}) {
  const { t } = useTranslation();

  return totalPages < 1 ? null : (
    <div className={`navigation ${className ?? ""}`}>
      <div className={"pagination"}>
        <div className={"icon"} onClick={() => setCurrentPage(1)}>
          {currentPage > 1 ? <DoubleArrowLeft /> : <DoubleArrowLeftGrayed />}
        </div>
        <div
          className={"icon"}
          onClick={() =>
            setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
          }
        >
          {currentPage > 1 ? <ArrowLeft /> : <ArrowLeftGrayed />}
        </div>
        <div className={"content"}>
          <input
            required={true}
            value={currentPage.toString()}
            placeholder={"1"}
            onChange={(e) => {
              let val = parseInt(e.target.value);

              if (val < 1) {
                val = 1;
              } else if (val > totalPages) {
                val = totalPages;
              }
              setCurrentPage(val);
            }}
            type={"number"}
          />
          {t("navigation.of")}
          <div>{totalPages}</div>
        </div>
        <div
          className={"icon"}
          onClick={() =>
            setCurrentPage(
              currentPage < totalPages ? currentPage + 1 : currentPage
            )
          }
        >
          {currentPage < totalPages ? <ArrowRight /> : <ArrowRightGrayed />}
        </div>
        <div className={"icon"} onClick={() => setCurrentPage(totalPages)}>
          {currentPage < totalPages ? (
            <DoubleArrowRight />
          ) : (
            <DoubleArrowRightGrayed />
          )}
        </div>
      </div>
    </div>
  );
}
