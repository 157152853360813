import React, { useEffect, useState } from "react";
import "./ImagePreview.css";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { PropertyPicture } from "../property-form/PropertyForm";

export function ImagePreview({
  file,
  setFiles,
}: {
  file: File | string;
  setFiles: React.Dispatch<React.SetStateAction<PropertyPicture[]>>;
}) {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (file instanceof File) {
      const fr = new FileReader();
      fr.onload = () => setUrl(fr.result?.toString() || "");
      fr.readAsDataURL(file);
    } else {
      setUrl(file);
    }
  }, [file]);

  return (
    <div className={"image-preview"}>
      {url ? (
        <>
          <img src={url} />
          <Cross
            className={"remove-button"}
            onClick={() => {
              setFiles((prevState) =>
                prevState.filter((f) => {
                  if (f instanceof File && file instanceof File) {
                    return f.name !== file.name;
                  } else if (
                    typeof f === "string" &&
                    typeof file === "string"
                  ) {
                    return f !== file;
                  } else {
                    return true;
                  }
                })
              );
            }}
          />
        </>
      ) : null}
    </div>
  );
}
