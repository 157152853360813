import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import { PropertyStatus } from "../api/PropertyTypes";

export function formatPropertyStatus(
  status: PropertyStatus,
  fullStatus = false
): string {
  if (
    status === PropertyStatus.SOLD_VISIBLE ||
    status === PropertyStatus.SOLD_ARCHIVED
  ) {
    if (fullStatus) {
      return capitalizeFirstLetter(status.replaceAll("_", " "));
    }
    return capitalizeFirstLetter(status.split("_")[0]);
  }

  return capitalizeFirstLetter(status);
}
