import React, { useState } from "react";
import { Input } from "../input/Input";
import { Select } from "../select/Select";
import { countries } from "../../constants/Countries";
import { useTranslation } from "react-i18next";
import "./ProfileForm.css";
import { AccountData } from "../../api/AccountApi";
import { toast } from "react-toastify";
import { Button } from "../button/Button";
import { isValidPhoneNumber } from "../../util/isValidPhoneNumber";
import { User } from "../../interfaces/UserTypes";

export function ProfileForm({
  profile,
  onSubmit,
}: {
  profile: User;
  onSubmit: (accountData: AccountData) => void;
}) {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>(profile.firstName);
  const [lastName, setLastName] = useState<string>(profile.lastName);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    profile.phoneNumber || ""
  );
  const [nationality, setNationality] = useState<string>(profile.nationality);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isRevealedPassword, setIsRevealedPassword] = useState<boolean>(false);

  const canSubmit =
    firstName !== profile.firstName ||
    lastName !== profile.lastName ||
    phoneNumber.trim() !== (profile.phoneNumber || "") ||
    nationality !== profile.nationality ||
    (password.trim() !== "" && confirmPassword.trim() !== "");

  return (
    <div>
      <form
        className={"profile-form"}
        onSubmit={async (e) => {
          e.preventDefault();
          if (!isValidPhoneNumber(phoneNumber)) {
            toast(t("clientForm.invalidPhoneNumber"), { type: "error" });
            return;
          }

          const toastId = "passwordReset.passwordDitNotMatch";
          if (password !== confirmPassword) {
            toast(t(toastId), {
              type: "error",
              toastId: toastId,
            });
            return;
          }

          toast.dismiss(toastId);
          await onSubmit({
            firstName,
            lastName,
            phoneNumber,
            nationality,
            password,
          });

          setPassword("");
          setConfirmPassword("");
        }}
      >
        <div className={"horizontal-row"}>
          <Input
            value={firstName}
            onChange={setFirstName}
            placeholder={t("registration.firstName")}
            required={true}
            type="text"
          />
          <Input
            value={lastName}
            onChange={setLastName}
            placeholder={t("registration.lastName")}
            required={true}
            type="text"
          />
        </div>
        <div className={"horizontal-row"}>
          <Input
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder={t("registration.phoneNumber")}
            required={true}
            type="tel"
          />
          <Select
            value={nationality}
            onChange={setNationality}
            label={t("registration.nationality")}
            required={true}
            options={countries.map((country) => {
              return {
                label: country.name,
                value: country.code,
              };
            })}
          />
        </div>
        <div className={"half-row"}>
          <Input
            value={profile.email}
            onChange={() => {
              return;
            }}
            placeholder={t("registration.email")}
            required={true}
            type={"email"}
            readOnly={true}
          />
        </div>
        <div className={"half-row"}>
          <Input
            value={password}
            onChange={setPassword}
            placeholder={t("registration.password")}
            required={false}
            type={isRevealedPassword ? "text" : "password"}
            showPasswordIcon={true}
            isRevealedPassword={isRevealedPassword}
            setIsRevealedPassword={setIsRevealedPassword}
            minLength={8}
          />
        </div>
        <div className={"half-row mb-4"}>
          <Input
            value={confirmPassword}
            onChange={setConfirmPassword}
            placeholder={t("registration.confirmPassword")}
            required={false}
            type={isRevealedPassword ? "text" : "password"}
            showPasswordIcon={true}
            isRevealedPassword={isRevealedPassword}
            setIsRevealedPassword={setIsRevealedPassword}
            minLength={8}
          />
        </div>
        <Button className="mt-4" type="submit" disabled={!canSubmit}>
          {t("profileForm.update")}
        </Button>
      </form>
    </div>
  );
}
