import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import RegistrationApi from "../../api/RegistrationApi";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import "./ClientRegistrationConfirm.css";

export function ClientRegistrationConfirm() {
  const { t } = useTranslation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { token: confirmToken, registrationId } = Object.fromEntries(
    urlSearchParams.entries()
  );

  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    RegistrationApi.confirmClient(registrationId, confirmToken)
      .then(() => {
        setLoading(false);
        setConfirmed(true);
      })
      .catch(() => {
        setLoading(false);
        setConfirmed(false);
      });
  }, []);

  return (
    <div className="client-confirm-upper-container flex">
      <div className="client-confirm-container flex">
        <div className={"client-confirm"}>
          <Logo />
          {!registrationId || !confirmToken ? (
            <div className={"description error"}>
              {t("completeAccount.invalidLink")}
            </div>
          ) : loading ? (
            <div className={"description"}>
              {t("clientRegistration.loading")}
            </div>
          ) : confirmed ? (
            <div className={"description"}>
              {t("clientRegistration.confirmed")}
            </div>
          ) : (
            <div className={"description error"}>
              {t("clientRegistration.error")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
