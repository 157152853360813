import "./CompleteAccount.css";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { Input } from "../../components/input/Input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AuthApi from "../../api/AuthApi";
import { routes } from "../../constants/routes";
import { Link, useNavigate } from "react-router-dom";
import {
  getCorrectPasswordString,
  isStrongPassword,
} from "../../util/isStrongPassword";
import { Select } from "../../components/select/Select";
import { countries } from "../../constants/Countries";
import jwt_decode from "jwt-decode";
import { LocalStorage } from "../../enums/LocalStorage";
import { Button } from "../../components/button/Button";
import { isValidPhoneNumber } from "../../util/isValidPhoneNumber";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { User } from "../../interfaces/UserTypes";
import { PasswordRequirements } from "../../components/password-requirements/PasswordRequirements";

export function CompleteAccount() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationality, setNationality] = useState<string>("ES");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRevealedPassword, setIsRevealedPassword] = useState(false);
  const [toastIds, setToastIds] = useState<string[]>([]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { token: confirmToken, email } = Object.fromEntries(
    urlSearchParams.entries()
  );

  async function onSubmit() {
    try {
      if (!isValidPhoneNumber(phoneNumber)) {
        toast(t("clientForm.invalidPhoneNumber"), { type: "error" });
        return;
      }

      if (password === confirmPassword) {
        const passwordStrength = isStrongPassword(password);

        if (!passwordStrength.ok) {
          const toastId = t(
            getCorrectPasswordString(passwordStrength)
          ) as string;
          toast(toastId, {
            type: "error",
            autoClose: 5000,
            position: "bottom-right",
          });

          return;
        }

        const toastId = toast.loading(t("completeAccount.pending"));
        const newToastIds = [...toastIds, toastId.toString()];
        setToastIds(newToastIds);
        const { token, type } = await AuthApi.completeAccount(
          capitalizeFirstLetter(firstName),
          lastName,
          nationality,
          phoneNumber,
          email,
          password,
          confirmToken
        );

        localStorage.setItem(LocalStorage.Token, token);
        localStorage.setItem(LocalStorage.Type, type);
        const user: User = jwt_decode(token);
        localStorage.setItem(LocalStorage.User, JSON.stringify(user));

        newToastIds.forEach((id) => toast.dismiss(id));
        navigate(routes.agencyHome);
      } else {
        const toastId = t("passwordReset.passwordDoNotMatch") as string;
        toast(toastId, {
          type: "error",
          toastId: toastId,
        });
        setToastIds((prevState) => [...prevState, toastId]);
      }
    } catch (e) {
      const toastId = t("general.somethingWrong") as string;
      toast(toastId, {
        type: "error",
        toastId: toastId,
      });
      setToastIds((prevState) => [...prevState, toastId]);
    }
  }

  return (
    <div className={"user-registration-upper-container flex"}>
      <div className={"user-registration-container flex"}>
        <div className={"user-registration-form"}>
          <Logo />
          {!email || !confirmToken ? (
            <>
              <div className={"description error"}>
                {t("completeAccount.invalidLink")}
              </div>
              <Link
                className="password-reset-link mt-4 flex justify-center"
                to={routes.login}
              >
                {t("accountConfirmation.returnLogin")}
              </Link>
            </>
          ) : (
            <>
              <div className={"title"}>{t("completeAccount.complete")}</div>
              <form
                className={"form"}
                onSubmit={async (e) => {
                  e.preventDefault();

                  await onSubmit();
                }}
              >
                <div className={"horizontal-input"}>
                  <Input
                    value={firstName}
                    onChange={setFirstName}
                    placeholder={t("registration.firstName")}
                    required={true}
                    type="text"
                  />
                  <Input
                    value={lastName}
                    onChange={setLastName}
                    placeholder={t("registration.lastName")}
                    required={true}
                    type="text"
                  />
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    placeholder={t("registration.phoneNumber")}
                    required={true}
                    type="tel"
                  />
                  <Select
                    value={nationality}
                    onChange={setNationality}
                    label={t("registration.nationality")}
                    required={true}
                    options={countries.map((country) => {
                      return {
                        label: country.name,
                        value: country.code,
                      };
                    })}
                  />
                </div>
                <div className={"horizontal-input"}>
                  <Input
                    className={"readonly"}
                    value={email}
                    onChange={() => {
                      return;
                    }}
                    placeholder={t("registration.email")}
                    required={true}
                    type={"email"}
                    readOnly={true}
                  />
                </div>
                <Input
                  className="password-input"
                  value={password}
                  onChange={setPassword}
                  placeholder={t("registration.password")}
                  required={true}
                  type={isRevealedPassword ? "text" : "password"}
                  showPasswordIcon={true}
                  isRevealedPassword={isRevealedPassword}
                  setIsRevealedPassword={setIsRevealedPassword}
                  minLength={8}
                ></Input>
                <Input
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  placeholder={t("registration.confirmPassword")}
                  required={true}
                  type={isRevealedPassword ? "text" : "password"}
                  showPasswordIcon={true}
                  isRevealedPassword={isRevealedPassword}
                  setIsRevealedPassword={setIsRevealedPassword}
                  minLength={8}
                />
                <PasswordRequirements password={password} className={"mt-4"} />
                <Button className="mt-4" type="submit" fullWidth>
                  {t("completeAccount.complete")}
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
