import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCaretRight } from "react-icons/rx";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import FilterCombinationApi, {
  FilterCombination,
} from "../../api/FilterCombinationApi";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { Button } from "../button/Button";
import Dialog from "../dialog/Dialog";
import "./FilterCombinations.css";
import { Input } from "../input/Input";

export function FilterCombinations({
  filterCombinations,
  setFilterCombinations,
  filterParams,
  setFilterParams,
  search,
  reset,
}: {
  filterCombinations: FilterCombination[];
  setFilterCombinations: Dispatch<SetStateAction<FilterCombination[]>>;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
  search: (params?: PropertyFilterParams) => void;
  reset: () => void;
}) {
  const { t } = useTranslation();

  const [combinationsOpen, setCombinationsOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [filterName, setFilterName] = React.useState("");
  const [selectedFilterCombinationId, setSelectedFilterCombinationId] =
    useState<string | null>(null);

  async function onSubmitFilterCombination(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    try {
      const newFilterCombination =
        await FilterCombinationApi.createFilterCombination({
          name: filterName,
          filterParams,
          version: "v1",
        });

      setFilterCombinations([...filterCombinations, newFilterCombination]);

      setSelectedFilterCombinationId(newFilterCombination.id);

      setDialogOpen(false);
      setFilterName("");

      setCombinationsOpen(true);

      toast.success(t("filters.filtersSaved", { name: filterName }));
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        toast.error(t("filters.errorNameAlreadyExists", { name: filterName }));
        return;
      }

      toast.error(t("filters.errorSaving"));
    }
  }

  async function onDeleteFilterCombination(id: string) {
    try {
      await FilterCombinationApi.deleteFilterCombination(id);
      setFilterCombinations(
        filterCombinations.filter(
          (filterCombination) => filterCombination.id !== id
        )
      );

      if (selectedFilterCombinationId === id) {
        setSelectedFilterCombinationId(null);
        setFilterParams({} as PropertyFilterParams);
        reset();
      }
    } catch (error) {
      toast.error(t("filters.errorDeleting"));
    }
  }

  return (
    <div className="filter-combinations">
      <div
        className="filter-combinations-title-wrapper"
        onClick={() => setCombinationsOpen(!combinationsOpen)}
      >
        <span className="filter-combinations-title">
          {t("filters.savedFilters")}
        </span>
        <RxCaretRight
          className={`filter-combinations-caret ${
            combinationsOpen ? "open" : ""
          }`}
        />
      </div>
      {combinationsOpen && (
        <div className="filter-combinations-content">
          {filterCombinations.map((filterCombination) => (
            <div
              className="filter-combinations-item pointer"
              key={filterCombination.id}
              onClick={() => {
                if (filterCombination.id === selectedFilterCombinationId) {
                  setSelectedFilterCombinationId(null);
                  setFilterParams({} as PropertyFilterParams);
                  reset();
                  return;
                }

                setSelectedFilterCombinationId(filterCombination.id);
                setFilterParams(filterCombination.filterParams);
                search(filterCombination.filterParams);
              }}
            >
              <input
                className="filter-combinations-checkbox"
                type="checkbox"
                checked={filterCombination.id === selectedFilterCombinationId}
                onChange={() => {
                  if (filterCombination.id === selectedFilterCombinationId) {
                    setSelectedFilterCombinationId(null);
                    setFilterParams({} as PropertyFilterParams);
                    reset();
                    return;
                  }

                  setSelectedFilterCombinationId(filterCombination.id);
                  setFilterParams(filterCombination.filterParams);
                  search(filterCombination.filterParams);
                }}
              />
              <span>{filterCombination.name}</span>
              <FaTrash
                className="filter-combinations-delete ml-auto"
                onClick={() => onDeleteFilterCombination(filterCombination.id)}
              />
            </div>
          ))}
        </div>
      )}
      <Button className="mt-4 ml-auto" onClick={() => setDialogOpen(true)}>
        {t("filters.saveFilters")}
      </Button>
      <Dialog
        isOpen={dialogOpen}
        onRequestClose={() => setDialogOpen(false)}
        title={t("filters.saveFilters")}
        size="xsmall"
      >
        <form
          className="save-filters-dialog"
          onSubmit={onSubmitFilterCombination}
        >
          <div className="mb-4">
            <Input
              type="text"
              value={filterName}
              onChange={(value) => setFilterName(value)}
              required={true}
              placeholder={t("filters.name")}
            />
          </div>
          <Button className="ml-auto" type="submit">
            {t("filters.save")}
          </Button>
        </form>
      </Dialog>
    </div>
  );
}
