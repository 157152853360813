import "./UserList.css";
import { useEffect, useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { getUser } from "../../util/getUser";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { dateToReadableFormat } from "../../util/dateToReadableFormat";
import { User, UserType } from "../../interfaces/UserTypes";

export type UserInfoType =
  | "NONE"
  | "FIRST_NAME"
  | "LAST_NAME"
  | "MAIL"
  | "PHONE_NUMBER"
  | "AGENCY"
  | "TYPE"
  | "CREATED_AT";

export function UserList({
  users,
  retrievePage,
  totalPages,
  deleteUser,
}: {
  users: User[];
  retrievePage: (page: number) => void;
  totalPages: number;
  deleteUser: (id: string) => void;
}) {
  const categoryTypeValues: UserInfoType[] = [
    "FIRST_NAME",
    "LAST_NAME",
    "MAIL",
    "PHONE_NUMBER",
    "AGENCY",
    "TYPE",
    "CREATED_AT",
  ];

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    retrievePage(currentPage);
  }, [currentPage]);

  const user = getUser();

  return (
    <div className={"user-list"}>
      <div className={"navigation"}>
        <Navigation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
      <div className="user-list-wrapper">
        <div className={"header"}>
          <div className={"items"}>
            {categoryTypeValues.map((el, index) => {
              return (
                <div
                  className={`item ${
                    el === "MAIL"
                      ? "large"
                      : el === "FIRST_NAME"
                      ? "small"
                      : "medium"
                  }`}
                  key={index}
                >
                  {capitalizeFirstLetter(el.replaceAll("_", " "))}
                </div>
              );
            })}
            <div className={"item small justify-center"}>
              {t("general.actions")}
            </div>
          </div>
        </div>
        {users.map((el, index) => {
          return (
            <div key={index} className={"user-item"}>
              <div className={"content"}>
                <div className={"item small"}>{el.firstName}</div>
                <div className={"item medium"}>{el.lastName}</div>
                <div className={"item large"}>{el.email}</div>
                <div className={"item medium"}>{el.phoneNumber}</div>
                <div className={"item medium"}>{el.agencyName}</div>
                <div className={"item medium"}>
                  {capitalizeFirstLetter(el.type.replace("_", " "))}
                </div>
                <div className={"item medium"}>
                  {dateToReadableFormat(
                    DateTime.fromISO(el.createdAt).toJSDate(),
                    true
                  )}
                </div>
                <div className={"item edit small justify-center"}>
                  {user?.type === UserType.ADMIN ||
                  el.type !== UserType.AGENCY_OWNER ? (
                    <div
                      className={"icon"}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (deleteUser !== undefined) {
                          deleteUser(el.id);
                        }
                      }}
                    >
                      <Delete title={t("propertyList.delete")} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
