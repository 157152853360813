import React from "react";
import { routes } from "../constants/routes";
import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../util/getUser";
import { Tier } from "../api/AgencyApi";
import { UserType } from "../interfaces/UserTypes";

export function RedirectIfInvalidTier() {
  const location = useLocation();
  const user = getUser();

  if (!user) {
    return null;
  }

  const tier = user.tier;
  if (user.type === UserType.ADMIN || tier === undefined) {
    return null;
  }

  if (
    location.pathname.startsWith(routes.dashboardStatistics) &&
    tier === Tier.ESSENTIAL
  ) {
    return <Navigate to={routes.dashboardUsers} />;
  }

  return null;
}
