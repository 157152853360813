import "./PropertyGrid.css";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as HeartFilled } from "../../assets/heart-filled.svg";
import { ReactComponent as House } from "../../assets/house.svg";
import i18n from "../../i18n";
import React, { useEffect, useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FavouriteApi from "../../api/FavouriteApi";
import { LayoutType } from "../property-list/PropertyList";
import { LayoutToggle } from "../layout-toggle/LayoutToggle";
import { PropertyItem, PropertyStatus } from "../../api/PropertyTypes";
import { RefSearch } from "../ref-search/RefSearch";
import { ExternalPropertyItem } from "../../api/ExternalPropertyTypes";
import { PropertySubTypesWithoutBeds } from "../../constants/PropertyTypes";
import { ReactComponent as Beds } from "../../assets/bed.svg";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { roundByNDecimals } from "../../util/roundByNDecimals";
import { Button } from "../button/Button";
import { Loading } from "../loading/Loading";

export function PropertyGrid({
  properties,
  retrievePage,
  totalPages,
  enableLayoutSwitch,
  enableRefSearch = false,
  layoutType,
  setLayoutType,
  isExternalPropertyGrid = false,
  setCurrentPageParent,
  loading,
}: {
  properties: PropertyItem[] | ExternalPropertyItem[];
  loading: boolean;
  retrievePage: (page: number, ref?: string) => void;
  totalPages: number;
  enableLayoutSwitch?: boolean;
  enableRefSearch?: boolean;
  layoutType?: LayoutType;
  setLayoutType?: React.Dispatch<React.SetStateAction<LayoutType>>;
  isExternalPropertyGrid?: boolean;
  setCurrentPageParent?: (page: number) => void;
}) {
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [favourites, setFavourites] = useState<string[]>([]);
  const [searchReference, setSearchReference] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPageParent && setCurrentPageParent(currentPage);
  }, [currentPage, setCurrentPageParent]);

  useEffect(() => {
    FavouriteApi.getFavourites().then((f) => setFavourites(f));
  }, []);

  async function toggleFavourite(propertyId: string) {
    if (favourites.includes(propertyId)) {
      await FavouriteApi.deleteFavourite(propertyId);
      setFavourites(favourites.filter((f) => f !== propertyId));
    } else {
      await FavouriteApi.addFavourite(propertyId);
      setFavourites([...favourites, propertyId]);
    }
  }

  function handleNavigateExternalPropertyForm(propertyId: string) {
    navigate(`/properties/add/external/${propertyId}`);
  }

  useEffect(() => {
    retrievePage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className={"property-grid"}>
      <div className={"navigation-wrapper"}>
        {enableLayoutSwitch && layoutType && setLayoutType ? (
          <LayoutToggle
            className="height-fit"
            layoutType={layoutType}
            setLayoutType={setLayoutType}
          />
        ) : null}
        <Navigation
          className="height-fit"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
        {enableRefSearch ? (
          <RefSearch
            className="ml-auto"
            reference={searchReference}
            setReference={setSearchReference}
            onClickSearch={(ref) => {
              retrievePage(currentPage, ref);
            }}
          />
        ) : null}
      </div>
      <div className={"properties"}>
        {loading ? <Loading /> : null}
        {properties.length <= 0 && !loading ? (
          <div className={"flex justify-center w-100 mt-4"}>
            {isExternalPropertyGrid
              ? t("propertyList.noExternalProperties")
              : searchReference !== ""
              ? t("propertyList.noDataSearch")
              : t("propertyList.noData")}
          </div>
        ) : (
          !loading &&
          properties.map((el, index) => {
            return (
              <div
                className={"property-item"}
                key={index}
                onClick={
                  isExternalPropertyGrid
                    ? () => handleNavigateExternalPropertyForm(el.id)
                    : () => navigate(`/property/${el.id}`)
                }
              >
                <div className={"image"}>
                  {!isExternalPropertyGrid && (
                    <div
                      className={"favourite"}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavourite(el.id).then();
                      }}
                    >
                      {favourites.includes(el.id) ? <HeartFilled /> : <Heart />}
                    </div>
                  )}

                  {el.status === PropertyStatus.SOLD_VISIBLE ? (
                    <div className={"status"}>{t("property.offer")}</div>
                  ) : null}
                  <img src={el.pictureUrls[0]} alt={"property image"} />
                </div>
                <div className={"content"}>
                  <div className={"item flex flex-column mt-4 mb-2"}>
                    <div className={"price"}>{formatter.format(el.price)}</div>
                    {el.commission && (
                      <div className={"commission"}>
                        {formatter.format(el.price * el.commission)} (
                        {roundByNDecimals(el.commission * 100, 2)}%)
                      </div>
                    )}
                  </div>
                  {el.subType ? (
                    <div className={"item category"}>
                      {capitalizeFirstLetter(el.subType)}
                    </div>
                  ) : (
                    t("propertyList.tbd")
                  )}
                  <div className={"item area"}>
                    {el.subregion || t("propertyList.tbd")}
                  </div>
                  <div className={"item details"}>
                    <div className={"reference"}>
                      {!isExternalPropertyGrid && (
                        <div className={"agency"}>
                          <div className={"agency-name"}>
                            {el.agency.name.split(" ").map((el, index) => (
                              <div key={index}>{el}</div>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className={"eb-reference"}>Ref. {el.reference}</div>
                      {isExternalPropertyGrid && (
                        <Button
                          className={"custom-button secondary mt-2"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigateExternalPropertyForm(el.id);
                          }}
                          fullWidth={false}
                        >
                          {t("property.add")}
                        </Button>
                      )}
                    </div>
                    <div className={"property-details"}>
                      <div className={"tuple"}>
                        <House /> {el.builtSurface} m<sup>2</sup>
                      </div>
                      {el.subType &&
                      !PropertySubTypesWithoutBeds.includes(el.subType) ? (
                        <div className={"tuple mt-4"}>
                          <Beds /> {el.bedrooms ? el.bedrooms : 0}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="navigation-wrapper">
        <div className="ml-auto mr-auto">
          <Navigation
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
}
