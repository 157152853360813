import React from "react";
import { routes } from "../constants/routes";
import { Navigate, useLocation } from "react-router-dom";
import { LocalStorage } from "../enums/LocalStorage";

export function RedirectIfLoggedOut() {
  const location = useLocation();
  const token = localStorage.getItem(LocalStorage.Token);

  if (token !== null && location.pathname === routes.login) {
    return <Navigate to={routes.agencyHome} />;
  }

  if (location.pathname === routes.root) {
    return <Navigate to={routes.agencyHome} />;
  }

  if (
    token === null &&
    ![
      routes.login,
      routes.resetPassword,
      routes.confirmAccount,
      routes.registration,
      routes.completeAccount,
      routes.clientRegistrationConfirm,
    ].includes(location.pathname)
  ) {
    return <Navigate to={routes.login} />;
  }

  return null;
}
