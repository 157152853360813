import React from "react";
import { useTranslation } from "react-i18next";
import { LuSearch } from "react-icons/lu";
import { Button } from "../button/Button";
import { Input } from "../input/Input";
import "./RefSearch.css";

export function RefSearch({
  className,
  reference,
  setReference,
  onClickSearch,
}: {
  className?: string;
  reference: string | undefined;
  setReference: (ref: string) => void;
  onClickSearch: (ref?: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={`ref-search flex ${className ?? ""}`}>
      <Input
        className="ref-input mr-2"
        value={reference ?? ""}
        onChange={setReference}
        placeholder={t("property.ebRef")}
        required={false}
        type="text"
        noPadding
      />
      <Button
        className="search-button ml-auto"
        onClick={() => {
          onClickSearch(reference);
        }}
      >
        <span className="button-text mr-2">{t("filters.search")}</span>
        <LuSearch className="search-icon" />
      </Button>
    </div>
  );
}
