import React, { useEffect, useState } from "react";
import { SideNavigation } from "../../components/side-navigation/SideNavigation";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import AgencyApi, { Agency, PaymentStatus, Tier } from "../../api/AgencyApi";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser } from "../../util/getUser";
import PaymentApi from "../../api/PaymentApi";
import "./SubscriptionSettings.css";
import { SETTINGS_ITEMS } from "../../constants/SettingsItems";
import { Button } from "../../components/button/Button";
import { TierCard } from "../../components/tier-card/TierCard";
import {
  advancedAdvantages,
  essentialAdvantages,
  premiumAdvantages,
} from "../../constants/tierAdvantages";
import AuthApi from "../../api/AuthApi";
import { dateToReadableFormat } from "../../util/dateToReadableFormat";
import Dialog from "../../components/dialog/Dialog";
import { tierPrices } from "../../constants/TierPrices";
import { UserType } from "../../interfaces/UserTypes";

export function SubscriptionSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [agency, setAgency] = useState<Agency | undefined>(undefined);
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [newTier, setNewTier] = useState<Tier | null>(null);

  const cancelled = agency?.paymentStatus === PaymentStatus.PAID_CANCELLED;

  useEffect(() => {
    AgencyApi.getCurrent().then((result) => {
      setAgency(result);
    });
  }, []);

  const user = getUser();

  // Redirect to profile settings if user is not an admin or agency owner
  if (user?.type !== UserType.ADMIN && user?.type !== UserType.AGENCY_OWNER) {
    navigate(routes.profileSettings);
  }

  async function updateSubscription(tier: Tier) {
    if (!user) {
      return;
    }

    await toast
      .promise(PaymentApi.update(user.agencyId, tier), {
        success: t("settings.subscriptionUpdated"),
        error: t("settings.updateSubscriptionFailed"),
        pending: t("settings.updatingSubscription"),
      })
      .then(() => {
        AgencyApi.getCurrent().then((result) => {
          setAgency(result);
        });
        AuthApi.refreshToken().then(() => {
          navigate(routes.subscriptionSettings);
        });
      });
  }

  async function reactivateSubscription() {
    const user = getUser();
    if (!user) {
      return;
    }

    await toast
      .promise(PaymentApi.reactivate(user.agencyId), {
        success: t("settings.subscriptionReactivated"),
        error: t("settings.reactivateFailed"),
        pending: t("settings.reactivatingSubscription"),
      })
      .then(() => {
        AgencyApi.getCurrent().then((result) => {
          setAgency(result);
        });
        AuthApi.refreshToken().then(() => {
          navigate(routes.subscriptionSettings);
        });
      });
  }

  return (
    <>
      <div className={"page settings"}>
        <div className={"side-nav"}>
          <SideNavigation items={SETTINGS_ITEMS} />
        </div>
        <div className="page-content-no-margin">
          <div className={"title"}>
            {t("settings.mySubscription")}
            {": "}
            {capitalizeFirstLetter(agency?.subscriptionTier ?? "")}{" "}
            {cancelled ? `(${t("settings.cancelled")})` : ""}
          </div>
          <div>
            {agency?.paymentStatus === PaymentStatus.PAID &&
            agency.paymentExpiration
              ? `Renewal date: ${dateToReadableFormat(
                  new Date(agency.paymentExpiration),
                  true
                )}`
              : cancelled && agency.paymentExpiration
              ? `End of subscription date: ${dateToReadableFormat(
                  new Date(agency.paymentExpiration),
                  true
                )}`
              : ""}
          </div>
          <div className={"tier-cards"}>
            <TierCard
              title={"Essential"}
              description={"For businesses seeking expended capabilities"}
              pricePerMonth={tierPrices.essential}
              update={true}
              current={agency?.subscriptionTier === Tier.ESSENTIAL}
              advantages={essentialAdvantages}
              onClick={async () => {
                if (cancelled) {
                  await reactivateSubscription();
                } else {
                  setNewTier(Tier.ESSENTIAL);
                }
              }}
              canceled={cancelled}
            />
            <TierCard
              title={"Advanced"}
              description={"Take your business to the next level"}
              pricePerMonth={tierPrices.advanced}
              update={true}
              advantages={advancedAdvantages}
              current={agency?.subscriptionTier === Tier.ADVANCED}
              onClick={async () => {
                if (cancelled) {
                  await reactivateSubscription();
                } else {
                  setNewTier(Tier.ADVANCED);
                }
              }}
              canceled={agency?.paymentStatus === PaymentStatus.PAID_CANCELLED}
            />
            <TierCard
              title={"Premium"}
              description={"For large organizations with specific demands"}
              pricePerMonth={tierPrices.premium}
              update={true}
              current={agency?.subscriptionTier === Tier.PREMIUM}
              advantages={premiumAdvantages}
              onClick={async () => {
                if (cancelled) {
                  await reactivateSubscription();
                } else {
                  setNewTier(Tier.PREMIUM);
                }
              }}
              canceled={agency?.paymentStatus === PaymentStatus.PAID_CANCELLED}
            />
          </div>
          <div className="ml-3 mb-4">
            <a
              className="link"
              href={"mailto:info@estatebridge.io?subject=Custom quote"}
            >
              {t("settings.customQuote")}
            </a>
          </div>
          <div className="flex">
            <Button
              className="ml-3 w-fit link"
              onClick={() => setIsShowingModal(true)}
              disabled={agency?.paymentStatus === PaymentStatus.PAID_CANCELLED}
            >
              {t("settings.cancelSubscription")}
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={isShowingModal}
        onRequestClose={() => setIsShowingModal(false)}
        title={t("settings.cancelSubscription")}
        size={"msmall"}
      >
        <div className={"subscription-dialog-content"}>
          <div className={"description"}>
            {t("settings.confirmCancelSubscription")}
          </div>
          <div className={"button-wrapper"}>
            <Button
              color="danger"
              onClick={async () => {
                const user = getUser();
                if (
                  !user ||
                  agency?.paymentStatus === PaymentStatus.PAID_CANCELLED
                ) {
                  return;
                }

                try {
                  await toast
                    .promise(PaymentApi.cancel(user.agencyId), {
                      success: t("settings.subscriptionCancelled"),
                      error: t("settings.cancelSubscriptionFailed"),
                      pending: t("settings.cancelingSubscription"),
                    })
                    .then((r) => {
                      setAgency(r);
                    });
                } catch (e) {
                  // toast will handle the error
                }
                setIsShowingModal(false);
              }}
            >
              {t("settings.cancel")}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        title={t("payment.updateSubscription")}
        onRequestClose={() => setNewTier(null)}
        isOpen={newTier !== null}
        size="small"
      >
        <div className="flex flex-column items-center">
          <div className="mb-4">
            {t("payment.updateSubscriptionConfirmation")}
          </div>
          <div className="ml-auto flex">
            <Button
              className="mr-4"
              onClick={() => setNewTier(null)}
              color="secondary"
            >
              {t("payment.cancel")}
            </Button>
            <Button
              onClick={async () => {
                if (newTier === null) {
                  return;
                }
                await updateSubscription(newTier);
                setNewTier(null);
              }}
              color="danger"
            >
              {t("payment.update")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
