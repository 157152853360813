import ApiInstance from "./ApiInstance";

class FavouriteApi {
  public async getFavourites(): Promise<string[]> {
    const response = await ApiInstance.getApi().get("/favourites");
    return response.data;
  }

  public async addFavourite(propertyId: string): Promise<void> {
    await ApiInstance.getApi().post("/favourites", { propertyId: propertyId });
  }

  public async deleteFavourite(propertyId: string): Promise<void> {
    await ApiInstance.getApi().delete(`/favourites/${propertyId}`);
  }
}

export default new FavouriteApi();
