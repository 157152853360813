export function capitalizeFirstLetter(word: string): string {
  if (!word) {
    return "";
  }

  if (word.length <= 1) {
    word.toUpperCase();
  }

  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
