import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineMail } from "react-icons/ai";
import * as Sentry from "@sentry/react";
import "./Property.css";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PropertyDetail } from "../../components/property-detail/PropertyDetail";
import PropertyApi from "../../api/PropertyApi";
import { ReactComponent as Phone } from "../../assets/phone.svg";
import { ReactComponent as Flag } from "../../assets/flag.svg";
import Dialog from "../../components/dialog/Dialog";
import ReportApi from "../../api/ReportApi";
import { toast } from "react-toastify";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import { getUser } from "../../util/getUser";
import { PropertyDocuments } from "../../components/property-documents/PropertyDocuments";
import { Button } from "../../components/button/Button";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ClientForm } from "../../components/client-form/ClientForm";
import RegistrationApi, {
  ClientData,
  Registration,
} from "../../api/RegistrationApi";
import { PiPrinter } from "react-icons/pi";
import { PropertyAgent } from "../../components/property-agent/PropertyAgent";
import { PropertyClients } from "../../components/property-clients/PropertyClients";
import { PropertyItem } from "../../api/PropertyTypes";
import { UserType } from "../../interfaces/UserTypes";
import { pdf } from "@react-pdf/renderer";
import { PropertyPDF } from "../../pdf/PropertyPDF";

enum Navigation {
  DESCRIPTION = "description",
  AGENT = "agent",
  DOCUMENTS = "documents",
  REGISTERED_CLIENTS = "registeredClients",
}

export function Property() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registrations, setRegistrations] = useState<Registration[]>([]);

  const params = new URLSearchParams(window.location.search);

  const navigationPossibilities = [
    Navigation.DESCRIPTION,
    Navigation.AGENT,
    Navigation.DOCUMENTS,
    Navigation.REGISTERED_CLIENTS,
  ];

  const [nav, setNav] = useState<Navigation>(
    (params.get("tab") as Navigation) || Navigation.DESCRIPTION
  );
  const [property, setProperty] = useState<PropertyItem | undefined>(undefined);

  const [isShowingReportModal, setIsShowingReportModal] =
    useState<boolean>(false);
  const [isShowingClientModal, setIsShowingClientModal] =
    useState<boolean>(false);
  const [toastIds, setToastIds] = useState<string[]>([]);
  const [reportReason, setReportReason] = useState<string>("");

  const { propertyId } = useParams<{ propertyId: string }>();

  useEffect(() => {
    if (propertyId) {
      PropertyApi.getById(propertyId).then((p) => {
        setProperty(p);
        PropertyApi.watch(propertyId).then();
      });

      PropertyApi.listRegistrations(propertyId).then((r) =>
        setRegistrations(r)
      );
    }
  }, [propertyId]);

  useEffect(() => {
    // update url
    const url = new URL(window.location.href);
    url.searchParams.set("tab", nav);
    window.history.replaceState({}, "", url.toString());
  }, [nav]);

  const user = getUser();
  const canEdit =
    user?.type === UserType.ADMIN ||
    (user?.type === UserType.AGENCY_OWNER &&
      user?.agencyId === property?.agencyId) ||
    (user?.type === UserType.AGENT && user?.id === property?.agent?.id);

  async function submitReport() {
    if (propertyId) {
      try {
        await ReportApi.sendReport({
          reason: reportReason,
          propertyId,
        });
        setReportReason("");
        setIsShowingReportModal(false);

        toastIds.forEach((id) => toast.dismiss(id));
        const toastId = "property.reportSuccess";
        toast(t(toastId) as string, {
          type: "success",
          toastId: toastId,
        });
        setToastIds([]);
      } catch (e) {
        Sentry.captureException(e);

        const toastId = "property.reportFailed";
        if (!toastIds.includes(toastId)) {
          setToastIds((prevState) => [...prevState, toastId]);
          toast(t(toastId) as string, {
            type: "error",
            toastId: toastId,
          });
        }
      }
    } else {
      const toastId = "property.reportNoPropertyId";
      if (!toastIds.includes(toastId)) {
        setToastIds((prevState) => [...prevState, toastId]);
        toast(t(toastId) as string, {
          type: "error",
          toastId: toastId,
        });
      }
    }
  }

  async function registerClient(clientData: ClientData) {
    if (propertyId) {
      const registration = await RegistrationApi.registerClient(
        clientData,
        propertyId
      );
      setRegistrations((prevState) => [...prevState, registration]);
      setIsShowingClientModal(false);
    }
  }

  if (!property) {
    return null;
  }

  return (
    <div className={"page without-side-navigation page-content-no-margin"}>
      <div className={"listing-header"}>
        <div className={"property-reference"}>
          <div className={"reference-item"}>
            <div>{t("property.agency")}</div>
            <div className={"value"}>{property.agency.name}</div>
          </div>
          <div className={"reference-item"}>
            <div>{t("property.ebRef")}</div>
            <div className={"value"}>{property.reference}</div>
          </div>
        </div>
        <div className={"extra-buttons"}>
          <a
            className={"contact-email"}
            href={`mailto:${property.agent?.email}?subject=Inquiry REF ${property.reference}`}
          >
            <AiOutlineMail />
          </a>
          <a
            className={"contact-phone"}
            href={`tel:${property.agent?.phoneNumber}`}
          >
            <Phone />
          </a>
          <div className={"contact-phone"}>
            <PiPrinter
              onClick={async () => {
                const blob = await pdf(
                  <PropertyPDF property={property} />
                ).toBlob();

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `Property ${property?.reference}.pdf`;
                link.click();
              }}
            />
          </div>
          <div className="view-count">
            <AiOutlineEye /> {property.viewCount}
          </div>
        </div>
        <div className="action-buttons">
          <Button
            className={"report-button"}
            color={"danger"}
            onClick={() => setIsShowingReportModal(true)}
          >
            <Flag /> {t("property.report")}
          </Button>
          <Button
            className={"client-button"}
            onClick={() => setIsShowingClientModal(true)}
            color={"secondary"}
          >
            <UserIcon />
            {t("property.registerClient")}
          </Button>
          {canEdit ? (
            <Button
              className="button edit-button"
              color="secondary"
              onClick={() => navigate(`/properties/edit/${property?.id}`)}
            >
              <Edit />
              {t("property.edit")}
            </Button>
          ) : null}
        </div>
      </div>
      <div className="listing-navigation">
        {navigationPossibilities.map((el, index) => {
          return (
            <div
              key={index}
              className={`item ${nav === el ? "active" : ""}`}
              onClick={() => {
                setNav(el);
              }}
            >
              {t(`property.${el}`)}{" "}
              {el == Navigation.REGISTERED_CLIENTS
                ? `(${registrations.length})`
                : null}
            </div>
          );
        })}
      </div>
      <div>
        {nav === Navigation.DESCRIPTION ? (
          <PropertyDetail property={property} />
        ) : null}
        {nav === Navigation.AGENT ? (
          <PropertyAgent property={property} />
        ) : null}
        {nav === Navigation.DOCUMENTS ? (
          <PropertyDocuments documents={property.documents} />
        ) : null}
        {nav === Navigation.REGISTERED_CLIENTS ? (
          <PropertyClients property={property} />
        ) : null}
      </div>
      <Dialog
        isOpen={isShowingReportModal}
        onRequestClose={() => setIsShowingReportModal(false)}
        title={"Report property"}
        size={"msmall"}
      >
        <div className={"report-dialog-content"}>
          <div className={"description"}>{t("property.reportDescription")}</div>
          <textarea
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
          />
          <div className={"button-wrapper"}>
            <Button
              disabled={reportReason.trim().length <= 0}
              onClick={() => submitReport()}
            >
              Submit
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={isShowingClientModal}
        onRequestClose={() => setIsShowingClientModal(false)}
        title={t("property.registerClient")}
        size={"wide"}
      >
        <ClientForm onSubmit={registerClient} />
      </Dialog>
    </div>
  );
}
