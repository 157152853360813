export const essentialAdvantages = [
  "payment.listingsInCountry",
  "payment.2Users",
  "payment.standardAnalytics",
  "payment.essentialSupport",
  "payment.yearlyFee",
];

export const advancedAdvantages = [
  "payment.listingsInCountry",
  "payment.10Users",
  "payment.standardAnalytics",
  "payment.advancedSupport",
  "payment.yearlyFee",
];

export const premiumAdvantages = [
  "payment.allListings",
  "payment.20Users",
  "payment.advancedAnalytics",
  "payment.premiumSupport",
  "payment.yearlyFee",
];
