export enum FilterName {
  FavouritesOnly = "favouritesOnly",
  Price = "price",
  Bedrooms = "bedrooms",
  Bathrooms = "bathrooms",
  Agency = "agency",
  Pool = "pool",
  Garden = "garden",
  Garage = "garage",
  Orientation = "orientation",
  InteriorSurface = "interiorSurface",
  TotalSurface = "totalSurface",
  TerraceSurface = "terraceSurface",
  Region = "region",
  GeneralType = "generalType",
  SubType = "subType",
  SubRegion = "subRegion",
}

const FilterNameToI18nKey: Record<FilterName, string> = {
  [FilterName.FavouritesOnly]: "filters.favouritesOnly",
  [FilterName.Price]: "filters.price",
  [FilterName.Bedrooms]: "filters.bedrooms",
  [FilterName.Bathrooms]: "filters.bathrooms",
  [FilterName.Agency]: "filters.agency",
  [FilterName.Pool]: "filters.pool",
  [FilterName.Garden]: "filters.garden",
  [FilterName.Garage]: "filters.garage",
  [FilterName.Orientation]: "filters.orientation",
  [FilterName.InteriorSurface]: "filters.interiorSurface",
  [FilterName.TotalSurface]: "filters.totalSurface",
  [FilterName.TerraceSurface]: "filters.terraceSurface",
  [FilterName.Region]: "filters.region",
  [FilterName.GeneralType]: "filters.type",
  [FilterName.SubType]: "", // not used, just added to compile
  [FilterName.SubRegion]: "", // not used, just added to compile
};

export function getFilterI18nKey(filterName: FilterName): string {
  return FilterNameToI18nKey[filterName];
}
