import ApiInstance from "./ApiInstance";
import { Agency } from "./AgencyApi";
import { PaginationResult } from "./PaginationContent";

export type AgencyWithStats = Agency & {
  numberOfUsers: number;
  numberOfProperties: number;
};

export type GlobalStats = {
  numberOfAgencies: number;
  numberOfProperties: number;
  numberOfUsers: number;
};

export type UserGrowthStats = {
  data: { month: number; year: number; count: number }[];
};

export type PropertyViewStats = {
  data: { week: number; month: number; year: number; count: number }[];
};

export type PropertyCountByTypeStats = {
  data: { propertyType: string; count: number }[];
};

class AdminApi {
  public async listAgenciesWithStats(
    page: number,
    pageSize: number
  ): Promise<PaginationResult<AgencyWithStats>> {
    const response = await ApiInstance.getApi().get(
      `/admin/agencies/stats?page=${page - 1}&pageSize=${pageSize}`
    );
    return response.data;
  }

  public async getGlobalStats(): Promise<GlobalStats> {
    const response = await ApiInstance.getApi().get(`/admin/stats/global`);
    return response.data;
  }

  public async getUserGrowthStats(): Promise<UserGrowthStats> {
    const response = await ApiInstance.getApi().get(
      `/admin/stats/global/user-growth`
    );
    return response.data;
  }

  public async getPropertyViewStats(): Promise<PropertyViewStats> {
    const response = await ApiInstance.getApi().get(
      `/admin/stats/global/property-view`
    );
    return response.data;
  }
}

export default new AdminApi();
