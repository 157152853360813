export enum Orientation {
  N = "NORTH",
  NE = "NORTH_EAST",
  E = "EAST",
  SE = "SOUTH_EAST",
  S = "SOUTH",
  SW = "SOUTH_WEST",
  W = "WEST",
  NW = "NORTH_WEST",
}

const ORIENTATION_TO_LABEL: { [key in Orientation]: string } = {
  [Orientation.N]: "N",
  [Orientation.NE]: "NE",
  [Orientation.E]: "E",
  [Orientation.SE]: "SE",
  [Orientation.S]: "S",
  [Orientation.SW]: "SW",
  [Orientation.W]: "W",
  [Orientation.NW]: "NW",
};

export function getOrientationLabel(orientation: Orientation): string {
  return ORIENTATION_TO_LABEL[orientation];
}
