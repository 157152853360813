import "./PropertyDetail.css";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as HeartFilled } from "../../assets/heart-filled.svg";
import { ReactComponent as Map } from "../../assets/map.svg";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { PropertyItem } from "../../api/PropertyTypes";
import { useEffect, useRef, useState } from "react";
import LanguageApi, { Language, LanguageCode } from "../../api/LanguageApi";
import { DateTime } from "luxon";
import { dateToRelativeString } from "../../util/dateToRelativeString";
import FavouriteApi from "../../api/FavouriteApi";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { parseProperty } from "../../util/parseProperty";
import { StatusBadge } from "../status-badge/StatusBadge";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down-icon.svg";
import { FaQuestionCircle } from "react-icons/fa";
import { roundByNDecimals } from "../../util/roundByNDecimals";

export function PropertyDetail({
  property,
  print,
}: {
  property: PropertyItem;
  print?: boolean;
}) {
  const { t } = useTranslation();

  const [languages, setLanguages] = useState<Language[]>([]);
  const [imageNumber, setImageNumber] = useState<number>(0);
  const [favourites, setFavourites] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();
  const [openLightBox, setOpenLightBox] = useState(false);
  const thumbnailsRef = useRef(null);

  useEffect(() => {
    LanguageApi.getLanguages().then((d) => {
      setLanguages(d);
      setSelectedLanguage(d.find((d) => d.code === LanguageCode.ENGLISH));
    });
    FavouriteApi.getFavourites().then((f) => setFavourites(f));
  }, []);

  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  function nextImage() {
    setImageNumber((imageNumber + 1) % property.pictureUrls.length);
  }

  function previousImage() {
    setImageNumber((imageNumber - 1) % property.pictureUrls.length);
  }

  async function toggleFavourite(propertyId: string) {
    if (favourites.includes(propertyId)) {
      await FavouriteApi.deleteFavourite(propertyId);
      setFavourites(favourites.filter((f) => f !== propertyId));
    } else {
      await FavouriteApi.addFavourite(propertyId);
      setFavourites([...favourites, propertyId]);
    }
  }

  return (
    <div className={`listing-description ${print ? "print" : ""}`}>
      <div className={"description-hero"}>
        <div className={"hero-slideshow"}>
          <div className={"slider"}>
            {print ? null : (
              <div
                className={"favourite active"}
                onClick={() => toggleFavourite(property.id)}
              >
                {favourites.includes(property.id) ? <HeartFilled /> : <Heart />}
              </div>
            )}
            {print ? null : (
              <div className={"navigation"}>
                <div className={"left"} onClick={() => previousImage()}>
                  <ArrowLeft />
                </div>
                <div className={"right"} onClick={() => nextImage()}>
                  <ArrowRight />
                </div>
              </div>
            )}
            <img
              className={"image"}
              src={property.pictureUrls[print ? 0 : imageNumber]}
              alt={"property image"}
              onClick={() => setOpenLightBox(true)}
            />
          </div>
        </div>
        <div className={"description"}>
          <div>
            <div className={"header flex"}>
              {t("propertyDetail.description")}
              {print ? null : (
                <div className={"language-select-wrapper"}>
                  <select
                    className={"language-select"}
                    value={selectedLanguage?.code}
                    onChange={(e) => {
                      setSelectedLanguage(
                        languages.find(
                          (l) => l.code === (e.target.value as LanguageCode)
                        )
                      );
                    }}
                  >
                    {languages.map((l) => (
                      <option key={l.id} value={l.code}>
                        {l.code}
                      </option>
                    ))}
                  </select>
                  <ChevronDown />
                </div>
              )}
            </div>
            <div className="description-content">
              {property.descriptions
                .find((d) => {
                  if (d.language.id && selectedLanguage) {
                    return d.language.id === selectedLanguage.id;
                  }
                  return false;
                })
                ?.description.split("\n")
                .map((p, index) => <p key={index}>{p}</p>) ||
                t("propertyDetail.noDescription", {
                  language: selectedLanguage?.name,
                })}
            </div>
          </div>
          <div className={"information-block"}>
            {print ? <div>{property.reference}</div> : null}
            <div className={"price"}>{formatter.format(property.price)}</div>
            <div className="no-border-right">
              {formatter.format(property.price * property.commission)} (
              {roundByNDecimals(property.commission * 100, 2)}%)
            </div>
            {print ? null : (
              <div className="tooltip">
                <FaQuestionCircle className="tooltip-icon" />
                <div className="tooltip-content">
                  <div className="no-border-right">
                    Listing agency commission:{" "}
                    {formatter.format(
                      property.price *
                        property.commission *
                        (1 - property.commissionSplit)
                    )}
                  </div>
                  <div>
                    Selling agency commission:{" "}
                    {formatter.format(
                      property.price *
                        property.commission *
                        property.commissionSplit
                    )}
                  </div>
                </div>
              </div>
            )}
            <div>{capitalizeFirstLetter(property.subType)}</div>
            <div>
              <StatusBadge status={property.status} />
            </div>
            {property.googleMapsUrl ? (
              <div>
                <a href={property.googleMapsUrl} target={"_blank"}>
                  <div className={"map"}>
                    <Map />
                  </div>
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={"description-specifications"}>
        <div className={"header"}>{t("propertyDetail.specifications")}</div>
        <div className={"items"}>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.bedrooms")}</div>
            <div className={"value"}>{parseProperty(property.bedrooms)}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.bathrooms")}</div>
            <div className={"value"}>{parseProperty(property.bathrooms)}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.builtSpace")}</div>
            <div className={"value"}>
              {parseProperty(property.builtSurface, true)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>
              {t("propertyDetail.internalFloorSpace")}
            </div>
            <div className={"value"}>
              {parseProperty(property.interiorSurface, true)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.terraceSpace")}</div>
            <div className={"value"}>
              {parseProperty(property.terraceSurface, true)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.totalSize")}</div>
            <div className={"value"}>
              {parseProperty(property.totalSurface, true)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.floor")}</div>
            <div className={"value"}>{parseProperty(property.floor)}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.numberOfFloors")}</div>
            <div className={"value"}>
              {parseProperty(property.numberOfFloors)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.numberOfToilets")}</div>
            <div className={"value"}>{parseProperty(property.toilets)}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.pool")}</div>
            <div className={"value"}>
              {parseProperty(capitalizeFirstLetter(property.pool))}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.garden")}</div>
            <div className={"value"}>
              {parseProperty(capitalizeFirstLetter(property.garden))}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.garage")}</div>
            <div className={"value"}>
              {parseProperty(capitalizeFirstLetter(property.garage))}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.orientation")}</div>
            <div className={"value"}>
              {parseProperty(capitalizeFirstLetter(property.orientation))}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.parkingSpaces")}</div>
            <div className={"value"}>
              {parseProperty(property.parkingSpaces)}
            </div>
          </div>

          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.completionYear")}</div>
            <div className={"value"}>
              {parseProperty(property.completionYear)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.updatedAt")}</div>
            <div className={"value"}>
              {dateToRelativeString(DateTime.fromISO(property.updatedAt))}
            </div>
          </div>
        </div>
      </div>
      <div className="description-location">
        <div className={"header"}>{t("propertyDetail.location")}</div>
        <div className={"items"}>
          <div className={"item"}>
            <div className={"key"}>{t("address.street")}</div>
            <div className={"value"}>
              {parseProperty(property.address.street)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("address.number")}</div>
            <div className={"value"}>
              {parseProperty(property.address.number)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("address.city")}</div>
            <div className={"value"}>{property.address.city}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("address.country")}</div>
            <div className={"value"}>{property.address.country.name}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.region")}</div>
            <div className={"value"}>{property.region}</div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.subregion")}</div>
            <div className={"value"}>{property.subregion}</div>
          </div>
        </div>
      </div>
      <div className="description-costs">
        <div className={"header"}>{t("propertyDetail.costs")}</div>
        <div className={"items"}>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.communityFees")}</div>
            <div className={"value"}>
              {parseProperty(property.communityFees, false, true)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.IBI")}</div>
            <div className={"value"}>
              {parseProperty(property.IBI, false, true)}
            </div>
          </div>
          <div className={"item"}>
            <div className={"key"}>{t("propertyDetail.garbageTax")}</div>
            <div className={"value"}>
              {parseProperty(property.garbageTax, false, true)}
            </div>
          </div>
        </div>
      </div>
      {print ? (
        <div className="print-images">
          {property.pictureUrls.map((item, index) => {
            return (
              <img
                key={index}
                className={"print-image"}
                src={item}
                alt={"property image"}
              />
            );
          })}
        </div>
      ) : null}
      <Lightbox
        open={openLightBox}
        plugins={[Thumbnails, Zoom]}
        thumbnails={{ ref: thumbnailsRef }}
        close={() => setOpenLightBox(false)}
        slides={property.pictureUrls.map((p) => ({ src: p }))}
      />
    </div>
  );
}
