import { SortingOrder } from "../enums/SortingOrder";

export function getNextSortingOrder(
  orderType: SortingOrder | undefined
): SortingOrder | undefined {
  if (!orderType) {
    return SortingOrder.ASC;
  }

  return orderType === SortingOrder.ASC ? SortingOrder.DESC : undefined;
}

export interface SortingConfig {
  field: string;
  order: SortingOrder;
}
