import { FunctionComponent, SVGProps } from "react";
import { routes } from "./routes";
import { ReactComponent as Subscription } from "../assets/subscription.svg";
import { ReactComponent as SubscriptionGrayed } from "../assets/subscription-grayed.svg";
import { ReactComponent as Key } from "../assets/key.svg";
import { ReactComponent as KeyGrayed } from "../assets/key-grayed.svg";
import { ReactComponent as UserIcon } from "../assets/user.svg";
import { ReactComponent as UserGrayedIcon } from "../assets/user-grayed.svg";
import { UserType } from "../interfaces/UserTypes";

export const SETTINGS_ITEMS: {
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  IconLight: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  titleKey: string;
  route: string;
  allowedUsers?: UserType[];
  hideIfNoAgency?: boolean;
}[] = [
  {
    Icon: Subscription,
    IconLight: SubscriptionGrayed,
    titleKey: "sideNavigation.subscription",
    route: routes.subscriptionSettings,
    allowedUsers: [UserType.AGENCY_OWNER, UserType.ADMIN],
    hideIfNoAgency: true,
  },
  {
    Icon: Key,
    IconLight: KeyGrayed,
    titleKey: "sideNavigation.inmobalia",
    route: routes.inmobaliaSettings,
    allowedUsers: [UserType.AGENCY_OWNER, UserType.ADMIN],
    hideIfNoAgency: true,
  },
  {
    Icon: UserIcon,
    IconLight: UserGrayedIcon,
    titleKey: "sideNavigation.profile",
    route: routes.profileSettings,
  },
];
