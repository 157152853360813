import "./TierCard.css";
import React from "react";
import i18n from "../../i18n";
import { ReactComponent as CheckmarkCircle } from "../../assets/check-circle.svg";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button";

export function TierCard({
  title,
  description,
  pricePerMonth,
  advantages,
  onClick,
  recommended,
  update,
  current,
  canceled,
}: {
  title: string;
  description: string;
  pricePerMonth: number;
  advantages: string[];
  onClick: () => void;
  recommended?: boolean;
  update?: boolean;
  current?: boolean;
  canceled?: boolean;
}) {
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  return (
    <div
      className={`tier-card ${recommended ? "recommended" : ""} ${
        current ? "current" : ""
      } ${canceled ? "canceled" : ""}`}
    >
      {recommended && (
        <div className={"recommended-content"}>{t("payment.recommended")}</div>
      )}
      <div className={"content"}>
        <div>
          <div className={"title"}>{title}</div>
          <div className={"description"}>{description}</div>
          <div className={"price-wrapper"}>
            <div className={"price"}>{formatter.format(pricePerMonth)}</div>
            <div className={"year"}>/ {t("payment.month")}</div>
          </div>
          <div className={"price-wrapper small"}>
            <div className={"price"}>
              {formatter.format(pricePerMonth * 12)}
            </div>
            <div className={"year"}>/ {t("payment.year")}</div>
          </div>
          <div className={"advantages"}>
            {advantages.map((a, index) => (
              <div key={index} className={"item"}>
                <div className={"checkmark"}>
                  <CheckmarkCircle />
                </div>
                <div className={"text"}>{t(a)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={"get-started"}>
          <Button
            disabled={canceled ? !current : current}
            color={
              recommended
                ? "primary"
                : canceled
                ? current
                  ? "outlined"
                  : "secondary"
                : !current
                ? "outlined"
                : "secondary"
            }
            onClick={onClick}
            fullWidth
          >
            {update
              ? current
                ? canceled
                  ? t("payment.reactivate")
                  : t("payment.current")
                : t("payment.select")
              : t("payment.getStarted")}
          </Button>
        </div>
      </div>
    </div>
  );
}
