import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../util/getUser";
import { routes } from "../constants/routes";

export function RedirectIfAlreadyPayed() {
  const location = useLocation();
  const user = getUser();

  if (!user) {
    return null;
  }

  if (
    user.tier !== undefined &&
    (location.pathname === routes.paymentAndTierSelection ||
      location.pathname === routes.payment)
  ) {
    return <Navigate to={routes.subscriptionSettings} />;
  }

  return null;
}
