import { LocalStorage } from "../enums/LocalStorage";
import { User } from "../interfaces/UserTypes";

export function getUser(): User | null {
  const storedUser = localStorage.getItem(LocalStorage.User);
  if (storedUser) {
    try {
      return JSON.parse(storedUser) as User;
    } catch (e) {
      return null;
    }
  }

  return null;
}
