import ApiInstance from "./ApiInstance";

export type MediaUrl = {
  url: string;
};

class MediaApi {
  public async uploadMedia(file: File): Promise<MediaUrl> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await ApiInstance.getApi().post(`/media`, formData, {
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });

    return response.data;
  }
}

export default new MediaApi();
