import React, { useEffect, useState } from "react";
import PropertyApi from "../../api/PropertyApi";
import { HomeWithStats } from "../../components/home-with-stats/HomeWithStats";
import { Config } from "../../Config";
import { PropertyItem, PropertyStatistics } from "../../api/PropertyTypes";
import { SortingConfig } from "../../interfaces/SortingConfig";

export function PersonalHome() {
  const [properties, setProperties] = useState<PropertyItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [statistics, setStatistics] = useState<PropertyStatistics | null>(null);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PropertyApi.listPersonalProperties(1, Config.getDefaultPageSize())
      .then((p) => {
        setLoading(false);
        setProperties(p.data);
        setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
      })
      .finally(() => {
        setLoading(false);
      });

    PropertyApi.getPersonalStatistics().then((result) => {
      setStatistics(result);
    });
  }, []);

  function retrievePage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    PropertyApi.listPersonalProperties(
      page,
      Config.getDefaultPageSize(),
      sortingConfig
    ).then((p) => {
      setProperties(p.data);
      setTotalPages(Math.ceil(p.count / Config.getDefaultPageSize()));
    });
  }

  return (
    <HomeWithStats
      properties={properties}
      setProperties={setProperties}
      retrievePage={retrievePage}
      propertyTotalPages={totalPages}
      propertyStatistics={statistics}
      enableEditButtons={true}
      sortingConfig={sortingConfig}
      setSortingConfig={setSortingConfig}
      loading={loading}
    />
  );
}
