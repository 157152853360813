import ApiInstance from "./ApiInstance";
import { Agency } from "./AgencyApi";

// all the values that can be used to filter the properties
export interface FilterValues {
  agencies: Agency[];
  bedroomsMin: number;
  bedroomsMax: number;
  bathroomsMin: number;
  bathroomsMax: number;
  interiorSurfaceMin: number;
  interiorSurfaceMax: number;
  totalSurfaceMin: number;
  totalSurfaceMax: number;
  terraceSurfaceMin: number;
  terraceSurfaceMax: number;
  priceMin: number;
  priceMax: number;
  types: Record<string, string[]>;
  regions: string[];
  subregions: Record<string, string[]>;
}

class FilterValuesApi {
  async getFilterValues(): Promise<FilterValues> {
    const response = await ApiInstance.getApi().get("/filter-values");
    return response.data;
  }
}

export default new FilterValuesApi();
