import { FilterType } from "../enums/FilterType";
import { FilterName } from "../enums/FilterName";
import { PropertyFilterParams } from "../api/PropertyTypes";

const FILTER_NAME_TO_TYPE: Record<FilterName, FilterType> = {
  [FilterName.FavouritesOnly]: FilterType.Boolean,
  [FilterName.Agency]: FilterType.MultiSelect,
  [FilterName.Bedrooms]: FilterType.MultiSelect,
  [FilterName.Bathrooms]: FilterType.MultiSelect,
  [FilterName.Price]: FilterType.Range,
  [FilterName.Pool]: FilterType.MultiEnum,
  [FilterName.Garden]: FilterType.MultiEnum,
  [FilterName.Garage]: FilterType.MultiEnum,
  [FilterName.Orientation]: FilterType.MultiEnum,
  [FilterName.InteriorSurface]: FilterType.Range,
  [FilterName.TotalSurface]: FilterType.Range,
  [FilterName.TerraceSurface]: FilterType.Range,
  [FilterName.Region]: FilterType.MultiSelect,
  [FilterName.GeneralType]: FilterType.MultiSelect,
  [FilterName.SubType]: FilterType.MultiSelect,
  [FilterName.SubRegion]: FilterType.SingleSelect,
};

export function filterNameToType(filterName: FilterName): FilterType {
  return FILTER_NAME_TO_TYPE[filterName];
}

export function getFilterParamsFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(
    Array.from(urlParams.entries()).filter(([key, _]) =>
      Object.values(FilterName).includes(key as FilterName)
    )
  ) as PropertyFilterParams;
}

export function getInitialMinAndMax(
  filterValue: string | undefined
): [number?, number?] {
  if (filterValue === undefined) {
    return [undefined, undefined];
  }

  const [min, max] = filterValue.split(",");
  const initialMin = min === "min" ? undefined : Number(min);
  const initialMax = max === "max" ? undefined : Number(max);
  return [initialMin, initialMax];
}
