import React, { useEffect, useState } from "react";
import "./PaymentAndTierSelection.css";
import { TierCard } from "../../components/tier-card/TierCard";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { toast } from "react-toastify";
import PaymentApi from "../../api/PaymentApi";
import { getUser } from "../../util/getUser";
import AgencyApi, { Agency, Tier } from "../../api/AgencyApi";
import { Button } from "../../components/button/Button";
import AuthApi from "../../api/AuthApi";
import { FaTrash } from "react-icons/fa6";
import Dialog from "../../components/dialog/Dialog";
import AccountApi from "../../api/AccountApi";
import {
  advancedAdvantages,
  essentialAdvantages,
  premiumAdvantages,
} from "../../constants/tierAdvantages";
import { tierPrices } from "../../constants/TierPrices";

export function PaymentAndTierSelection({ update }: { update?: boolean }) {
  const [agency, setAgency] = useState<Agency | undefined>(undefined);
  const [newTier, setNewTier] = useState<Tier | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);

  useEffect(() => {
    AgencyApi.getCurrent().then((result) => {
      setAgency(result);
    });
  }, []);

  function logout() {
    AuthApi.logout();
    navigate(routes.login);
  }

  async function onConfirmDelete() {
    try {
      await AccountApi.deleteAccount();
      logout();
      toast.success(t("payment.deleteAccountSuccess"));
    } catch (e) {
      toast.error(t("payment.deleteAccountFailed"));
    }
  }

  async function updateSubscription(tier: Tier) {
    const user = getUser();
    if (!user) {
      return;
    }

    await toast
      .promise(PaymentApi.update(user.agencyId, tier), {
        success: t("settings.subscriptionUpdated"),
        error: t("settings.updateSubscriptionFailed"),
        pending: t("settings.updatingSubscription"),
      })
      .then(() => {
        AuthApi.refreshToken().then(() => {
          navigate(routes.subscriptionSettings);
        });
      });
  }

  return (
    <div className={"tier-selection-upper-container flex flex-column"}>
      <div className={"tier-selection-container flex"}>
        <div className={"tier-selection"}>
          <Logo />
          {update ? null : (
            <div className={"subtitle"}>
              {t("payment.selectTier")}
              <br />
              {t("payment.freeTrialMessage")}
            </div>
          )}
          <div className={"tier-cards"}>
            <TierCard
              title={"Essential"}
              description={"For businesses seeking expended capabilities"}
              pricePerMonth={tierPrices.essential}
              update={update}
              current={agency?.subscriptionTier === Tier.ESSENTIAL}
              advantages={essentialAdvantages}
              onClick={async () => {
                if (update) {
                  setNewTier(Tier.ESSENTIAL);
                } else {
                  navigate(`${routes.payment}?tier=essential`);
                }
              }}
            />
            <TierCard
              title={"Advanced"}
              description={"Take your business to the next level"}
              pricePerMonth={tierPrices.advanced}
              update={update}
              advantages={advancedAdvantages}
              current={agency?.subscriptionTier === Tier.ADVANCED}
              onClick={async () => {
                if (update) {
                  setNewTier(Tier.ADVANCED);
                } else {
                  navigate(`${routes.payment}?tier=advanced`);
                }
              }}
              recommended={true}
            />
            <TierCard
              title={"Premium"}
              description={"For large organizations with specific demands"}
              pricePerMonth={tierPrices.premium}
              update={update}
              current={agency?.subscriptionTier === Tier.PREMIUM}
              advantages={premiumAdvantages}
              onClick={async () => {
                if (update) {
                  setNewTier(Tier.PREMIUM);
                } else {
                  navigate(`${routes.payment}?tier=premium`);
                }
              }}
            />
          </div>
          <div className="ml-auto mr-auto">
            <a
              className="link"
              href={"mailto:info@estatebridge.io?subject=Custom quote"}
            >
              {t("settings.customQuote")}
            </a>
          </div>
        </div>
      </div>
      <div className="flex ml-auto mr-4 mb-4 items-center">
        <Button onClick={logout}>{t("header.logout")}</Button>
        {update ? null : (
          <div className="delete-account">
            <FaTrash
              className="ml-4"
              onClick={() => setDeleteAccountDialogOpen(true)}
            />
          </div>
        )}
      </div>
      <Dialog
        title={t("payment.deleteAccount")}
        onRequestClose={() => setDeleteAccountDialogOpen(false)}
        isOpen={deleteAccountDialogOpen}
        size="small"
      >
        <div className="flex flex-column items-center">
          <div className="mb-4">{t("payment.deleteAccountConfirmation")}</div>
          <div className="ml-auto flex">
            <Button
              className="mr-4"
              onClick={() => setDeleteAccountDialogOpen(false)}
              color="secondary"
            >
              {t("payment.cancel")}
            </Button>
            <Button onClick={onConfirmDelete} color="danger">
              {t("payment.delete")}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        title={t("payment.updateSubscription")}
        onRequestClose={() => setNewTier(null)}
        isOpen={newTier !== null}
        size="small"
      >
        <div className="flex flex-column items-center">
          <div className="mb-4">
            {t("payment.updateSubscriptionConfirmation")}
          </div>
          <div className="ml-auto flex">
            <Button
              className="mr-4"
              onClick={() => setNewTier(null)}
              color="secondary"
            >
              {t("payment.cancel")}
            </Button>
            <Button
              onClick={async () => {
                if (newTier === null) {
                  return;
                }
                await updateSubscription(newTier);
              }}
              color="danger"
            >
              {t("payment.update")}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
