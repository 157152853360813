import PropertyApi from "../../api/PropertyApi";
import { toast } from "react-toastify";
import { SideNavigation } from "../side-navigation/SideNavigation";
import { PropertyField, PropertyList } from "../property-list/PropertyList";
import Dialog from "../dialog/Dialog";
import React, { FunctionComponent, SVGProps, useEffect, useState } from "react";
import { ReactComponent as House } from "../../assets/house.svg";
import { ReactComponent as HouseGrayed } from "../../assets/house-grayed.svg";
import { ReactComponent as AuctionGrayed } from "../../assets/auction-grayed.svg";
import { ReactComponent as Auction } from "../../assets/auction.svg";
import { ReactComponent as Heart } from "../../assets/heart-primary.svg";
import { ReactComponent as HeartFilled } from "../../assets/heart-filled.svg";
import UserImage from "../../assets/user.svg";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import "./HomeWithStats.css";
import { LanguageCode } from "../../api/LanguageApi";
import { useNavigate } from "react-router-dom";
import { PropertyGrid } from "../property-grid/PropertyGrid";
import { Button } from "../button/Button";
import {
  PropertyData,
  PropertyItem,
  PropertyStatistics,
  PropertyStatus,
} from "../../api/PropertyTypes";
import { SortingConfig } from "../../interfaces/SortingConfig";
import { SortingOrder } from "../../enums/SortingOrder";

export const HOME_ITEMS: {
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  IconLight: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  titleKey: string;
  route: string;
}[] = [
  {
    Icon: House,
    IconLight: HouseGrayed,
    titleKey: "sideNavigation.agencyProperties",
    route: routes.agencyHome,
  },
  {
    Icon: House,
    IconLight: HouseGrayed,
    titleKey: "sideNavigation.myProperties",
    route: routes.personalHome,
  },
  {
    Icon: Heart,
    IconLight: HeartFilled,
    titleKey: "sideNavigation.favorites",
    route: routes.favoritesHome,
  },
];

enum DialogPurpose {
  Delete = "DELETE",
  Update = "UPDATE",
}

export function HomeWithStats({
  properties,
  setProperties,
  retrievePage,
  propertyTotalPages,
  propertyStatistics,
  enableEditButtons,
  sortingConfig,
  setSortingConfig,
  loading,
}: {
  properties: PropertyItem[];
  loading: boolean;
  setProperties: React.Dispatch<React.SetStateAction<PropertyItem[]>>;
  retrievePage: (page: number) => void;
  propertyTotalPages: number;
  propertyStatistics: PropertyStatistics | null;
  enableEditButtons: boolean;
  sortingConfig: SortingConfig | undefined;
  setSortingConfig: React.Dispatch<
    React.SetStateAction<SortingConfig | undefined>
  >;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
  const [dialogPurpose, setDialogPurpose] = useState<
    DialogPurpose | undefined
  >();
  const [deletingPropertyId, setDeletingPropertyId] = useState<string>("");
  const [updatePropertyId, setUpdatePropertyId] = useState<string>("");
  const [updatePropertyStatus, setUpdatePropertyStatus] = useState<
    PropertyStatus | undefined
  >();
  const [sortSold, setSortSold] = useState<boolean>(false);
  const [highlightRegisteredClients, setHighlightRegisteredClients] =
    useState<boolean>(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function deleteProperty() {
    PropertyApi.deleteProperty(deletingPropertyId)
      .then(() => {
        toast(t("property.deleteSuccess"), {
          type: "success",
        });
        setProperties((prevState) =>
          prevState.filter((p) => p.id !== deletingPropertyId)
        );
        setDeletingPropertyId("");
      })
      .catch(() => {
        toast(t("property.catchDelete"), {
          type: "error",
        });
      });
  }

  function editProperty(id: string) {
    navigate(`/properties/edit/${id}`);
  }

  function updateStatus() {
    const property = properties.find((p) => p.id === updatePropertyId);
    if (property && updatePropertyStatus) {
      const propertyData: PropertyData = {
        street: property.address.street,
        number: property.address.number,
        city: property.address.city,
        countryCode: property.address.country.code,
        price: property.price,
        commission: property.commission,
        commissionSplit: property.commissionSplit,
        description:
          property.descriptions.find(
            (d) => d.language.code === LanguageCode.ENGLISH
          )?.description || "",
        region: property.region,
        subregion: property.subregion,
        propertyGeneralType: property.generalType,
        propertySubType: property.subType,
        bedrooms: property.bedrooms,
        bathrooms: property.bathrooms,
        builtSurface: property.builtSurface,
        interiorSurface: property.interiorSurface,
        terraceSurface: property.terraceSurface,
        totalSurface: property.totalSurface,
        floor: property.floor,
        completionYear: property.completionYear,
        toilets: property.toilets,
        numberOfFloors: property.numberOfFloors,
        pool: property.pool,
        garden: property.garden,
        garage: property.garage,
        orientation: property.orientation,
        communityFees: property.communityFees,
        parkingSpaces: property.parkingSpaces,
        IBI: property.IBI,
        garbageTax: property.garbageTax,
        pictureUrls: property.pictureUrls,
        documents: property.documents,
        agentId: property.agent.id,
        status: updatePropertyStatus,
        googleMapsUrl: property.googleMapsUrl,
      };

      PropertyApi.updateProperty(property.id, propertyData, property.agencyId)
        .then((r) => {
          setProperties((prevState) =>
            prevState.map((p) => (p.id === r.id ? r : p))
          );
          const toastId =
            updatePropertyStatus === PropertyStatus.AVAILABLE
              ? t("propertyList.markAvailable")
              : t("propertyList.markSold");
          toast(toastId, {
            type: "success",
          });

          setUpdatePropertyStatus(undefined);
          setUpdatePropertyId("");
        })
        .catch(() => {
          const toastId = t("general.somethingWrong");
          toast(toastId, {
            type: "error",
          });
        });
    }
  }

  return (
    <>
      <div className={"page personal-home"}>
        <div className={"side-nav"}>
          <SideNavigation items={HOME_ITEMS} />
        </div>
        <div className="page-content-no-margin">
          <div className="statistics">
            <div
              className={`item ${!sortSold ? "active" : ""}`}
              onClick={() => {
                setSortSold((prevState) => !prevState);
                setSortingConfig(undefined);
              }}
            >
              <HouseGrayed className="item-image" />
              <div className="item-details">
                <div className="item-number">
                  {propertyStatistics?.numberOfProperties}
                </div>
                <div className="item-text">{t("statistics.properties")}</div>
              </div>
            </div>
            <div
              className={`item ${sortSold ? "active" : ""}`}
              onClick={() => {
                if (sortSold) {
                  setSortingConfig(undefined);
                } else {
                  setSortingConfig({
                    field: PropertyField.STATUS,
                    order: SortingOrder.DESC,
                  });
                }

                setSortSold((prevState) => !prevState);
              }}
            >
              {sortSold ? (
                <AuctionGrayed className="item-image" />
              ) : (
                <Auction className="item-image" />
              )}
              <div className="item-details">
                <div className="item-number">
                  {propertyStatistics?.numberOfSoldProperties}
                </div>
                <div className="item-text">
                  {t("statistics.propertiesSold")}
                </div>
              </div>
            </div>
            <div
              className={`item ${highlightRegisteredClients ? "bold" : ""}`}
              onClick={() => {
                setHighlightRegisteredClients(true);
                setTimeout(() => setHighlightRegisteredClients(false), 1500);
              }}
            >
              <img src={UserImage} className="item-image" />
              <div className="item-details">
                <div className="item-number">
                  {propertyStatistics?.numberOfRegisteredClients}
                </div>
                <div className="item-text">
                  {t("statistics.confirmedClients")}
                </div>
              </div>
            </div>
          </div>
          {windowSize[0] > 1350 ? (
            <PropertyList
              properties={properties}
              sortingConfig={sortingConfig}
              setSortingConfig={setSortingConfig}
              enableLayoutSwitch={false}
              enableEditButtons={enableEditButtons}
              totalPages={propertyTotalPages}
              deleteProperty={(id: string) => {
                setDialogPurpose(DialogPurpose.Delete);
                setDeletingPropertyId(id);
                setIsShowingModal(true);
              }}
              editProperty={(id: string) => editProperty(id)}
              retrievePage={(page: number) => retrievePage(page)}
              updateStatus={(id: string, status: PropertyStatus) => {
                setUpdatePropertyId(id);
                setUpdatePropertyStatus(status);
                setDialogPurpose(DialogPurpose.Update);
                setIsShowingModal(true);
              }}
              showFullStatus={true}
              showRegisteredClients={true}
              highlightRegisteredClients={highlightRegisteredClients}
              loading={loading}
            />
          ) : (
            <PropertyGrid
              properties={properties}
              retrievePage={(page: number) => retrievePage(page)}
              totalPages={propertyTotalPages}
              enableLayoutSwitch={true}
              loading={loading}
            />
          )}
        </div>
      </div>
      <Dialog
        isOpen={isShowingModal}
        onRequestClose={() => setIsShowingModal(false)}
        title={
          dialogPurpose === DialogPurpose.Delete
            ? t("propertyList.deleteTitle")
            : t("propertyList.updateTitle")
        }
        size={"msmall"}
      >
        {dialogPurpose === DialogPurpose.Delete && (
          <DialogContent
            description={t("property.deleteProperty")}
            onClick={() => {
              deleteProperty();
              setIsShowingModal(false);
            }}
          />
        )}
        {dialogPurpose === DialogPurpose.Update && (
          <DialogContent
            description={
              updatePropertyStatus === PropertyStatus.AVAILABLE
                ? t("propertyList.updateAsAvailable")
                : t("propertyList.updateAsSold")
            }
            onClick={() => {
              updateStatus();
              setIsShowingModal(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
}

function DialogContent({
  description,
  onClick,
}: {
  description?: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={"report-dialog-content"}>
      <div className={"description"}>{description}</div>
      <div className={"button-wrapper"}>
        <Button color="danger" onClick={onClick}>
          {t("general.confirm")}
        </Button>
      </div>
    </div>
  );
}
