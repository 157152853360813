import "./AgencyList.css";
import { useEffect, useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import { AgencyWithStats } from "../../api/AdminApi";
import { dateToReadableFormat } from "../../util/dateToReadableFormat";

export type AgencyInfoType =
  | "NONE"
  | "NAME"
  | "NUMBER_OF_USERS"
  | "NUMBER_OF_PROPERTIES"
  | "PAYMENT_STATUS"
  | "PAYMENT_EXPIRATION_DATE"
  | "SUBSCRIPTION_TIER"
  | "CREATED_AT";

export function AgencyList({
  agenciesWithStats,
  totalPages,
  retrievePage,
}: {
  agenciesWithStats: AgencyWithStats[];
  totalPages: number;
  retrievePage: (page: number) => void;
}) {
  const categoryTypeValues: AgencyInfoType[] = [
    "NAME",
    "NUMBER_OF_PROPERTIES",
    "NUMBER_OF_USERS",
    "PAYMENT_STATUS",
    "PAYMENT_EXPIRATION_DATE",
    "SUBSCRIPTION_TIER",
    "CREATED_AT",
  ];

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    retrievePage(currentPage);
  }, [currentPage]);

  return (
    <div className={"agency-list"}>
      <div className={"navigation"}>
        <Navigation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
      <div className={"header"}>
        <div className={"items"}>
          {categoryTypeValues.map((el, index) => {
            return (
              <div className={"item"} key={index}>
                {capitalizeFirstLetter(el.replaceAll("_", " "))}
              </div>
            );
          })}
        </div>
      </div>
      {agenciesWithStats.map((el, index) => {
        return (
          <div key={index} className={"user-item"}>
            <div className={"content"}>
              <div className={"item bold"}>{el.name}</div>
              <div className={"item"}>{el.numberOfProperties}</div>
              <div className={"item"}>{el.numberOfUsers}</div>
              <div className={"item"}>
                {capitalizeFirstLetter(el.paymentStatus.replaceAll("_", " "))}
              </div>
              <div className={"item"}>
                {el.paymentExpiration
                  ? dateToReadableFormat(new Date(el.paymentExpiration))
                  : "-"}
              </div>
              <div className={"item"}>
                {capitalizeFirstLetter(el.subscriptionTier)}
              </div>
              <div className={"item"}>
                {dateToReadableFormat(new Date(el.createdAt))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
