import React, { FunctionComponent, SVGProps } from "react";
import "./SideNavigation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as SemiArrowRightGrayed } from "../../assets/semi-arrow-right-grayed.svg";
import { Tier } from "../../api/AgencyApi";
import { getUser } from "../../util/getUser";
import { UserType } from "../../interfaces/UserTypes";

export type NavigationItem = {
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  IconLight: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  titleKey: string;
  route: string;
  allowedTiers?: Tier[];
  allowedUsers?: UserType[];
  hideIfNoAgency?: boolean;
};

export function SideNavigation({ items }: { items: NavigationItem[] }) {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const user = getUser();
  if (!user) {
    return null;
  }
  const isAdmin = user.type === UserType.ADMIN;
  const tier = user.tier;
  const agencyId = user.agencyId;

  return (
    <div className="side-navigation">
      {items.map((el, index) => {
        const { Icon, IconLight } = el;
        const isActive = el.route === location.pathname;

        const disableNavigation =
          el.allowedTiers &&
          el.allowedTiers.length > 0 &&
          tier &&
          !el.allowedTiers.includes(tier);

        const hideNavigation =
          (!isAdmin &&
            el.allowedUsers &&
            !el.allowedUsers?.includes(user?.type)) ||
          (el.hideIfNoAgency && !agencyId);

        return hideNavigation ? null : (
          <div
            className={`item ${isActive ? "active" : ""} ${
              disableNavigation ? "disabled" : ""
            }`}
            key={index}
            onClick={() => {
              if (!disableNavigation) {
                navigate(el.route);
              }
            }}
          >
            <div className={"flex"}>
              {isActive ? <IconLight /> : <Icon />}
              <div className={"title"}>{t(el.titleKey)}</div>
            </div>
            {isActive && (
              <div className={"flex justify-center align-center"}>
                <SemiArrowRightGrayed className={"arrow-right"} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
