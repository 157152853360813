import { SideNavigation } from "../../components/side-navigation/SideNavigation";
import { SETTINGS_ITEMS } from "../../constants/SettingsItems";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileForm } from "../../components/profile-form/ProfileForm";
import AccountApi, { AccountData } from "../../api/AccountApi";
import { toast } from "react-toastify";
import {
  getCorrectPasswordString,
  isStrongPassword,
} from "../../util/isStrongPassword";
import { User } from "../../interfaces/UserTypes";

export function ProfileSettings() {
  const { t } = useTranslation();

  const [profile, setProfile] = useState<User | undefined>();

  useEffect(() => {
    AccountApi.getAccount().then((r) => setProfile(r));
  }, []);

  async function updateProfile(accountData: AccountData) {
    if (accountData.password) {
      const passwordStrength = isStrongPassword(accountData.password);

      if (!passwordStrength.ok) {
        const toastId = t(getCorrectPasswordString(passwordStrength)) as string;
        toast(toastId, {
          type: "error",
          autoClose: 5000,
          position: "bottom-right",
        });

        return;
      }
    }

    try {
      await toast.promise(
        AccountApi.updateAccount(accountData).then((r) => setProfile(r)),
        {
          pending: t("profileSettings.updating"),
          success: t("profileSettings.success"),
          error: t("general.somethingWrong"),
        }
      );
    } catch (e) {
      // toast will handle the error
    }
  }

  return (
    <div className={"page settings"}>
      <div className={"side-nav"}>
        <SideNavigation items={SETTINGS_ITEMS} />
      </div>
      <div className="page-content-no-margin">
        <div className={"title"}>{t("settings.myProfile")}</div>
        {profile ? (
          <ProfileForm
            profile={profile}
            onSubmit={(accountData: AccountData) => updateProfile(accountData)}
          />
        ) : null}
      </div>
    </div>
  );
}
