import "./PropertyForm.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropertyApi from "../../api/PropertyApi";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import MediaApi from "../../api/MediaApi";
import { Input } from "../input/Input";
import { Select } from "../select/Select";
import { Textarea } from "../textarea/Textarea";
import { FileUpload } from "../file-upload/FileUpload";
import { ImagePreview } from "../image-preview/ImagePreview";
import { getUser } from "../../util/getUser";
import { AxiosError } from "axios";
import { LanguageCode } from "../../api/LanguageApi";
import { PropertyTypes } from "../../constants/PropertyTypes";
import { Button } from "../button/Button";
import { routes } from "../../constants/routes";
import { regions } from "../../constants/Regions";
import { Garage, Garden, Orientation, Pool } from "../../enums/PropertyEnums";
import {
  Document,
  DocumentType,
  PropertyData,
  PropertyItem,
  PropertyStatus,
} from "../../api/PropertyTypes";
import { countries } from "../../constants/Countries";
import { addThousandSeparators } from "../../util/addThousandSeparators";
import { roundByNDecimals } from "../../util/roundByNDecimals";
import { ExternalPropertyItem } from "../../api/ExternalPropertyTypes";
import { DocumentPreview } from "../document-preview/DocumentPreview";

function removeNonNumeric(num: string) {
  return num.replace(/[^0-9,]/g, "");
}

function roundBy2Decimals(value: number): number {
  return roundByNDecimals(value, 2);
}

function roundPriceByNDecimals(price: string, decimals: number): string {
  const splitPrice = price.split(",");
  if (splitPrice.length === 1) {
    return price;
  }

  if (splitPrice.length !== 2) {
    return price;
  }

  const roundedDecimals = splitPrice[1].slice(0, decimals);
  return `${splitPrice[0]},${roundedDecimals}`;
}

function padDecimals(price: string): string {
  const splitPrice = price.split(",");
  if (splitPrice.length === 1) {
    return `${price},00`;
  }

  if (splitPrice.length !== 2) {
    return price;
  }

  let roundedDecimals = splitPrice[1];
  if (roundedDecimals.length < 2) {
    roundedDecimals = roundedDecimals + "0".repeat(2 - roundedDecimals.length);
  }
  return `${splitPrice[0]},${roundedDecimals}`;
}

export type PropertyPicture = File | string;
export type PropertyDocument = File | Document;

function isPropertyItem(
  property: PropertyItem | ExternalPropertyItem
): property is PropertyItem {
  return (property as PropertyItem).address !== undefined;
}

export function PropertyForm({
  property,
}: {
  property?: PropertyItem | ExternalPropertyItem;
}) {
  const { t } = useTranslation();
  const user = getUser();
  const navigate = useNavigate();

  const propertyStatusOptions = Object.keys(PropertyStatus).map((e) => ({
    label: capitalizeFirstLetter(e.replaceAll("_", " ")),
    value: e,
  }));

  const mainRegions: string[] = useMemo(() => Object.keys(regions).sort(), []);

  const [propertyStatus, setPropertyStatus] = useState<PropertyStatus>(
    PropertyStatus.AVAILABLE
  );
  const [street, setStreet] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("ES");
  const [googleMapsUrl, setGoogleMapsUrl] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState<string | undefined>();
  const [commission, setCommission] = useState<number | undefined>();
  const [commissionSplit, setCommissionSplit] = useState<number | undefined>();
  const [region, setRegion] = useState<string>("");
  const [subregion, setSubregion] = useState<string>("");
  const [propertyGeneralType, setPropertyGeneralType] = useState<string>("");
  const [propertySubType, setPropertySubType] = useState<string>("");
  const [bedrooms, setBedrooms] = useState<number | undefined>();
  const [bathrooms, setBathrooms] = useState<number | undefined>();
  const [builtSurface, setBuiltSurface] = useState<number | undefined>();
  const [interiorSurface, setInteriorSurface] = useState<number | undefined>();
  const [terraceSurface, setTerraceSurface] = useState<number | undefined>();
  const [totalSurface, setTotalSurface] = useState<number | undefined>();
  const [floor, setFloor] = useState<number | undefined>();
  const [completionYear, setCompletionYear] = useState<number | undefined>();
  const [toilets, setToilets] = useState<number | undefined>();
  const [numberOfFloors, setNumberOfFloors] = useState<number | undefined>();
  const [pool, setPool] = useState<Pool>(Pool.UNDEFINED);
  const [garden, setGarden] = useState<Garden>(Garden.UNDEFINED);
  const [garage, setGarage] = useState<Garage>(Garage.UNDEFINED);
  const [parkingSpaces, setParkingSpaces] = useState<number | undefined>();
  const [orientation, setOrientation] = useState<Orientation>(Orientation.N);
  const [communityFees, setCommunityFees] = useState<string | undefined>();
  const [IBI, setIBI] = useState<string | undefined>();
  const [garbageTax, setGarbageTax] = useState<string | undefined>();

  const [pictures, setPictures] = useState<PropertyPicture[]>([]);
  const [floorPlan, setFloorPlan] = useState<PropertyDocument[]>([]);
  const [landRegistryExtract, setLandRegistryExtract] = useState<
    PropertyDocument[]
  >([]);
  const [energyCertification, setEnergyCertification] = useState<
    PropertyDocument[]
  >([]);

  const regionOptions = useMemo(
    () => [
      { label: t("propertyForm.selectRegion"), value: "" },
      ...mainRegions.map((region) => ({
        label: region,
        value: region,
      })),
    ],
    [mainRegions, t]
  );

  const subregionOptions = useMemo(() => {
    if (!region || !regions[region]) {
      return [{ label: t("propertyForm.selectRegionFirst"), value: "" }];
    }

    return [
      { label: t("propertyForm.selectSubRegion"), value: "" },
      ...regions[region].map((subregion) => ({
        label: subregion,
        value: subregion,
      })),
    ];
  }, [region, t]);

  const propertyGeneralOptions = useMemo(
    () => [
      { label: t("propertyForm.selectType"), value: "" },
      ...Object.keys(PropertyTypes).map((e) => ({
        label: capitalizeFirstLetter(e),
        value: e,
      })),
    ],
    [t]
  );

  const propertySubTypeOptions = useMemo(() => {
    if (!propertyGeneralType || !PropertyTypes[propertyGeneralType]) {
      return [{ label: t("propertyForm.selectGeneralTypeFirst"), value: "" }];
    }

    return [
      { label: t("propertyForm.selectSubType"), value: "" },
      ...PropertyTypes[propertyGeneralType].map((e) => ({
        label: capitalizeFirstLetter(e),
        value: e,
      })),
    ];
  }, [propertyGeneralType, t]);

  const setPropertyDetails = (
    property: PropertyItem | ExternalPropertyItem
  ) => {
    setPrice(
      property.price
        ? padDecimals(property.price.toString().replace(".", ","))
        : ""
    );
    setBedrooms(property.bedrooms ?? undefined);
    setBathrooms(property.bathrooms ?? undefined);
    setBuiltSurface(property.builtSurface ?? undefined);
    setInteriorSurface(property.interiorSurface ?? undefined);
    setTerraceSurface(property.terraceSurface ?? undefined);
    setFloor(property.floor ?? undefined);
    setTotalSurface(property.totalSurface ?? undefined);
    setCompletionYear(property.completionYear ?? undefined);
    setPictures(property.pictureUrls);
    setGoogleMapsUrl(property.googleMapsUrl ?? "");
    setPropertyStatus(property.status);
    setToilets(property.toilets ?? undefined);
    setNumberOfFloors(property.numberOfFloors ?? undefined);
    setPool(property.pool);
    setGarden(property.garden);
    setGarage(property.garage);
    setOrientation(property.orientation ?? Orientation.N);
    setParkingSpaces(property.parkingSpaces ?? undefined);
    setCommunityFees(
      property.communityFees
        ? padDecimals(property.communityFees.toString().replace(".", ","))
        : undefined
    );
    setIBI(
      property.IBI
        ? padDecimals(property.IBI.toString().replace(".", ","))
        : undefined
    );
    setGarbageTax(
      property.garbageTax
        ? padDecimals(property.garbageTax.toString().replace(".", ","))
        : undefined
    );
  };

  const setPropertyItemDetails = (property: PropertyItem) => {
    setStreet(property.address?.street ?? "");
    setNumber(property.address?.number ?? "");
    setCity(property.address?.city ?? "");
    setCountryCode(property.address?.country.code ?? "");
    setCommission(roundByNDecimals(property.commission * 100, 2));
    setCommissionSplit(roundByNDecimals(property.commissionSplit * 100, 2));
    setDescription(
      property.descriptions.find(
        (d) => d.language.code === LanguageCode.ENGLISH
      )?.description || ""
    );
    setRegion(property.region);
    setSubregion(property.subregion);
    setPropertyGeneralType(property.generalType);
    setPropertySubType(property.subType);
    setFloorPlan(
      property.documents?.filter((f) => f.type === DocumentType.FLOOR_PLAN)
    );
    setLandRegistryExtract(
      property.documents?.filter(
        (f) => f.type === DocumentType.LAND_REGISTRY_EXTRACT
      )
    );
    setEnergyCertification(
      property.documents?.filter(
        (f) => f.type === DocumentType.ENERGY_CERTIFICATION
      )
    );
  };

  const setExternalPropertyDetails = (property: ExternalPropertyItem) => {
    const { externalAddress } = property;
    setStreet(externalAddress?.street || "");
    setNumber(externalAddress?.number || "");
    setCity(externalAddress?.city || "");
    setCountryCode(externalAddress?.country.code || "");
    setDescription(
      property.externalDescriptions.find(
        (d) => d.language.code === LanguageCode.ENGLISH
      )?.description || ""
    );

    setRegion(property.region || "");
    setSubregion(property.subregion || "");
    setPropertyGeneralType(property.generalType || "");
    setPropertySubType(property.subType || "");
    setFloorPlan(
      property.externalDocuments?.filter(
        (f) => f.type === DocumentType.FLOOR_PLAN
      )
    );
    setLandRegistryExtract(
      property.externalDocuments?.filter(
        (f) => f.type === DocumentType.LAND_REGISTRY_EXTRACT
      )
    );
    setEnergyCertification(
      property.externalDocuments?.filter(
        (f) => f.type === DocumentType.ENERGY_CERTIFICATION
      )
    );
  };

  let formTitle;
  if (property) {
    if ("source" in property && property.source) {
      formTitle = t("property.addNewPropertyExternal", {
        source: capitalizeFirstLetter(property.source),
        ref: property.reference,
      });
    } else {
      formTitle = t("property.editProperty");
    }
  } else {
    formTitle = t("property.addNewProperty");
  }

  useEffect(() => {
    if (!property) return;

    setPropertyDetails(property);

    if (isPropertyItem(property)) {
      setPropertyItemDetails(property);
    } else {
      setExternalPropertyDetails(property);
    }
  }, [property]);

  async function createOrUpdateProperty() {
    try {
      if (user && price && commission && commissionSplit) {
        if (pictures.length <= 0) {
          const toastId = t("propertyForm.picturesEmpty");
          toast(toastId, { type: "error", toastId: toastId });
          return;
        }

        // Start the toast with a pending message
        const toastId = toast.loading(
          property ? t("propertyForm.saving") : t("propertyForm.creating")
        );

        try {
          // Upload pictures to S3
          const pictureUrls = [];
          for (const picture of pictures) {
            if (picture instanceof File) {
              const response = await MediaApi.uploadMedia(picture);
              pictureUrls.push(response.url);
            } else {
              pictureUrls.push(picture);
            }
          }

          // Upload documents to S3
          const documents: Document[] = [];
          for (const document of floorPlan) {
            if (document instanceof File) {
              const response = await MediaApi.uploadMedia(document);
              documents.push({
                url: response.url,
                type: DocumentType.FLOOR_PLAN,
              });
            } else {
              documents.push(document);
            }
          }
          for (const document of landRegistryExtract) {
            if (document instanceof File) {
              const response = await MediaApi.uploadMedia(document);
              documents.push({
                url: response.url,
                type: DocumentType.LAND_REGISTRY_EXTRACT,
              });
            } else {
              documents.push(document);
            }
          }
          for (const document of energyCertification) {
            if (document instanceof File) {
              const response = await MediaApi.uploadMedia(document);
              documents.push({
                url: response.url,
                type: DocumentType.ENERGY_CERTIFICATION,
              });
            } else {
              documents.push(document);
            }
          }

          // Property API calls
          let propertyResponse;
          const propertyData: PropertyData = {
            street,
            number,
            city,
            countryCode,
            price: priceToNumber(price),
            commission: roundByNDecimals(commission / 100, 4), // convert number to percentage
            commissionSplit: roundByNDecimals(commissionSplit / 100, 4),
            description,
            region,
            subregion,
            propertyGeneralType,
            propertySubType,
            bedrooms,
            bathrooms,
            builtSurface,
            interiorSurface,
            terraceSurface,
            totalSurface,
            floor,
            completionYear,
            toilets,
            numberOfFloors,
            pool,
            garden,
            garage,
            orientation,
            parkingSpaces,
            communityFees: communityFees
              ? priceToNumber(communityFees)
              : undefined,
            IBI: IBI ? priceToNumber(IBI) : undefined,
            garbageTax: garbageTax ? priceToNumber(garbageTax) : undefined,
            reference: property?.reference || undefined,
            pictureUrls,
            documents,
            agentId: user.id,
            status: propertyStatus,
            googleMapsUrl,
          };
          if (property && isPropertyItem(property)) {
            propertyResponse = await PropertyApi.updateProperty(
              property.id,
              propertyData,
              user.agencyId
            );
          } else {
            propertyResponse = await PropertyApi.createProperty(
              propertyData,
              user.agencyId,
              property && !isPropertyItem(property) ? property.id : undefined
            );
          }

          // On success, update the toast to a success message
          toast.update(toastId, {
            type: "success",
            render: property
              ? t("propertyForm.successSaved")
              : t("propertyForm.successCreated"),
            isLoading: false,
            autoClose: 5000,
          });
          navigate(`/property/${propertyResponse.id}`);
        } catch (e) {
          // On error, determine the correct message and update the toast
          let errorMessage = t("propertyForm.error");
          if (
            e instanceof AxiosError &&
            e.response &&
            e.response.status === 409
          ) {
            errorMessage = t("propertyForm.duplicate");
          }
          toast.update(toastId, {
            type: "error",
            render: errorMessage,
            isLoading: false,
            autoClose: 5000,
          });
        }
      }
    } catch (e) {
      // Handle any other errors
    }
  }

  function priceToNumber(price: string) {
    return parseFloat(price.replace(",", "."));
  }

  function calculateAgencyListingCommission() {
    return price && commission && commissionSplit
      ? addThousandSeparators(
          roundBy2Decimals(
            priceToNumber(price) *
              (commission / 100) *
              (1 - commissionSplit / 100)
          ).toString()
        )
      : "";
  }

  function calculateBuyingAgencyCommission() {
    return price && commission && commissionSplit
      ? addThousandSeparators(
          roundBy2Decimals(
            priceToNumber(price) * (commission / 100) * (commissionSplit / 100)
          ).toString()
        )
      : "";
  }

  return (
    <div className={"form"}>
      <div className={"title"}>{formTitle}</div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await createOrUpdateProperty();
        }}
      >
        <div className={"section"}>
          <div className={"subtitle"}>{t("propertyForm.generalInfo")}</div>
          <Select
            value={propertyStatus || ""}
            onChange={(input) => setPropertyStatus(input as PropertyStatus)}
            label={t("propertyForm.propertyStatus")}
            required={true}
            options={propertyStatusOptions}
            showAsterisk={true}
          />
          <div className={"row"}>
            <Select
              value={propertyGeneralType || ""}
              onChange={(input) => setPropertyGeneralType(input)}
              label={t("propertyForm.propertyGeneralType")}
              required={true}
              options={propertyGeneralOptions}
              showAsterisk={true}
            />
            <Select
              value={propertySubType || ""}
              onChange={(input) => setPropertySubType(input)}
              label={t("propertyForm.propertySubType")}
              required={true}
              options={propertySubTypeOptions}
              showAsterisk={true}
            />
          </div>
          <div className={"row"}>
            <Input
              value={price ? addThousandSeparators(price.toString()) : ""}
              onChange={(input) => {
                if (input === "") {
                  setPrice(undefined);
                } else {
                  setPrice(roundPriceByNDecimals(removeNonNumeric(input), 2));
                }
              }}
              placeholder={t("propertyForm.price")}
              required={true}
              type={"text"}
              min={0}
              showAsterisk={true}
              onBlur={() => {
                if (price) {
                  setPrice(padDecimals(price));
                }
              }}
            />
          </div>
          <div className={"row"}>
            <Input
              value={commission?.toString() || ""}
              onChange={(input) => {
                if (input === "") {
                  setCommission(undefined);
                } else if (parseFloat(input) <= 100 && parseFloat(input) >= 0) {
                  setCommission(roundBy2Decimals(parseFloat(input)));
                }
              }}
              placeholder={t("propertyForm.commission")}
              required={true}
              type={"number"}
              min={0}
              max={100}
              step={0.01}
              showAsterisk={true}
            >
              {price && commission
                ? t("property.AgencyTotalCommission", {
                    val: addThousandSeparators(
                      roundBy2Decimals(
                        (priceToNumber(price) * commission) / 100
                      ).toString()
                    ),
                  })
                : ""}
            </Input>
            <Input
              value={commissionSplit?.toString() || ""}
              onChange={(input) => {
                if (input === "") {
                  setCommissionSplit(undefined);
                } else if (parseFloat(input) <= 100 && parseFloat(input) >= 0) {
                  setCommissionSplit(roundBy2Decimals(parseFloat(input)));
                }
              }}
              placeholder={t("propertyForm.commissionSplit")}
              required={true}
              type={"number"}
              min={0}
              max={100}
              step={0.01}
              showAsterisk={true}
            >
              {price && commission && commissionSplit ? (
                <div>
                  {t("property.SellingCommissionSplit", {
                    val: calculateBuyingAgencyCommission(),
                  })}
                  <br />
                  {t("property.ListingCommissionSplit", {
                    val: calculateAgencyListingCommission(),
                  })}
                </div>
              ) : (
                ""
              )}
            </Input>
          </div>
          <Textarea
            value={description}
            onChange={setDescription}
            placeholder={t("propertyForm.description")}
            required={true}
            rows={7}
          />
        </div>
        <div className={"section"}>
          <div className={"subtitle"}>{t("propertyForm.location")}</div>
          <div className={"row"}>
            <Input
              value={street}
              onChange={setStreet}
              placeholder={t("address.street")}
              required={false}
              type={"text"}
            />
            <Input
              value={number}
              onChange={setNumber}
              placeholder={t("address.number")}
              required={false}
              type={"text"}
            />
          </div>
          <div className={"row"}>
            <Input
              value={city}
              onChange={setCity}
              placeholder={t("address.city")}
              required={true}
              type={"text"}
              showAsterisk={true}
            />
            <Select
              value={countryCode}
              onChange={setCountryCode}
              label={t("address.country")}
              required={true}
              options={[
                { label: t("propertyForm.selectCountry"), value: "" },
              ].concat(
                countries.map((c) => ({ label: c.name, value: c.code }))
              )}
              showAsterisk={true}
            />
          </div>
          <div className={"row"}>
            <Select
              value={region || ""}
              onChange={(input) => setRegion(input)}
              label={t("propertyForm.region")}
              required={true}
              options={regionOptions}
              showAsterisk={true}
            />

            <Select
              value={subregion || ""}
              onChange={(input) => setSubregion(input)}
              label="Subregion"
              required={true}
              options={subregionOptions}
              showAsterisk={true}
            />
          </div>
          <div className={"row"}>
            <Input
              value={googleMapsUrl}
              onChange={setGoogleMapsUrl}
              placeholder={t("propertyDetail.googleMapsUrl")}
              required={false}
              type={"text"}
            />
          </div>
        </div>
        <div className={"section"}>
          <div className={"subtitle"}>{t("propertyForm.specifications")}</div>
          <div className={"row"}>
            <Input
              value={bedrooms !== undefined ? bedrooms.toString() : ""}
              onChange={(input) => setBedrooms(parseFloat(input))}
              placeholder={t("propertyForm.bedrooms")}
              required={false}
              type={"number"}
              min={0}
            />
            <Input
              value={bathrooms !== undefined ? bathrooms.toString() : ""}
              onChange={(input) => setBathrooms(parseFloat(input))}
              placeholder={t("propertyForm.bathrooms")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
          <div className={"row"}>
            <Input
              value={builtSurface !== undefined ? builtSurface.toString() : ""}
              onChange={(input) => setBuiltSurface(parseFloat(input))}
              placeholder={t("propertyForm.builtSurface")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
            <Input
              value={
                interiorSurface !== undefined ? interiorSurface.toString() : ""
              }
              onChange={(input) => setInteriorSurface(parseFloat(input))}
              placeholder={t("propertyForm.interiorSurface")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
          <div className={"row"}>
            <Input
              value={
                terraceSurface !== undefined ? terraceSurface.toString() : ""
              }
              onChange={(input) => setTerraceSurface(parseFloat(input))}
              placeholder={t("propertyForm.terraceSurface")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
            <Input
              value={totalSurface !== undefined ? totalSurface.toString() : ""}
              onChange={(input) => setTotalSurface(parseFloat(input))}
              placeholder={t("propertyForm.totalSurface")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
          <div className={"row"}>
            <Input
              value={floor !== undefined ? floor.toString() : ""}
              onChange={(input) => setFloor(parseFloat(input))}
              placeholder={t("propertyForm.floor")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
            <Input
              value={
                completionYear !== undefined ? completionYear.toString() : ""
              }
              onChange={(input) => setCompletionYear(parseFloat(input))}
              placeholder={t("propertyForm.completionYear")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
          <div className={"row"}>
            <Input
              value={toilets !== undefined ? toilets.toString() : ""}
              onChange={(input) => setToilets(parseFloat(input))}
              placeholder={t("propertyForm.toilets")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
            <Input
              value={
                numberOfFloors !== undefined ? numberOfFloors.toString() : ""
              }
              onChange={(input) => setNumberOfFloors(parseFloat(input))}
              placeholder={t("propertyForm.numberOfFloors")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
          <div className={"row"}>
            <Select
              value={pool}
              onChange={(input) => setPool(input as Pool)}
              label={t("propertyForm.pool")}
              required={false}
              options={Object.values(Pool).map((p) => ({
                label: capitalizeFirstLetter(p.replaceAll("_", " ")),
                value: p,
              }))}
            />
            <Select
              value={garden}
              onChange={(input) => setGarden(input as Garden)}
              label={t("propertyForm.garden")}
              required={false}
              options={Object.values(Garden).map((g) => ({
                label: capitalizeFirstLetter(g.replaceAll("_", " ")),
                value: g,
              }))}
            />
          </div>
          <div className={"row"}>
            <Select
              value={garage}
              onChange={(input) => setGarage(input as Garage)}
              label={t("propertyForm.garage")}
              required={false}
              options={Object.values(Garage).map((g) => ({
                label: capitalizeFirstLetter(g.replaceAll("_", " ")),
                value: g,
              }))}
            />
            <Select
              value={orientation}
              onChange={(input) => setOrientation(input as Orientation)}
              label={t("propertyForm.orientation")}
              required={false}
              options={Object.values(Orientation).map((o) => ({
                label: capitalizeFirstLetter(o.replaceAll("_", " ")),
                value: o,
              }))}
            />
          </div>
          <div className={"row"}>
            <Input
              value={
                parkingSpaces !== undefined ? parkingSpaces.toString() : ""
              }
              onChange={(input) => setParkingSpaces(parseInt(input))}
              placeholder={t("propertyForm.parkingSpaces")}
              required={false}
              type={"number"}
              min={0}
              step={1}
            />

            <Input
              value={
                communityFees
                  ? addThousandSeparators(communityFees.toString())
                  : ""
              }
              onChange={(input) => {
                if (input === "") {
                  setCommunityFees(undefined);
                } else {
                  setCommunityFees(
                    roundPriceByNDecimals(removeNonNumeric(input), 2)
                  );
                }
              }}
              placeholder={t("propertyForm.communityFees")}
              required={false}
              type={"text"}
              min={0}
              showAsterisk={true}
              onBlur={() => {
                if (communityFees) {
                  setCommunityFees(padDecimals(communityFees));
                }
              }}
            />
          </div>
          <div className={"row"}>
            <Input
              value={IBI ? addThousandSeparators(IBI.toString()) : ""}
              onChange={(input) => {
                if (input === "") {
                  setIBI(undefined);
                } else {
                  setIBI(roundPriceByNDecimals(removeNonNumeric(input), 2));
                }
              }}
              placeholder={t("propertyForm.IBI")}
              required={false}
              type={"text"}
              min={0}
              showAsterisk={true}
              onBlur={() => {
                if (IBI) {
                  setIBI(padDecimals(IBI));
                }
              }}
            />
            <Input
              value={
                garbageTax ? addThousandSeparators(garbageTax.toString()) : ""
              }
              onChange={(input) => {
                if (input === "") {
                  setGarbageTax(undefined);
                } else {
                  setGarbageTax(
                    roundPriceByNDecimals(removeNonNumeric(input), 2)
                  );
                }
              }}
              placeholder={t("propertyForm.garbageTax")}
              required={false}
              type={"text"}
              min={0}
              showAsterisk={true}
              onBlur={() => {
                if (garbageTax) {
                  setGarbageTax(padDecimals(garbageTax));
                }
              }}
            />
          </div>
        </div>
        <div className={"section"}>
          <div className={"subtitle"}>
            {t("propertyForm.picturesAndDocuments")}
          </div>
          <div className={"row"}>
            <FileUpload
              setFiles={(files) =>
                setPictures((prevState) => [...prevState, ...files])
              }
              label={t("propertyForm.pictures")}
              required={true}
              accept={"image/*"}
              multiple={true}
            />
          </div>
          <div className={"previews"}>
            {pictures.map((f, index) => (
              <ImagePreview key={index} file={f} setFiles={setPictures} />
            ))}
          </div>
          <div className={"row"}>
            <FileUpload
              setFiles={(docs) =>
                setFloorPlan((prevState) => [...prevState, ...docs])
              }
              label={t("propertyForm.floorPlan")}
              required={false}
              accept={"application/pdf"}
              multiple={false}
            />
          </div>
          <div className={"flex flex-column"}>
            {floorPlan &&
              floorPlan.map((d, index) => (
                <DocumentPreview
                  key={index}
                  index={index}
                  file={d}
                  setFiles={setFloorPlan}
                />
              ))}
          </div>
          <div className={"row"}>
            <FileUpload
              setFiles={(docs) =>
                setLandRegistryExtract((prevState) => [...prevState, ...docs])
              }
              label={t("propertyForm.landRegistryExtract")}
              required={false}
              accept={"application/pdf"}
              multiple={false}
            />
          </div>
          <div className={"flex flex-column"}>
            {landRegistryExtract.map((d, index) => (
              <DocumentPreview
                key={index}
                index={index}
                file={d}
                setFiles={setLandRegistryExtract}
              />
            ))}
          </div>
          <div className={"row"}>
            <FileUpload
              setFiles={(docs) =>
                setEnergyCertification((prevState) => [...prevState, ...docs])
              }
              label={t("propertyForm.energyCertification")}
              required={false}
              accept={"application/pdf"}
              multiple={false}
            />
          </div>
          <div className={"flex flex-column"}>
            {energyCertification.map((d, index) => (
              <DocumentPreview
                key={index}
                index={index}
                file={d}
                setFiles={setEnergyCertification}
              />
            ))}
          </div>
        </div>
        <div className={"flex mb-4"}>
          <Button className="mt-4" type={"submit"}>
            {t("propertyForm.saveProperty")}
          </Button>
          <Button
            className="mt-4 ml-4"
            color="outlined"
            onClick={(e) => {
              e.preventDefault();
              if (property && isPropertyItem(property)) {
                navigate(`/property/${property.id}`);
              } else {
                navigate(routes.addProperties);
              }
            }}
          >
            {t("propertyForm.cancel")}
          </Button>
        </div>
      </form>
    </div>
  );
}
