import React, { useState } from "react";
import lock from "../../assets/lock.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import "./AccountConfirmation.css";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import AuthApi from "../../api/AuthApi";
import { Input } from "../../components/input/Input";
import {
  getCorrectPasswordString,
  isStrongPassword,
} from "../../util/isStrongPassword";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { LocalStorage } from "../../enums/LocalStorage";
import { Button } from "../../components/button/Button";
import { User } from "../../interfaces/UserTypes";
import { PasswordRequirements } from "../../components/password-requirements/PasswordRequirements";

export function AccountConfirmation() {
  const { t } = useTranslation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { token: confirmToken, email } = Object.fromEntries(
    urlSearchParams.entries()
  );

  const [password, setPassword] = useState("");
  const [isRevealedPassword, setIsRevealedPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isRevealedRepeatPassword, setIsRevealedRepeatPassword] =
    useState(false);

  const navigate = useNavigate();

  async function onSubmitConfirmAccount(): Promise<void> {
    try {
      if (password !== repeatPassword) {
        const toastId = t("passwordReset.passwordDoNotMatch") as string;
        toast(toastId, {
          type: "error",
          toastId: toastId,
        });

        return;
      }

      const passwordStrength = isStrongPassword(password);

      if (!passwordStrength.ok) {
        const toastId = t(getCorrectPasswordString(passwordStrength)) as string;
        toast(toastId, {
          type: "error",
          toastId: toastId,
        });

        return;
      }

      await toast.promise(
        AuthApi.confirm(email, password, confirmToken).then(
          ({ token, type }) => {
            localStorage.setItem(LocalStorage.Token, token);
            localStorage.setItem(LocalStorage.Type, type);
            const user: User = jwt_decode(token);
            localStorage.setItem(LocalStorage.User, JSON.stringify(user));

            navigate(routes.agencyHome);
          }
        ),
        {
          pending: t("accountConfirmation.pending"),
          error: t("general.somethingWrong"),
        }
      );
    } catch (error) {
      // toast will handle the error
    }
  }

  return (
    <div className="account-confirmation-upper-container flex">
      <div className="account-confirmation-container flex">
        <div className="account-confirmation-form">
          <Logo />
          {!email || !confirmToken ? (
            <>
              <div className={"description error"}>
                {t("completeAccount.invalidLink")}
              </div>
              <Link
                className="password-reset-link mt-4 flex justify-center"
                to={routes.login}
              >
                {t("accountConfirmation.returnLogin")}
              </Link>
            </>
          ) : (
            <>
              <div className={"description"}>
                {t("accountConfirmation.description")}
              </div>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();

                  await onSubmitConfirmAccount();
                }}
              >
                <Input
                  value={password}
                  onChange={setPassword}
                  placeholder={t("accountConfirmation.password")}
                  required={true}
                  type={isRevealedPassword ? "text" : "password"}
                  image={lock}
                  showPasswordIcon={true}
                  isRevealedPassword={isRevealedPassword}
                  setIsRevealedPassword={setIsRevealedPassword}
                ></Input>
                <Input
                  value={repeatPassword}
                  onChange={setRepeatPassword}
                  placeholder={t("accountConfirmation.repeatPassword")}
                  required={true}
                  type={isRevealedRepeatPassword ? "text" : "password"}
                  image={lock}
                  showPasswordIcon={true}
                  isRevealedPassword={isRevealedRepeatPassword}
                  setIsRevealedPassword={setIsRevealedRepeatPassword}
                />
                <PasswordRequirements password={password} className={"mt-4"} />
                <Button className="mt-4" type="submit" fullWidth>
                  {t("accountConfirmation.confirm")}
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
