import "./PaymentConfirmation.css";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentApi, { PaymentSessionStatus } from "../../api/PaymentApi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import { routes } from "../../constants/routes";
import AuthApi from "../../api/AuthApi";

export function PaymentConfirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const apiTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    apiTimeout.current = setTimeout(fetchStatus, 1000);
  }, []);

  function fetchStatus() {
    const sessionId = searchParams.get("session_id");

    if (sessionId) {
      PaymentApi.checkStatus(sessionId)
        .then((result) => {
          if (result.status === PaymentSessionStatus.OPEN) {
            apiTimeout.current = setTimeout(fetchStatus, 1000);
          } else {
            clearTimeout(apiTimeout.current);
            if (result.status === PaymentSessionStatus.EXPIRED) {
              setError(true);
            } else {
              AuthApi.refreshToken().then();
              setTimeout(() => navigate(routes.agencyHome), 4000);
            }

            setLoading(false);
          }
        })
        .catch(() => {
          clearTimeout(apiTimeout.current);
          setLoading(false);
          setError(true);
        });
    } else {
      clearTimeout(apiTimeout.current);
      setLoading(false);
      setError(true);
    }
  }

  return (
    <div className={"payment-confirmation-upper-container"}>
      <div className={"payment-confirmation-container"}>
        <div className={"payment-confirmation"}>
          <Logo />
          {loading ? (
            <div className={"description"}>
              <AiOutlineLoading3Quarters className={"loading-spinner"} />
              {t("paymentConfirmation.loading")}
            </div>
          ) : error ? (
            <div className={"description error"}>
              {t("paymentConfirmation.error")}
            </div>
          ) : (
            <div className={"description"}>
              <IoMdCheckmark />
              {t("paymentConfirmation.success")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
