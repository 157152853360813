import { DateTime } from "luxon";

export function dateToRelativeString(date: DateTime): string {
  const now = DateTime.now();
  const diff = now.diff(date, [
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds",
  ]);

  let timeAgo = "";

  if (diff.years >= 1) {
    timeAgo = `${Math.floor(diff.years)} year${
      Math.floor(diff.years) > 1 ? "s" : ""
    }`;
  } else if (diff.months >= 1) {
    timeAgo = `${Math.floor(diff.months)} month${
      Math.floor(diff.months) > 1 ? "s" : ""
    }`;
  } else if (diff.weeks >= 1) {
    timeAgo = `${Math.floor(diff.weeks)} week${
      Math.floor(diff.weeks) > 1 ? "s" : ""
    }`;
  } else if (diff.days >= 1) {
    timeAgo = `${Math.floor(diff.days)} day${
      Math.floor(diff.days) > 1 ? "s" : ""
    }`;
  } else if (diff.hours >= 1) {
    timeAgo = `${Math.floor(diff.hours)} hour${
      Math.floor(diff.hours) > 1 ? "s" : ""
    }`;
  } else if (diff.minutes >= 1) {
    timeAgo = `${Math.floor(diff.minutes)} minute${
      Math.floor(diff.minutes) > 1 ? "s" : ""
    }`;
  } else {
    timeAgo = `${Math.floor(diff.seconds)} second${
      Math.floor(diff.seconds) > 1 ? "s" : ""
    }`;
  }
  return timeAgo;
}
