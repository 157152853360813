import ApiInstance from "./ApiInstance";
import { LocalStorage } from "../enums/LocalStorage";
import jwtDecode from "jwt-decode";
import { User } from "../interfaces/UserTypes";

class AuthApi {
  public async login(
    email: string,
    password: string
  ): Promise<{ token: string; type: string }> {
    const response = await ApiInstance.getApi().post("/auth/login", {
      email,
      password,
    });
    return {
      token: response.data.data.token,
      type: response.data.data.type,
    };
  }

  public logout(): void {
    localStorage.removeItem(LocalStorage.Token);
    localStorage.removeItem(LocalStorage.Type);
    localStorage.removeItem(LocalStorage.User);
    localStorage.removeItem(LocalStorage.AdminToken);
  }

  public async confirm(
    email: string,
    password: string,
    token: string
  ): Promise<{ token: string; type: string }> {
    const response = await ApiInstance.getApi().post("/auth/confirm", {
      email,
      password,
      token,
    });
    return {
      token: response.data.data.token,
      type: response.data.data.type,
    };
  }

  public async requestPasswordReset(email: string): Promise<void> {
    await ApiInstance.getApi().post("/auth/request-password-reset", { email });
  }

  public async completeAccount(
    firstName: string,
    lastName: string,
    nationality: string,
    phoneNumber: string,
    email: string,
    password: string,
    confirmToken: string
  ): Promise<{ token: string; type: string }> {
    const data = {
      firstName,
      lastName,
      nationality,
      phoneNumber,
      email,
      password,
      token: confirmToken,
    };

    const response = await ApiInstance.getApi().post(
      "auth/complete-account",
      data
    );

    return {
      token: response.data.data.token,
      type: response.data.data.type,
    };
  }

  public async inviteUser(email: string, agencyId: string): Promise<void> {
    const data = {
      email,
      agencyId,
    };

    return await ApiInstance.getApi().post("auth/invite", data);
  }

  public async getTokenForAgency(
    agencyId: string
  ): Promise<{ token: string; type: string }> {
    const response = await ApiInstance.getApi().post("/auth/token", {
      agencyId: agencyId,
    });
    return {
      token: response.data.data.token,
      type: response.data.data.type,
    };
  }

  public async refreshToken(): Promise<void> {
    const response = await ApiInstance.getApi().post("/auth/refresh-token");

    localStorage.setItem(LocalStorage.Token, response.data.data.token);
    localStorage.setItem(LocalStorage.Type, response.data.data.type);

    const user: User = jwtDecode(response.data.data.token);
    localStorage.setItem(LocalStorage.User, JSON.stringify(user));
  }
}

export default new AuthApi();
