import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import jwtDecode from "jwt-decode";
import AuthApi from "../../api/AuthApi";
import { LocalStorage } from "../../enums/LocalStorage";
import { getUser } from "../../util/getUser";
import { Select } from "../select/Select";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Agency } from "../../api/AgencyApi";
import "./HeaderLinksMain.css";
import { User, UserType } from "../../interfaces/UserTypes";

export function HeaderLinksMain({
  setIsShowingAddUserModal,
  selectedAgency,
  setSelectedAgency,
  agencies,
  user,
}: {
  setIsShowingAddUserModal: (value: boolean) => void;
  selectedAgency: string;
  setSelectedAgency: (value: string) => void;
  agencies: Agency[];
  user: User | null;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showingUserDropdown, setShowingUserDropdown] = useState(false);
  const [showingAddDropdown, setShowingAddDropdown] = useState(false);

  function logout() {
    AuthApi.logout();
    navigate(routes.login);
  }

  return (
    <div className="header-links ml-auto">
      {getUser()?.type === UserType.ADMIN ? (
        <Select
          label={null}
          value={selectedAgency}
          onChange={async (value) => {
            if (value === "") {
              const token = localStorage.getItem(LocalStorage.AdminToken) ?? "";
              localStorage.setItem(LocalStorage.Token, token);
              const user: User = jwtDecode(token);
              localStorage.setItem(LocalStorage.User, JSON.stringify(user));
            } else {
              const token = await AuthApi.getTokenForAgency(value);

              const adminToken =
                localStorage.getItem(LocalStorage.AdminToken) ?? "";
              if (adminToken === "") {
                localStorage.setItem(
                  LocalStorage.AdminToken,
                  localStorage.getItem(LocalStorage.Token) ?? ""
                );
              }

              localStorage.setItem(LocalStorage.Token, token.token);
              const user: User = jwtDecode(token.token);
              localStorage.setItem(LocalStorage.User, JSON.stringify(user));
            }

            setSelectedAgency(value);
            window.location.reload();
          }}
          options={[{ label: "Admin", value: "" }].concat(
            agencies.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          )}
          clean={true}
        />
      ) : null}
      {user &&
      ((user.type === UserType.ADMIN && user.agencyId !== undefined) ||
        user.type !== UserType.ADMIN) ? (
        <NavLink className="link" to={routes.agencyHome}>
          {t("header.home")}
        </NavLink>
      ) : null}
      <NavLink className="link" to={routes.properties}>
        {t("header.properties")}
      </NavLink>
      {user &&
      (user.type === UserType.AGENCY_OWNER ||
        (user.type === UserType.ADMIN && user.agencyId !== undefined)) ? (
        <NavLink className="link" to={routes.dashboard}>
          {t("header.dashboard")}
        </NavLink>
      ) : null}
      {user && user.type === UserType.ADMIN ? (
        <NavLink className="link" to={routes.admin}>
          {t("header.admin")}
        </NavLink>
      ) : null}
      <div
        className={"dropdown-container"}
        onMouseEnter={() => setShowingUserDropdown(true)}
        onMouseLeave={() => setShowingUserDropdown(false)}
      >
        <div className={`icon-wrapper ${showingUserDropdown ? "active" : ""}`}>
          <FaRegUser className={"icon"} />
        </div>
        {showingUserDropdown ? (
          <div className={"dropdown"}>
            <div className={"title-item"}>{t("header.accountMenu")}</div>
            {user &&
            user.agencyId &&
            (user.type == UserType.ADMIN ||
              user.type === UserType.AGENCY_OWNER) ? (
              <div
                className={"item"}
                onClick={() => navigate(routes.subscriptionSettings)}
              >
                {t("header.settings")}
              </div>
            ) : null}
            <div
              className={"item"}
              onClick={() => navigate(routes.profileSettings)}
            >
              {t("header.myProfile")}
            </div>
            <div className={"item"} onClick={() => logout()}>
              {t("header.logout")}
            </div>
          </div>
        ) : null}
      </div>
      {user &&
      ((user.type === UserType.ADMIN && user.agencyId !== undefined) ||
        user.type !== UserType.ADMIN) ? (
        <div
          className={"dropdown-container"}
          onMouseEnter={() => setShowingAddDropdown(true)}
          onMouseLeave={() => setShowingAddDropdown(false)}
        >
          <div
            className={"dropdown-container"}
            onMouseEnter={() => setShowingAddDropdown(true)}
            onMouseLeave={() => setShowingAddDropdown(false)}
          >
            <div
              className={`icon-wrapper ${showingAddDropdown ? "active" : ""}`}
            >
              <FaPlus className={"icon"} />
            </div>
            {showingAddDropdown && (
              <div className={"dropdown"}>
                <div
                  className={"item"}
                  onClick={() => navigate(routes.addProperties)}
                >
                  {t("header.addProperty")}
                </div>
                {user &&
                (user.type === UserType.ADMIN ||
                  user.type === UserType.AGENCY_OWNER) ? (
                  <div
                    className={"item"}
                    onClick={() => setIsShowingAddUserModal(true)}
                  >
                    {t("header.addUser")}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
