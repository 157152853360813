import { PropertyStatus } from "../../api/PropertyTypes";
import "./StatusBadge.css";
import { formatPropertyStatus } from "../../util/formatPropertyStatus";

export function StatusBadge({
  status,
  fullStatus = false,
}: {
  status: PropertyStatus;
  fullStatus?: boolean;
}) {
  return (
    <div className={"status-badge"}>
      {formatPropertyStatus(status, fullStatus)}
    </div>
  );
}
