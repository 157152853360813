import ApiInstance from "./ApiInstance";
import { PaginationResult } from "./PaginationContent";
import { User } from "../interfaces/UserTypes";

class UserApi {
  public async createAgencyOwner(
    firstName: string,
    lastName: string,
    nationality: string,
    phoneNumber: string,
    email: string,
    agencyName: string,
    agencyReference: string,
    agencyEmail: string,
    agencyStreet: string,
    agencyNumber: string,
    agencyCity: string,
    agencyCountryCode: string,
    agencyVat: string,
    defaultRegion: string
  ): Promise<User> {
    const data = {
      firstName,
      lastName,
      nationality,
      phoneNumber,
      email,
      agencyName,
      agencyEmail,
      agencyReference,
      agencyStreet,
      agencyNumber,
      agencyCity,
      agencyCountryCode,
      agencyVat,
      defaultRegion,
    };
    const response = await ApiInstance.getApi().post(
      `auth/register-agency-owner`,
      data
    );

    return response.data;
  }

  public async list(
    page: number,
    pageSize: number
  ): Promise<PaginationResult<User>> {
    const response = await ApiInstance.getApi().get(
      `/users?page=${page - 1}&size=${pageSize}`
    );
    return response.data;
  }

  public async deleteUser(id: string): Promise<void> {
    await ApiInstance.getApi().delete(`/users/${id}`);
  }
}

export default new UserApi();
