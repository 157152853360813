import React from "react";
import { LayoutType } from "../property-list/PropertyList";
import { ReactComponent as Grid } from "../../assets/grid.svg";
import { ReactComponent as GridLight } from "../../assets/grid-light.svg";
import { ReactComponent as List } from "../../assets/list.svg";
import { ReactComponent as ListLight } from "../../assets/list-light.svg";
import "./LayoutToggle.css";

export function LayoutToggle({
  className,
  layoutType,
  setLayoutType,
}: {
  className?: string;
  layoutType: LayoutType;
  setLayoutType: (layoutType: LayoutType) => void;
}) {
  return (
    <div className={`layout-toggle ${className ?? ""}`}>
      <div
        className={`toggle-item ${
          layoutType === LayoutType.GRID ? "active" : ""
        }`}
        onClick={() => setLayoutType(LayoutType.GRID)}
      >
        {layoutType === LayoutType.GRID ? <GridLight /> : <Grid />}
      </div>
      <div
        className={`toggle-item ${
          layoutType === LayoutType.LIST ? "active" : ""
        }`}
        onClick={() => setLayoutType(LayoutType.LIST)}
      >
        {layoutType === LayoutType.LIST ? <ListLight /> : <List />}
      </div>
    </div>
  );
}
