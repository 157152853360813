import React, { useEffect, useState } from "react";
import "./AddProperty.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Button } from "../../components/button/Button";
import ExternalPropertyApi from "../../api/ExternalPropertyApi";
import { toast } from "react-toastify";
import { getUser } from "../../util/getUser";
import { ExternalPropertyItem } from "../../api/ExternalPropertyTypes";
import { Config } from "../../Config";
import { PropertyGrid } from "../../components/property-grid/PropertyGrid";
import { LayoutType } from "../../components/property-list/PropertyList";
import Dialog from "../../components/dialog/Dialog";
import axios from "axios";
import { ExternalPropertyErrorEnums } from "../../enums/ExternalPropertyErrorEnums";

export function AddProperty() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [isSyncing, setIsSyncing] = useState(false);
  const [externalProperties, setExternalProperties] = useState<
    ExternalPropertyItem[]
  >([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const currentPageRef = React.useRef(1);
  const setCurrentPage = (page: number) => {
    currentPageRef.current = page;
  };
  const [showErrorsDialog, setShowErrorsDialog] = useState(false);
  const [syncErrors, setSyncErrors] = useState<
    Array<{ propertyReference: string | undefined; error: string }>
  >([]);

  useEffect(() => {
    const user = getUser();
    if (!user) {
      return;
    }
    const agencyId = user.agencyId;

    ExternalPropertyApi.list(
      agencyId,
      currentPageRef.current,
      Config.getDefaultPageSize()
    )
      .then((p) => {
        setExternalProperties(p.data);
        setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSyncProperties = async () => {
    const user = getUser();
    if (!user) {
      return;
    }
    const agencyId = user.agencyId;

    setIsSyncing(true);
    const loadingToastId = toast.loading(t("property.syncing"));

    try {
      const result = await ExternalPropertyApi.syncExternalProperties(agencyId);
      toast.dismiss(loadingToastId);

      if (result && result.errors.length > 0) {
        toast.warning(
          <div
            onClick={() => {
              setShowErrorsDialog(true);
              setSyncErrors(result.errors);
              toast.dismiss();
            }}
            style={{ cursor: "pointer" }}
          >
            {t("property.syncPartialSuccess")}
            <br />
            {t("property.syncFailedCount", {
              totalNotProcessed: result.errors.length,
            })}
            <br />
            {t("property.clickToSeeErrors")}
          </div>
        );
      } else {
        toast.success(t("property.syncSuccess"));
        setSyncErrors([]);
      }

      await retrievePage(currentPageRef.current);
    } catch (error) {
      toast.dismiss(loadingToastId);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        const errorMessage = error.response.data.message;

        if (
          errorMessage === ExternalPropertyErrorEnums.MISSING_INMOBALIA_API_KEY
        ) {
          toast.error(t("property.missingInmobaliaXmlFeedUrl"));
          navigate(routes.inmobaliaSettings);
        }
        if (
          errorMessage === ExternalPropertyErrorEnums.INMOBALIA_API_KEY_INVALID
        ) {
          toast.error(t("property.invalidInmobaliaXmlFeedUrl"));
          navigate(routes.inmobaliaSettings);
        }
      } else {
        toast.error(t("general.somethingWrong"));
      }
    } finally {
      setIsSyncing(false);
    }
  };

  async function retrievePage(page: number) {
    const user = getUser();
    if (!user) {
      return;
    }
    const agencyId = user.agencyId;
    if (Number.isNaN(page)) {
      return;
    }

    ExternalPropertyApi.list(agencyId, page, Config.getDefaultPageSize()).then(
      (p) => {
        setExternalProperties(p.data);
        setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
      }
    );
  }

  return (
    <div>
      <div className={"page without-side-navigation page-content-no-margin"}>
        <div className={"title-wrapper"}>
          <div className={"title"}>{t("property.addNewProperty")}</div>
          <Link
            to={routes.addPropertyManually}
            className="manual-link custom-button secondary"
          >
            {t("property.addManually")}
          </Link>
        </div>
      </div>
      <div className={"page without-side-navigation page-content-no-margin"}>
        <div className={"title-wrapper"}>
          <div className={"title"}>{t("property.addNewInmobaliaProperty")}</div>
          <Button
            className={`${isSyncing ? "is-syncing" : ""}`}
            onClick={handleSyncProperties}
            disabled={isSyncing}
            fitHeight={true}
          >
            {isSyncing ? t("property.syncing") : t("property.sync")}
          </Button>
        </div>
        <div className="properties-wrapper">
          <Dialog
            isOpen={showErrorsDialog}
            onRequestClose={() => setShowErrorsDialog(false)}
            title="Sync Errors"
            size={"wide"}
          >
            <ul>
              {syncErrors.map((error, index) => (
                <li key={index} className="error-list-item">
                  <span className="property-ref">
                    {`REF: ${error.propertyReference}`}{" "}
                  </span>
                  - {error.error}
                </li>
              ))}
            </ul>
          </Dialog>

          <PropertyGrid
            properties={externalProperties}
            retrievePage={(page: number) => retrievePage(page)}
            totalPages={totalPages}
            enableLayoutSwitch={false}
            enableRefSearch={false}
            layoutType={LayoutType.GRID}
            isExternalPropertyGrid={true}
            setCurrentPageParent={setCurrentPage}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
