import React, { useEffect, useState } from "react";
import "./Admin.css";
import {
  NavigationItem,
  SideNavigation,
} from "../../components/side-navigation/SideNavigation";
import { ReactComponent as House } from "../../assets/house.svg";
import { ReactComponent as HouseGrayed } from "../../assets/house-grayed.svg";
import { ReactComponent as Agency } from "../../assets/agency.svg";
import { ReactComponent as AgencyGrayed } from "../../assets/agency-grayed.svg";
import { ReactComponent as UserPrimary } from "../../assets/user-primary.svg";
import { ReactComponent as UserGrayed } from "../../assets/user-grayed.svg";
import { ReactComponent as Statistics } from "../../assets/statistics.svg";
import { ReactComponent as StatisticsGrayed } from "../../assets/statistics-grayed.svg";
import { routes } from "../../constants/routes";
import { Navigate } from "react-router-dom";
import { UserList } from "../../components/user-list/UserList";
import { PropertyItem } from "../../api/PropertyTypes";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Dialog from "../../components/dialog/Dialog";
import UserApi from "../../api/UserApi";
import { PropertyList } from "../../components/property-list/PropertyList";
import PropertyApi from "../../api/PropertyApi";
import { AgencyList } from "../../components/agency-list/AgencyList";
import ReportApi, { ReportItem } from "../../api/ReportApi";
import { ReportList } from "../../components/report-list/ReportList";
import { AdminStatistics } from "../../components/admin-statistics/AdminStatistics";
import AdminApi, { AgencyWithStats } from "../../api/AdminApi";
import { Config } from "../../Config";
import { Button } from "../../components/button/Button";
import { SortingConfig } from "../../interfaces/SortingConfig";
import { PropertyGrid } from "../../components/property-grid/PropertyGrid";
import { User } from "../../interfaces/UserTypes";

const ADMIN_NAV_ITEMS: NavigationItem[] = [
  {
    Icon: Statistics,
    IconLight: StatisticsGrayed,
    titleKey: "sideNavigation.statistics",
    route: routes.adminStatistics,
  },
  {
    Icon: UserPrimary,
    IconLight: UserGrayed,
    titleKey: "sideNavigation.users",
    route: routes.adminUsers,
  },
  {
    Icon: House,
    IconLight: HouseGrayed,
    titleKey: "sideNavigation.properties",
    route: routes.adminProperties,
  },
  {
    Icon: House,
    IconLight: HouseGrayed,
    titleKey: "sideNavigation.reportedProperties",
    route: routes.adminReportedProperties,
  },
  {
    Icon: Agency,
    IconLight: AgencyGrayed,
    titleKey: "sideNavigation.agencies",
    route: routes.adminAgencies,
  },
];

export enum AdminViewType {
  USERS = "USERS",
  PROPERTIES = "PROPERTIES",
  REPORTED_PROPERTIES = "REPORTED_PROPERTIES",
  AGENCIES = "AGENCIES",
  STATISTICS = "STATISTICS",
}

export function AdminRedirect() {
  return <Navigate to={routes.adminStatistics} />;
}

export function Admin({ viewType }: { viewType: AdminViewType }) {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);
  const [totalUserPages, setTotalUserPages] = useState<number>(0);
  const [properties, setProperties] = useState<PropertyItem[]>([]);
  const [totalPropertyPages, setTotalPropertyPages] = useState<number>(0);
  const [reports, setReports] = useState<ReportItem[]>([]);
  const [totalReportPages, setTotalReportPages] = useState<number>(0);
  const [isShowingDeleteUserModal, setIsShowingDeleteUserModal] =
    useState<boolean>(false);
  const [isShowingDeletePropertyModal, setIsShowingDeletePropertyModal] =
    useState<boolean>(false);
  const [deletingUserId, setDeletingUserId] = useState<string>("");
  const [deletingPropertyId, setDeletingPropertyId] = useState<string>("");
  const [agenciesWithStats, setAgenciesWithStats] = useState<AgencyWithStats[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [totalAgenciesWithStatsPages, setTotalAgenciesWithStatsPages] =
    useState<number>(0);

  const [propertySortingConfig, setPropertySortingConfig] =
    useState<SortingConfig>();

  const [reportSortingConfig, setReportSortingConfig] =
    useState<SortingConfig>();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    UserApi.list(1, Config.getDefaultPageSize()).then((u) => {
      setUsers(u.data);
      setTotalUserPages(Math.ceil(u.total / Config.getDefaultPageSize()));
    });
    PropertyApi.list(1, Config.getDefaultPageSize())
      .then((d) => {
        setProperties(d.data);
        setTotalPropertyPages(Math.ceil(d.total / Config.getDefaultPageSize()));
      })
      .finally(() => {
        setLoading(false);
      });
    ReportApi.list(1, Config.getDefaultPageSize()).then((r) => {
      setReports(r.data);
      setTotalReportPages(Math.ceil(r.total / Config.getDefaultPageSize()));
    });
    AdminApi.listAgenciesWithStats(1, Config.getDefaultPageSize()).then((d) => {
      setAgenciesWithStats(d.data);
      setTotalAgenciesWithStatsPages(
        Math.ceil(d.total / Config.getDefaultPageSize())
      );
    });
  }, []);

  function deleteUser() {
    UserApi.deleteUser(deletingUserId)
      .then(() => {
        UserApi.list(1, Config.getDefaultPageSize()).then((u) => {
          setUsers(u.data);
          setTotalUserPages(Math.ceil(u.total / Config.getDefaultPageSize()));
        });
        toast(t("admin.userDeleteSuccess"), {
          type: "success",
        });
        setDeletingUserId("");
      })
      .catch(() => {
        toast(t("admin.userDeleteFailed"), {
          type: "error",
        });
      });
  }

  function deleteProperty() {
    PropertyApi.deleteProperty(deletingPropertyId)
      .then(() => {
        PropertyApi.list(1, Config.getDefaultPageSize()).then((d) => {
          setProperties(d.data);
          setTotalPropertyPages(d.count / d.total);
        });
        toast(t("admin.propertyDeleteSuccess"), {
          type: "success",
        });
        setDeletingUserId("");
      })
      .catch(() => {
        toast(t("admin.propertyDeleteFailed"), {
          type: "error",
        });
      });
  }

  function retrievePropertyPage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    PropertyApi.list(
      page,
      Config.getDefaultPageSize(),
      undefined,
      propertySortingConfig
    ).then((d) => {
      setProperties(d.data);
      setTotalPropertyPages(Math.ceil(d.total / Config.getDefaultPageSize()));
    });
  }

  function retrieveAgencyPage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    AdminApi.listAgenciesWithStats(page, Config.getDefaultPageSize()).then(
      (d) => {
        setAgenciesWithStats(d.data);
        setTotalAgenciesWithStatsPages(
          Math.ceil(d.total / Config.getDefaultPageSize())
        );
      }
    );
  }

  function editProperty(id: string) {
    // TODO: Do something
  }

  function retrieveUserPage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    UserApi.list(page, Config.getDefaultPageSize()).then((u) => {
      setUsers(u.data);
      setTotalUserPages(Math.ceil(u.total / Config.getDefaultPageSize()));
    });
  }

  function retrieveReportPage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    ReportApi.list(page, Config.getDefaultPageSize(), reportSortingConfig).then(
      (r) => {
        setReports(r.data);
        setTotalReportPages(Math.ceil(r.total / Config.getDefaultPageSize()));
      }
    );
  }

  return (
    <>
      <div className={"page admin"}>
        <div className={"side-nav"}>
          <SideNavigation items={ADMIN_NAV_ITEMS} />
        </div>
        <div className="page-content-no-margin">
          {viewType === AdminViewType.STATISTICS && <AdminStatistics />}
          {viewType === AdminViewType.USERS ? (
            <UserList
              users={users}
              totalPages={totalUserPages}
              retrievePage={retrieveUserPage}
              deleteUser={(id: string) => {
                setDeletingUserId(id);
                setIsShowingDeleteUserModal(true);
              }}
            />
          ) : null}
          {viewType === AdminViewType.PROPERTIES ? (
            windowSize[0] < 1400 ? (
              <PropertyGrid
                properties={properties}
                enableLayoutSwitch={false}
                totalPages={totalPropertyPages}
                retrievePage={(page: number) => retrievePropertyPage(page)}
                loading={loading}
              />
            ) : (
              <PropertyList
                properties={properties}
                sortingConfig={propertySortingConfig}
                setSortingConfig={setPropertySortingConfig}
                enableLayoutSwitch={false}
                totalPages={totalPropertyPages}
                enableEditButtons={true}
                deleteProperty={(id: string) => {
                  setDeletingPropertyId(id);
                  setIsShowingDeletePropertyModal(true);
                }}
                editProperty={(id: string) => editProperty(id)}
                retrievePage={(page: number) => retrievePropertyPage(page)}
                loading={loading}
              />
            )
          ) : null}
          {viewType === AdminViewType.REPORTED_PROPERTIES ? (
            <ReportList
              sortingConfig={reportSortingConfig}
              setSortingConfig={setReportSortingConfig}
              reports={reports}
              retrievePage={retrieveReportPage}
              totalPages={totalReportPages}
            />
          ) : null}
          {viewType === AdminViewType.AGENCIES && (
            <AgencyList
              agenciesWithStats={agenciesWithStats}
              totalPages={totalAgenciesWithStatsPages}
              retrievePage={retrieveAgencyPage}
            />
          )}
        </div>
      </div>
      <Dialog
        isOpen={isShowingDeleteUserModal}
        onRequestClose={() => setIsShowingDeleteUserModal(false)}
        title={"Delete user"}
        size={"msmall"}
      >
        <div className={"report-dialog-content"}>
          <div className={"description"}>{t("admin.deleteUser")}</div>
          <div className={"button-wrapper"}>
            <Button
              color="danger"
              onClick={() => {
                deleteUser();
                setIsShowingDeleteUserModal(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={isShowingDeletePropertyModal}
        onRequestClose={() => setIsShowingDeletePropertyModal(false)}
        title={"Delete property"}
        size={"msmall"}
      >
        <div className={"report-dialog-content"}>
          <div className={"description"}>{t("admin.deleteProperty")}</div>
          <div className={"button-wrapper"}>
            <Button
              color="danger"
              onClick={() => {
                deleteProperty();
                setIsShowingDeletePropertyModal(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
