import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { routes } from "./constants/routes";
import { Login } from "./pages/login/Login";
import "./i18n";
import { Header } from "./components/header/Header";
import { Properties } from "./pages/properties/Properties";
import { Property } from "./pages/listing/Property";
import { Home } from "./pages/home/Home";
import { PasswordReset } from "./pages/password-reset/PasswordReset";
import { AccountConfirmation } from "./pages/account-confirmation/AccountConfirmation";
import { AddProperty } from "./pages/add-property/AddProperty";
import { PaymentAndTierSelection } from "./pages/payment-and-tier-selection/PaymentAndTierSelection";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RedirectIfLoggedOut } from "./components/RedirectIfLoggedOut";
import { AddPropertyManually } from "./pages/add-property-manually/AddPropertyManually";
import { Registration } from "./pages/registration/Registration";
import { CompleteAccount } from "./pages/complete-account/CompleteAccount";
import { Payment } from "./pages/payment/Payment";
import { Admin, AdminRedirect, AdminViewType } from "./pages/admin/Admin";
import { PersonalHome } from "./pages/personal-home/PersonalHome";
import { NotPayed } from "./pages/not-payed/NotPayed";
import { EditProperty } from "./pages/edit-property/EditProperty";
import {
  Dashboard,
  DashboardRedirect,
  DashboardViewType,
} from "./pages/dashboard/Dashboard";
import { SubscriptionSettings } from "./pages/subscriptionSettings/SubscriptionSettings";
import { InmobaliaSettings } from "./pages/inmobaliaSettings/InmobaliaSettings";
import { RedirectIfNoAgencySelected } from "./components/RedirectIfNoAgencySelected";
import { ProfileSettings } from "./pages/profileSettings/ProfileSettings";
import { RedirectIfInvalidTier } from "./components/RedirectIfInvalidTier";
import { ClientRegistrationConfirm } from "./pages/client-registration-confirm/ClientRegistrationConfirm";
import { PaymentConfirmation } from "./pages/payment-confirmation/PaymentConfirmation";
import { RedirectIfAlreadyPayed } from "./components/RedirectIfAlreadyPayed";
import { FavoritesHome } from "./pages/favorites-home/FavoritesHome";
import { AddPropertyExternal } from "./pages/add-property-external/AddPropertyExternal";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <RedirectIfLoggedOut />
        <RedirectIfNoAgencySelected />
        <RedirectIfInvalidTier />
        <RedirectIfAlreadyPayed />
        <Routes>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.resetPassword} element={<PasswordReset />} />
          <Route
            path={routes.confirmAccount}
            element={<AccountConfirmation />}
          />
          <Route path={routes.registration} element={<Registration />} />
          <Route path={routes.completeAccount} element={<CompleteAccount />} />
          <Route
            element={
              <div className={"page-wrapper"}>
                <Header />
                <Outlet />
              </div>
            }
          >
            <Route path={routes.agencyHome} element={<Home />} />
            <Route
              path={routes.subscriptionSettings}
              element={<SubscriptionSettings />}
            />
            <Route
              path={routes.inmobaliaSettings}
              element={<InmobaliaSettings />}
            />
            <Route
              path={routes.profileSettings}
              element={<ProfileSettings />}
            />
            <Route path={routes.admin} element={<AdminRedirect />} />
            <Route
              path={routes.adminStatistics}
              element={<Admin viewType={AdminViewType.STATISTICS} />}
            />
            <Route
              path={routes.adminUsers}
              element={<Admin viewType={AdminViewType.USERS} />}
            />
            <Route
              path={routes.adminProperties}
              element={<Admin viewType={AdminViewType.PROPERTIES} />}
            />
            <Route
              path={routes.adminReportedProperties}
              element={<Admin viewType={AdminViewType.REPORTED_PROPERTIES} />}
            />
            <Route
              path={routes.adminAgencies}
              element={<Admin viewType={AdminViewType.AGENCIES} />}
            />
            <Route path={routes.dashboard} element={<DashboardRedirect />} />
            <Route
              path={routes.dashboardStatistics}
              element={<Dashboard viewType={DashboardViewType.STATISTICS} />}
            />
            <Route
              path={routes.dashboardUsers}
              element={<Dashboard viewType={DashboardViewType.USERS} />}
            />
            <Route
              path={routes.dashboardInvoices}
              element={<Dashboard viewType={DashboardViewType.INVOICES} />}
            />
            <Route
              path={routes.dashboardAgencyInformation}
              element={
                <Dashboard viewType={DashboardViewType.AGENCY_INFORMATION} />
              }
            />
            <Route path={routes.personalHome} element={<PersonalHome />} />
            <Route path={routes.favoritesHome} element={<FavoritesHome />} />
            <Route path={routes.propertyDetail} element={<Property />} />
            <Route path={routes.properties} element={<Properties />} />
            <Route path={routes.addProperties} element={<AddProperty />} />
            <Route
              path={routes.addPropertyManually}
              element={<AddPropertyManually />}
            />
            <Route
              path={routes.addPropertyExternal}
              element={<AddPropertyExternal />}
            />
            <Route path={routes.editProperty} element={<EditProperty />} />
          </Route>
          <Route
            path={routes.paymentAndTierSelection}
            element={<PaymentAndTierSelection />}
          />
          <Route
            path={routes.paymentConfirmation}
            element={<PaymentConfirmation />}
          />
          <Route path={routes.notPayed} element={<NotPayed />} />
          <Route path={routes.payment} element={<Payment />} />
          <Route
            path={routes.clientRegistrationConfirm}
            element={<ClientRegistrationConfirm />}
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        theme={"colored"}
        transition={Flip}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
        position={"bottom-right"}
        autoClose={5000}
      />
    </>
  );
}
