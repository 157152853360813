import "./PropertyList.css";
import { ReactComponent as Heart } from "../../assets/heart.svg";
import { ReactComponent as HeartFilled } from "../../assets/heart-filled.svg";
import { ReactComponent as Ascending } from "../../assets/ascending.svg";
import { ReactComponent as Descending } from "../../assets/descending.svg";
import { ReactComponent as Surface } from "../../assets/surface.svg";
import { ReactComponent as Beds } from "../../assets/bed.svg";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Auction } from "../../assets/auction.svg";
import { ReactComponent as AuctionUndo } from "../../assets/auction-undo.svg";
import i18n from "../../i18n";
import React, { useEffect, useState } from "react";
import { Navigation } from "../navigation/Navigation";
import { NavLink, useNavigate } from "react-router-dom";
import FavouriteApi from "../../api/FavouriteApi";
import { useTranslation } from "react-i18next";
import { LayoutToggle } from "../layout-toggle/LayoutToggle";
import { StatusBadge } from "../status-badge/StatusBadge";
import { PropertyItem, PropertyStatus } from "../../api/PropertyTypes";
import {
  getNextSortingOrder,
  SortingConfig,
} from "../../interfaces/SortingConfig";
import { SortingOrder } from "../../enums/SortingOrder";
import { routes } from "../../constants/routes";
import { roundByNDecimals } from "../../util/roundByNDecimals";
import { RefSearch } from "../ref-search/RefSearch";
import { FaArrowRight } from "react-icons/fa";
import Dialog from "../dialog/Dialog";
import { PropertyClients } from "../property-clients/PropertyClients";
import { Loading } from "../loading/Loading";

export enum LayoutType {
  GRID = "GRID",
  LIST = "LIST",
}

export enum PropertyField {
  AGENCY = "agency",
  REFERENCE = "reference",
  PRICE = "price",
  STATUS = "status",
  CATEGORY = "category",
  BEDROOMS = "bedrooms",
  REGISTERED_CLIENTS = "registered_clients",
}

export function getPropertyFieldLabel(field: PropertyField) {
  switch (field) {
    case PropertyField.AGENCY:
      return "Agency";
    case PropertyField.REFERENCE:
      return "Reference";
    case PropertyField.PRICE:
      return "Price";
    case PropertyField.STATUS:
      return "Status";
    case PropertyField.CATEGORY:
      return "Category";
    case PropertyField.BEDROOMS:
      return "Bedrooms";
    case PropertyField.REGISTERED_CLIENTS:
      return "Registered Clients";
  }
}

export function PropertyList({
  properties,
  retrievePage,
  sortingConfig,
  setSortingConfig,
  totalPages,
  enableLayoutSwitch = true,
  enableRefSearch = false,
  enableEditButtons = false,
  deleteProperty,
  editProperty,
  updateStatus,
  showFullStatus = false,
  layoutType,
  setLayoutType,
  showRegisteredClients = false,
  highlightRegisteredClients,
  hideAddButton = false,
  loading,
}: {
  properties: PropertyItem[];
  loading: boolean;
  retrievePage: (
    page: number,
    sortingConfig?: SortingConfig,
    ref?: string
  ) => void;
  sortingConfig?: SortingConfig;
  setSortingConfig: (sortingConfig: SortingConfig | undefined) => void;
  totalPages: number;
  enableLayoutSwitch?: boolean;
  enableRefSearch?: boolean;
  enableEditButtons?: boolean;
  deleteProperty?: (id: string) => void;
  editProperty?: (id: string) => void;
  updateStatus?: (id: string, status: PropertyStatus) => void;
  showFullStatus?: boolean;
  layoutType?: LayoutType;
  setLayoutType?: React.Dispatch<React.SetStateAction<LayoutType>>;
  showRegisteredClients?: boolean;
  highlightRegisteredClients?: boolean;
  hideAddButton?: boolean;
}) {
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [favourites, setFavourites] = useState<string[]>([]);
  const [searchReference, setSearchReference] = useState<string>("");
  const [showClientsForProperty, setShowClientsForProperty] =
    useState<PropertyItem | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    FavouriteApi.getFavourites().then((f) => setFavourites(f));
  }, []);

  useEffect(() => {
    retrievePage(currentPage, sortingConfig, searchReference);
  }, [currentPage, sortingConfig]);

  async function toggleFavourite(propertyId: string) {
    if (favourites.includes(propertyId)) {
      await FavouriteApi.deleteFavourite(propertyId);
      setFavourites(favourites.filter((f) => f !== propertyId));
    } else {
      await FavouriteApi.addFavourite(propertyId);
      setFavourites([...favourites, propertyId]);
    }
  }

  function headerClick(field: PropertyField) {
    if (sortingConfig && field !== sortingConfig.field) {
      setSortingConfig({
        field,
        order: SortingOrder.ASC,
      });
      return;
    }

    const nextOrder = getNextSortingOrder(sortingConfig?.order);
    if (nextOrder === undefined) {
      setSortingConfig(undefined);
      return;
    }

    setSortingConfig({
      field,
      order: nextOrder,
    });
  }

  if (
    enableEditButtons &&
    (editProperty === undefined || deleteProperty === undefined)
  ) {
    return null;
  }

  return (
    <div className={"property-list"}>
      <div className={"navigation-wrapper"}>
        {enableLayoutSwitch && layoutType && setLayoutType ? (
          <LayoutToggle
            className="height-fit"
            layoutType={layoutType}
            setLayoutType={setLayoutType}
          />
        ) : null}
        <Navigation
          className="height-fit"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
        {enableRefSearch ? (
          <RefSearch
            className="ml-auto"
            reference={searchReference}
            setReference={setSearchReference}
            onClickSearch={(ref) => {
              retrievePage(currentPage, sortingConfig, ref);
            }}
          />
        ) : null}
      </div>
      <div className={"header"}>
        <div className={"image"}></div>
        <div className={"items"}>
          {Object.values(PropertyField).map((field) => {
            if (field === PropertyField.BEDROOMS) {
              return null;
            }

            if (
              !showRegisteredClients &&
              field === PropertyField.REGISTERED_CLIENTS
            ) {
              return null;
            }

            return (
              <div
                className={`item ${
                  highlightRegisteredClients &&
                  field === PropertyField.REGISTERED_CLIENTS
                    ? "highlight"
                    : "no"
                }`}
                style={{ userSelect: "none" }}
                onClick={() => {
                  headerClick(field);
                }}
                key={field}
              >
                {getPropertyFieldLabel(field)}
                {sortingConfig?.field === field &&
                sortingConfig?.order === SortingOrder.ASC ? (
                  <Ascending />
                ) : null}
                {sortingConfig?.field === field &&
                sortingConfig?.order === SortingOrder.DESC ? (
                  <Descending />
                ) : null}
                <div className={"badge"} />
              </div>
            );
          })}
          {enableEditButtons ? (
            <div className={"item"}>{t("general.actions")}</div>
          ) : null}
        </div>
      </div>
      {loading ? <Loading /> : null}
      {properties.length <= 0 && !loading ? (
        <div>
          <div className={"flex justify-center mt-4"}>
            {searchReference !== ""
              ? t("propertyList.noDataSearch")
              : t("propertyList.noData")}
          </div>
          {hideAddButton ? null : (
            <NavLink
              className="custom-button fit-content primary ml-auto mr-auto mt-4"
              to={routes.addProperties}
            >
              {t("header.addProperty")}
            </NavLink>
          )}
        </div>
      ) : (
        <>
          {!loading &&
            properties.map((el, index) => {
              return (
                <div
                  key={index}
                  className={"property-item"}
                  onClick={() => navigate(`/property/${el.id}`)}
                >
                  <div className={"image"}>
                    <div
                      className={"favourite"}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavourite(el.id).then();
                      }}
                    >
                      {favourites.includes(el.id) ? <HeartFilled /> : <Heart />}
                    </div>
                    <img src={el.pictureUrls[0]} alt={"property image"} />
                  </div>
                  <div className={"content"}>
                    <div className={"item"}>{el.agency.name}</div>
                    <div className={"item"}>
                      <div>{el.reference}</div>
                    </div>
                    <div className={"item"}>
                      <div className={"price"}>
                        {formatter.format(el.price)}
                      </div>
                      <div className={"commission"}>
                        {formatter.format(el.price * el.commission)} (
                        {roundByNDecimals(el.commission * 100, 2)}%)
                      </div>
                    </div>
                    <div className={"item"}>
                      <StatusBadge
                        status={el.status}
                        fullStatus={showFullStatus}
                      />
                    </div>
                    <div className={"item"}>
                      <div className={"category"}>{el.subType}</div>
                      <div>{el.region}</div>
                    </div>
                    {showRegisteredClients ? (
                      <div
                        className={`item center ${
                          highlightRegisteredClients ? "highlight" : ""
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowClientsForProperty(el);
                        }}
                      >
                        <div className="flex items-center justify-center">
                          <div>{el.numberOfRegistrations ?? "0"}</div>
                          <FaArrowRight className="ml-3" />
                        </div>
                      </div>
                    ) : (
                      <div className={"item"}>
                        <div className={"tuple"}>
                          <Beds /> {el.bedrooms}
                        </div>
                        <div className={"tuple"}>
                          <Surface /> {el.totalSurface} m<sup>2</sup>
                        </div>
                      </div>
                    )}
                    {enableEditButtons ? (
                      <div className={"item edit"}>
                        <div
                          className={"icon"}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (editProperty !== undefined) {
                              editProperty(el.id);
                            }
                          }}
                        >
                          <Edit title={t("propertyList.edit")} />
                        </div>
                        <div
                          className={"icon"}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (deleteProperty !== undefined) {
                              deleteProperty(el.id);
                              retrievePage(currentPage);
                            }
                          }}
                        >
                          <Delete title={t("propertyList.delete")} />
                        </div>
                        {el.status === PropertyStatus.AVAILABLE && (
                          <div
                            className={"icon"}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (updateStatus !== undefined) {
                                updateStatus(
                                  el.id,
                                  PropertyStatus.SOLD_VISIBLE
                                );
                              }
                            }}
                          >
                            <Auction title={t("propertyList.markAsSold")} />
                          </div>
                        )}
                        {(el.status === PropertyStatus.SOLD_VISIBLE ||
                          el.status === PropertyStatus.SOLD_ARCHIVED) && (
                          <div
                            className={"icon"}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (updateStatus !== undefined) {
                                updateStatus(el.id, PropertyStatus.AVAILABLE);
                              }
                            }}
                          >
                            <AuctionUndo
                              title={t("propertyList.markAsAvailable")}
                            />
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          <div className="navigation-wrapper">
            <div className={"ml-auto mr-auto"}>
              <Navigation
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </div>
          </div>
        </>
      )}
      <Dialog
        isOpen={showClientsForProperty !== null}
        onRequestClose={() => {
          setShowClientsForProperty(null);
        }}
        title="Registered Clients"
        size="xwide"
      >
        {showClientsForProperty ? (
          <PropertyClients property={showClientsForProperty} />
        ) : null}
      </Dialog>
    </div>
  );
}
