import ApiInstance from "./ApiInstance";
import { Agency, Tier } from "./AgencyApi";

export enum PaymentSessionStatus {
  OPEN = "open",
  EXPIRED = "expired",
  COMPLETE = "complete",
}

class PaymentApi {
  public async checkout(tierType: string): Promise<any> {
    const response = await ApiInstance.getApi().get(
      `/payments/checkout/${tierType}`
    );

    return response.data;
  }

  public async cancel(agencyId: string): Promise<Agency> {
    const response = await ApiInstance.getApi().post(
      "/payments/cancel-reactivate",
      {
        agencyId: agencyId,
        cancelAtPeriodEnd: true,
      }
    );
    return response.data;
  }

  public async reactivate(agencyId: string): Promise<Agency> {
    const response = await ApiInstance.getApi().post(
      "/payments/cancel-reactivate",
      {
        agencyId: agencyId,
        cancelAtPeriodEnd: false,
      }
    );
    return response.data;
  }

  public async update(agencyId: string, tier: Tier): Promise<Agency> {
    const response = await ApiInstance.getApi().post("/payments/change", {
      agencyId: agencyId,
      tier: tier.toLowerCase(),
    });
    return response.data;
  }

  public async checkStatus(sessionId: string): Promise<{
    status: PaymentSessionStatus;
    payment_status: string;
  }> {
    const response = await ApiInstance.getApi().get(
      `/payments/status?session_id=${sessionId}`
    );

    return response.data;
  }
}

export default new PaymentApi();
