import ApiInstance from "./ApiInstance";
import { PaginationResult } from "./PaginationContent";
import { Registration } from "./RegistrationApi";
import {
  PropertyData,
  PropertyFilterParams,
  PropertyItem,
  PropertyStatistics,
} from "./PropertyTypes";
import { SortingConfig } from "../interfaces/SortingConfig";
import { AxiosError } from "axios";
import { PropertyCountByTypeStats, PropertyViewStats } from "./AdminApi";

function getSortingQuery(sortingConfig?: SortingConfig) {
  if (!sortingConfig) {
    return "";
  }

  return `&sortingField=${sortingConfig.field}&sortingOrder=${sortingConfig.order}`;
}

class PropertyApi {
  public async getById(propertyId: string): Promise<PropertyItem | undefined> {
    const response = await ApiInstance.getApi().get(
      `/properties/${propertyId}`
    );
    return response.data;
  }

  public async getByRef(reference: string): Promise<PropertyItem | undefined> {
    try {
      const response = await ApiInstance.getApi().get(
        `/properties/reference/${reference}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        return undefined;
      }

      throw error;
    }
  }

  public async list(
    page: number,
    pageSize: number,
    filterParams?: PropertyFilterParams,
    sortingConfig?: SortingConfig
  ): Promise<PaginationResult<PropertyItem>> {
    const sortingQuery = getSortingQuery(sortingConfig);
    const response = await ApiInstance.getApi().get(
      `/properties?page=${page - 1}&pageSize=${pageSize}${sortingQuery}`,
      {
        params: filterParams,
      }
    );
    return response.data || [];
  }

  public async listAgencyProperties(
    agencyId: string,
    page: number,
    pageSize: number,
    sortingConfig?: SortingConfig
  ): Promise<PaginationResult<PropertyItem>> {
    const sortingQuery = getSortingQuery(sortingConfig);
    const response = await ApiInstance.getApi().get(
      `/agencies/${agencyId}/properties?page=${
        page - 1
      }&pageSize=${pageSize}${sortingQuery}`
    );
    return response.data;
  }

  public async getStatistics(): Promise<PropertyStatistics> {
    const response = await ApiInstance.getApi().get(`/properties/stats`);
    return response.data;
  }

  public async getPropertyViewStats(): Promise<PropertyViewStats> {
    const response = await ApiInstance.getApi().get(
      `/properties/stats/property-view`
    );
    return response.data;
  }

  public async getCountPerType(): Promise<PropertyCountByTypeStats> {
    const response = await ApiInstance.getApi().get(
      `/properties/stats/count-per-type`
    );
    return response.data;
  }

  public async listPersonalProperties(
    page: number,
    pageSize: number,
    sortingConfig?: SortingConfig
  ): Promise<PaginationResult<PropertyItem>> {
    const sortingQuery = getSortingQuery(sortingConfig);
    const response = await ApiInstance.getApi().get(
      `/properties/my-properties?page=${
        page - 1
      }&pageSize=${pageSize}${sortingQuery}`
    );
    return response.data;
  }

  public async getPersonalStatistics(): Promise<PropertyStatistics> {
    const response = await ApiInstance.getApi().get(
      `/properties/my-properties/stats`
    );
    return response.data;
  }

  public async watch(propertyId: string): Promise<void> {
    await ApiInstance.getApi().post(`/properties/stats/watch/${propertyId}`);
  }

  public async createProperty(
    propertyData: PropertyData,
    agencyId: string,
    externalPropertyId?: string
  ): Promise<PropertyItem> {
    const data = {
      ...propertyData,
      languageCode: "EN",
      externalPropertyId,
    };

    const response = await ApiInstance.getApi().post(
      `/agencies/${agencyId}/properties`,
      data
    );
    return response.data;
  }

  public async updateProperty(
    id: string,
    propertyData: PropertyData,
    agencyId: string
  ): Promise<PropertyItem> {
    const data = {
      ...propertyData,
      id,
      languageCode: "EN",
    };

    const response = await ApiInstance.getApi().put(
      `/agencies/${agencyId}/properties/${id}`,
      data
    );
    return response.data;
  }

  public async deleteProperty(propertyId: string): Promise<void> {
    await ApiInstance.getApi().delete(`/properties/${propertyId}`);
  }

  public async listRegistrations(propertyId: string): Promise<Registration[]> {
    const response = await ApiInstance.getApi().get(
      `/properties/${propertyId}/registrations`
    );
    return response.data;
  }
}

export default new PropertyApi();
