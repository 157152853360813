import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { FilterName } from "../../enums/FilterName";
import { addThousandSeparators } from "../../util/addThousandSeparators";
import { getInitialMinAndMax } from "../../util/filters";
import "./RangeSlider.css";

function formatNumber(
  number: number | undefined,
  withThousandsSeparator: boolean
) {
  if (number === undefined) {
    return "";
  }

  if (!withThousandsSeparator) {
    return number;
  }

  return addThousandSeparators(number.toString());
}

function removeNonNumericCharacters(value: string) {
  return value.replace(/\D/g, "");
}

export function RangeSlider({
  filterName,
  filterParams,
  setFilterParams,
  totalMin,
  totalMax,
  initialMin,
  initialMax,
  withThousandsSeparator = true,
  stepSize = 50_000,
}: {
  filterName: FilterName;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
  totalMin: number;
  totalMax: number;
  initialMin?: number;
  initialMax?: number;
  withThousandsSeparator?: boolean;
  stepSize?: number;
}) {
  const [inputMin, setInputMin] = useState(initialMin);
  const [inputMax, setInputMax] = useState(initialMax);
  const [sliderMin, setSliderMin] = useState(initialMin ?? totalMin);
  const [sliderMax, setSliderMax] = useState(initialMax ?? totalMax);

  const newTotalMin = roundDownToNearestStepSize(totalMin, stepSize);
  const newTotalMax = roundUpToNearestStepSize(totalMax, stepSize);

  const normalizedInputMin = Math.ceil(
    (((inputMin || newTotalMin) - newTotalMin) / (newTotalMax - newTotalMin)) *
      100
  );
  const normalizedInputMax = Math.ceil(
    (((inputMax || newTotalMax) - newTotalMin) / (newTotalMax - newTotalMin)) *
      100
  );

  const sliderStyle = {
    left: `calc(${normalizedInputMin}% ${
      normalizedInputMin > 50 ? "-" : "+"
    } 2%)`,
    width: `${normalizedInputMax - normalizedInputMin}%`,
  };

  function handleMinInputChange(event: ChangeEvent<HTMLInputElement>) {
    const numericInput = removeNonNumericCharacters(event.target.value);

    if (numericInput === "") {
      setInputMin(undefined);
      return;
    }

    setInputMin(Number(numericInput));
  }

  function handleMaxInputChange(event: ChangeEvent<HTMLInputElement>) {
    const numericInput = removeNonNumericCharacters(event.target.value);

    if (numericInput === "") {
      setInputMax(undefined);
      return;
    }

    const value = Number(numericInput);
    setInputMax(value);
  }

  function handleMinInputBlur(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      // to make sure the input field is empty if the user deletes the value
      setFilterParams((prev) => ({
        ...prev,
        [filterName]: `${inputMin ?? "min"},${inputMax ?? "max"}`,
      }));
      return;
    }

    const numericInput = removeNonNumericCharacters(event.target.value);
    const minFilterValue = Math.max(
      totalMin,
      Math.min(Number(numericInput), inputMax || totalMax)
    );
    const maxFilterValue = inputMax ?? "max";

    setFilterParams((prev) => ({
      ...prev,
      [filterName]: `${minFilterValue},${maxFilterValue}`,
    }));
  }

  function handleMaxInputBlur(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      // to make sure the input field is empty if the user deletes the value
      setFilterParams((prev) => ({
        ...prev,
        [filterName]: `${inputMin ?? "min"},${inputMax ?? "max"}`,
      }));
      return;
    }

    const numericInput = removeNonNumericCharacters(event.target.value);
    const newInputMax = Math.min(
      totalMax,
      Math.max(Number(numericInput), inputMin || totalMin)
    );

    const minFilterValue = inputMin ?? "min";
    const maxFilterValue = newInputMax;
    setFilterParams((prev) => ({
      ...prev,
      [filterName]: `${minFilterValue},${maxFilterValue}`,
    }));
  }

  function handleMinSliderChange(event: ChangeEvent<HTMLInputElement>) {
    let value = Math.max(
      totalMin,
      Math.min(
        roundDownToNearestStepSize(Number(event.target.value), stepSize),
        inputMax || totalMax
      )
    );

    if (value - stepSize / 2 < totalMin) {
      value = totalMin;
    }

    const minFilterValue = value;
    const maxFilterValue = inputMax ?? "max"; // otherwise the max input field will be set to an actual value if it was empty before
    setFilterParams((prev) => ({
      ...prev,
      [filterName]: `${minFilterValue},${maxFilterValue}`,
    }));
  }

  function handleMaxSliderChange(event: ChangeEvent<HTMLInputElement>) {
    let value = Math.min(
      totalMax,
      Math.max(
        roundUpToNearestStepSize(Number(event.target.value), stepSize),
        inputMin || totalMin
      )
    );

    if (value + stepSize / 2 > totalMax) {
      value = totalMax;
    }

    const minFilterValue = inputMin ?? "min"; // otherwise the min input field will be set to an actual value if it was empty before
    const maxFilterValue = value;

    setFilterParams((prev) => ({
      ...prev,
      [filterName]: `${minFilterValue},${maxFilterValue}`,
    }));
  }

  useEffect(() => {
    if (filterParams[filterName] === undefined) {
      setInputMin(undefined);
      setInputMax(undefined);
      setSliderMin(totalMin);
      setSliderMax(totalMax);
      return;
    }

    const [newMin, newMax] = getInitialMinAndMax(filterParams[filterName]);

    setInputMin(newMin);
    setInputMax(newMax);
    setSliderMin(newMin || totalMin);
    setSliderMax(newMax || totalMax);
  }, [filterParams]);

  return (
    <div className="range-slider">
      <div className="number-input-group">
        <input
          type="text"
          className="input-min"
          value={formatNumber(inputMin, withThousandsSeparator)}
          onChange={handleMinInputChange}
          onBlur={handleMinInputBlur}
        />
        <input
          type="text"
          className="input-max"
          value={formatNumber(inputMax, withThousandsSeparator)}
          onChange={handleMaxInputChange}
          onBlur={handleMaxInputBlur}
        />
      </div>
      <div className="slider">
        <div className="progress" style={sliderStyle}></div>
      </div>
      <div className="range-input-group">
        <input
          type="range"
          className="range-min"
          min={totalMin}
          max={totalMax}
          value={sliderMin}
          onChange={handleMinSliderChange}
          step={stepSize}
        />
        <input
          type="range"
          className="range-max"
          min={totalMin}
          max={totalMax}
          value={sliderMax}
          onChange={handleMaxSliderChange}
          step={stepSize}
        />
      </div>
    </div>
  );
}

function roundDownToNearestStepSize(value: number, stepSize: number): number {
  return Math.floor(value / stepSize) * stepSize;
}

function roundUpToNearestStepSize(value: number, stepSize: number): number {
  return Math.ceil(value / stepSize) * stepSize;
}
