import React, { useEffect, useState } from "react";
import "./EditProperty.css";
import { PropertyForm } from "../../components/property-form/PropertyForm";
import PropertyApi from "../../api/PropertyApi";
import { useParams } from "react-router-dom";
import { getUser } from "../../util/getUser";
import { PropertyItem } from "../../api/PropertyTypes";
import { UserType } from "../../interfaces/UserTypes";

export function EditProperty() {
  const [property, setProperty] = useState<PropertyItem | undefined>(undefined);

  const { propertyId } = useParams<{ propertyId: string }>();

  useEffect(() => {
    if (propertyId) {
      PropertyApi.getById(propertyId).then((p) => {
        setProperty(p);
        PropertyApi.watch(propertyId).then();
      });
    }
  }, [propertyId]);

  const user = getUser();
  const canEdit =
    user?.type === UserType.ADMIN ||
    (user?.type === UserType.AGENCY_OWNER &&
      user?.agencyId === property?.agencyId) ||
    (user?.type === UserType.AGENT && user?.id === property?.agent?.id);

  return (
    <div className={"page page-content-no-margin"}>
      <div className={"form-container"}>
        <div className={"form-wrapper"}>
          {canEdit && property && propertyId ? (
            <PropertyForm property={property} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
