import ApiInstance from "./ApiInstance";

export type GlobalAgencyStats = {
  numberOfProperties: number;
  numberOfUsers: number;
  numberOfRegisteredClients: number;
};

export type UserGrowthStats = {
  data: { month: number; year: number; count: number }[];
};

class AgencyOwnerApi {
  public async getGlobalStats(): Promise<GlobalAgencyStats> {
    const response = await ApiInstance.getApi().get(
      `/agency-owner/stats/global`
    );
    return response.data;
  }

  public async getUserGrowthStats(): Promise<UserGrowthStats> {
    const response = await ApiInstance.getApi().get(
      `/agency-owner/stats/global/user-growth`
    );
    return response.data;
  }
}

export default new AgencyOwnerApi();
