import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import user from "../../assets/user.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import "./PasswordReset.css";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import AuthApi from "../../api/AuthApi";
import { Input } from "../../components/input/Input";
import { toast } from "react-toastify";
import { Button } from "../../components/button/Button";

export function PasswordReset() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [toastids, setToastIds] = useState<string[]>([]);

  async function onSubmitPasswordReset(email: string): Promise<void> {
    try {
      await AuthApi.requestPasswordReset(email);
      const toastId = t("passwordReset.sent") as string;
      toastids.forEach((id) => toast.dismiss(id));
      toast(toastId, {
        type: "success",
        toastId: toastId,
      });
    } catch (error) {
      const toastId = t("general.somethingWrong") as string;
      toast(toastId, {
        type: "error",
        toastId: toastId,
      });
      setToastIds((prevState) => [...prevState, toastId]);
      Sentry.captureException(error);
    }
  }

  return (
    <div className={"password-reset-upper-container flex"}>
      <div className={"password-reset-container flex"}>
        <div className={"password-reset-form"}>
          <Logo />
          <div className={"description"}>{t("passwordReset.description")}</div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await onSubmitPasswordReset(email);
            }}
          >
            <Input
              value={email}
              onChange={setEmail}
              placeholder={t("passwordReset.email")}
              required={true}
              type="email"
              image={user}
            />
            <Button className="mt-4" type="submit" fullWidth>
              {t("passwordReset.reset")}
            </Button>
          </form>
          <Link
            className={"login-link mt-4 flex justify-center"}
            to={routes.login}
          >
            {t("passwordReset.login")}
          </Link>
        </div>
      </div>
    </div>
  );
}
