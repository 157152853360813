import React, { useEffect, useState } from "react";
import { MdClose, MdMenu } from "react-icons/md";
import jwtDecode from "jwt-decode";
import { Agency } from "../../api/AgencyApi";
import AuthApi from "../../api/AuthApi";
import { LocalStorage } from "../../enums/LocalStorage";
import "./HeaderLinksMobile.css";
import { Select } from "../select/Select";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { User, UserType } from "../../interfaces/UserTypes";

export function HeaderLinksMobile({
  setIsShowingAddUserModal,
  selectedAgency,
  setSelectedAgency,
  agencies,
  user,
}: {
  setIsShowingAddUserModal: (value: boolean) => void;
  selectedAgency: string;
  setSelectedAgency: (value: string) => void;
  agencies: Agency[];
  user: User | null;
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [overlayVisible, setOverlayVisible] = useState(false);

  useEffect(() => {
    if (overlayVisible) {
      const htmlElement = document.getElementById("html");

      if (htmlElement) {
        htmlElement.classList.add("no-scroll");
      }
    } else {
      const htmlElement = document.getElementById("html");

      if (htmlElement) {
        htmlElement.classList.remove("no-scroll");
      }
    }
  }, [overlayVisible]);

  async function onSelectAgency(value: string) {
    if (value === "") {
      const token = localStorage.getItem(LocalStorage.AdminToken) ?? "";
      localStorage.setItem(LocalStorage.Token, token);
      const user: User = jwtDecode(token);
      localStorage.setItem(LocalStorage.User, JSON.stringify(user));
    } else {
      const token = await AuthApi.getTokenForAgency(value);

      const adminToken = localStorage.getItem(LocalStorage.AdminToken) ?? "";
      if (adminToken === "") {
        localStorage.setItem(
          LocalStorage.AdminToken,
          localStorage.getItem(LocalStorage.Token) ?? ""
        );
      }

      localStorage.setItem(LocalStorage.Token, token.token);
      const user: User = jwtDecode(token.token);
      localStorage.setItem(LocalStorage.User, JSON.stringify(user));
    }

    setSelectedAgency(value);
    window.location.reload();
  }

  function logout() {
    AuthApi.logout();
    navigateAndCloseOverlay(routes.login);
  }

  function navigateAndCloseOverlay(path: string) {
    navigate(path);
    setOverlayVisible(false);
  }

  return (
    <div className="header-links-mobile">
      <MdMenu
        className={`hamburger-menu ${overlayVisible ? "hidden" : ""}`}
        onClick={() => setOverlayVisible(true)}
      />
      <MdClose
        className={`close-menu ${overlayVisible ? "" : "hidden"}`}
        onClick={() => setOverlayVisible(false)}
      />
      <div className={`links-overlay ${overlayVisible ? "" : "hidden"}`}>
        {user && user.type === UserType.ADMIN ? (
          <Select
            className="agency-select"
            label={null}
            value={selectedAgency}
            onChange={onSelectAgency}
            options={[{ label: "Admin", value: "" }].concat(
              agencies.map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            )}
            clean={true}
          />
        ) : null}
        <NavLink
          className="link"
          to={routes.agencyHome}
          onClick={() => setOverlayVisible(false)}
        >
          {t("header.home")}
        </NavLink>
        <NavLink
          className="link"
          to={routes.properties}
          onClick={() => setOverlayVisible(false)}
        >
          {t("header.properties")}
        </NavLink>
        {user &&
        (user.type === UserType.AGENCY_OWNER ||
          user.type === UserType.ADMIN) ? (
          <NavLink
            className="link"
            to={routes.dashboard}
            onClick={() => setOverlayVisible(false)}
          >
            {t("header.dashboard")}
          </NavLink>
        ) : null}
        {user && user.type === UserType.ADMIN ? (
          <NavLink
            className="link"
            to={routes.admin}
            onClick={() => setOverlayVisible(false)}
          >
            {t("header.admin")}
          </NavLink>
        ) : null}
        <div className="account-section links-section">
          {user && user.agencyId ? (
            <div
              className={"item"}
              onClick={() => {
                setOverlayVisible(false);
                navigate(routes.subscriptionSettings);
              }}
            >
              {t("header.settings")}
            </div>
          ) : null}
          <div
            className={"item"}
            onClick={() => {
              setOverlayVisible(false);
              navigate(routes.profileSettings);
            }}
          >
            {t("header.myProfile")}
          </div>
          <div className="item" onClick={() => logout()}>
            {t("header.logout")}
          </div>
        </div>
        <div className="add-section links-section">
          <div
            className="item"
            onClick={() => navigateAndCloseOverlay(routes.addProperties)}
          >
            {t("header.addProperty")}
          </div>
          {user &&
          (user.type === UserType.ADMIN ||
            user.type === UserType.AGENCY_OWNER) ? (
            <div
              className={"item"}
              onClick={() => setIsShowingAddUserModal(true)}
            >
              {t("header.addUser")}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
