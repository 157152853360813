import ApiInstance from "./ApiInstance";
import { PropertyItem } from "./PropertyTypes";
import { PaginationResult } from "./PaginationContent";
import { SortingConfig } from "../interfaces/SortingConfig";
import { User } from "../interfaces/UserTypes";

export type Report = {
  propertyId: string;
  reason: string;
};

export type ReportItem = {
  id: string;
  reason: string;
  property: PropertyItem;
  author: User;
  createdAt: string;
};

class ReportApi {
  public async sendReport(report: Report): Promise<void> {
    await ApiInstance.getApi().post("/report", { ...report });
  }

  public async removeReport(reportId: string): Promise<void> {
    await ApiInstance.getApi().delete(`/report/${reportId}`);
  }

  public async list(
    page: number,
    pageSize: number,
    sortingConfig?: SortingConfig
  ): Promise<PaginationResult<ReportItem>> {
    const orderQuery =
      sortingConfig && sortingConfig.order
        ? `&sortingField=${sortingConfig.field}&sortingOrder=${sortingConfig.order}`
        : "";

    const response = await ApiInstance.getApi().get(
      `/report?page=${page - 1}&pageSize=${pageSize}${orderQuery}`
    );

    return response.data;
  }
}

export default new ReportApi();
