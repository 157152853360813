import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import {
  NavigationItem,
  SideNavigation,
} from "../../components/side-navigation/SideNavigation";
import { ReactComponent as InvoiceIcon } from "../../assets/invoice.svg";
import { ReactComponent as InvoiceIconGrayed } from "../../assets/invoice-grayed.svg";
import { ReactComponent as Statistics } from "../../assets/statistics.svg";
import { ReactComponent as StatisticsGrayed } from "../../assets/statistics-grayed.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as UserIconGrayed } from "../../assets/user-grayed.svg";
import { ReactComponent as OfficeIcon } from "../../assets/office.svg";
import { ReactComponent as OfficeIconGrayed } from "../../assets/office-grayed.svg";
import { routes } from "../../constants/routes";
import { Navigate } from "react-router-dom";
import { UserList } from "../../components/user-list/UserList";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Dialog from "../../components/dialog/Dialog";
import UserApi from "../../api/UserApi";
import { getUser } from "../../util/getUser";
import { InvoicesList } from "../../components/invoices/InvoicesList";
import InvoicesApi, { Invoice } from "../../api/InvoicesApi";
import { DashboardStatistics } from "../../components/dashboard-statistics/DashboardStatistics";
import { Config } from "../../Config";
import { AgencyInformation } from "../../components/agency-information/AgencyInformation";
import AgencyApi, { Agency, Tier } from "../../api/AgencyApi";
import { Button } from "../../components/button/Button";
import { User } from "../../interfaces/UserTypes";

const DASHBOARD_NAV_ITEMS: NavigationItem[] = [
  {
    Icon: Statistics,
    IconLight: StatisticsGrayed,
    titleKey: "sideNavigation.statistics",
    route: routes.dashboardStatistics,
    allowedTiers: [Tier.ADVANCED, Tier.PREMIUM],
  },
  {
    Icon: UserIcon,
    IconLight: UserIconGrayed,
    titleKey: "sideNavigation.users",
    route: routes.dashboardUsers,
  },
  {
    Icon: InvoiceIcon,
    IconLight: InvoiceIconGrayed,
    titleKey: "sideNavigation.invoices",
    route: routes.dashboardInvoices,
  },
  {
    Icon: OfficeIcon,
    IconLight: OfficeIconGrayed,
    titleKey: "sideNavigation.agencyInformation",
    route: routes.dashboardAgencyInformation,
  },
];

export enum DashboardViewType {
  USERS = "USERS",
  STATISTICS = "STATISTICS",
  INVOICES = "INVOICES",
  AGENCY_INFORMATION = "AGENCY_INFORMATION",
}

export function DashboardRedirect() {
  const user = getUser();

  if (user && user.tier !== Tier.ESSENTIAL) {
    return <Navigate to={routes.dashboardStatistics} />;
  }
  return <Navigate to={routes.dashboardUsers} />;
}

export function Dashboard({ viewType }: { viewType: DashboardViewType }) {
  const { t } = useTranslation();

  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isShowingDeleteUserModal, setIsShowingDeleteUserModal] =
    useState<boolean>(false);
  const [deletingUserId, setDeletingUserId] = useState<string>("");
  const [agency, setAgency] = useState<Agency | undefined>(undefined);
  const user = getUser();

  useEffect(() => {
    if (!user || !user.agencyId) {
      return;
    }

    UserApi.list(1, Config.getDefaultPageSize()).then((u) => {
      setUsers(u.data);
      setTotalPages(Math.ceil(u.total / Config.getDefaultPageSize()));
    });
    InvoicesApi.getInvoices().then((i) => setInvoices(i));
    AgencyApi.getCurrent().then((a) => setAgency(a));
  }, []);

  function deleteUser() {
    UserApi.deleteUser(deletingUserId)
      .then(() => {
        UserApi.list(1, Config.getDefaultPageSize()).then((u) => {
          setUsers(u.data);
          setTotalPages(Math.ceil(u.total / Config.getDefaultPageSize()));
        });
        toast(t("dashboard.userDeleteSuccess"), {
          type: "success",
        });
        setDeletingUserId("");
      })
      .catch(() => {
        toast(t("dashboard.userDeleteFailed"), {
          type: "error",
        });
      });
  }

  function retrievePage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    UserApi.list(page, Config.getDefaultPageSize()).then((u) => {
      setUsers(u.data);
      setTotalPages(Math.ceil(u.total / Config.getDefaultPageSize()));
    });
  }

  if (!user || !user.agencyId) {
    return null;
  }

  return (
    <>
      <div className={"page admin"}>
        <div className={"side-nav"}>
          <SideNavigation items={DASHBOARD_NAV_ITEMS} />
        </div>
        <div className="page-content-no-margin">
          {viewType === DashboardViewType.USERS ? (
            <UserList
              users={users}
              retrievePage={(page: number) => retrievePage(page)}
              totalPages={totalPages}
              deleteUser={(id: string) => {
                setDeletingUserId(id);
                setIsShowingDeleteUserModal(true);
              }}
            />
          ) : null}
          {viewType === DashboardViewType.STATISTICS ? (
            <DashboardStatistics />
          ) : null}
          {viewType === DashboardViewType.INVOICES ? (
            <InvoicesList invoices={invoices} />
          ) : null}
          {viewType === DashboardViewType.AGENCY_INFORMATION ? (
            agency !== undefined ? (
              <AgencyInformation agency={agency} />
            ) : null
          ) : null}
        </div>
      </div>
      <Dialog
        isOpen={isShowingDeleteUserModal}
        onRequestClose={() => setIsShowingDeleteUserModal(false)}
        title={"Delete user"}
        size={"msmall"}
      >
        <div className={"report-dialog-content"}>
          <div className={"description"}>{t("dashboard.deleteUser")}</div>
          <div className={"button-wrapper"}>
            <Button
              color="danger"
              onClick={() => {
                deleteUser();
                setIsShowingDeleteUserModal(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
