import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { routes } from "../../constants/routes";
import "./Header.css";
import { useTranslation } from "react-i18next";
import Dialog from "../dialog/Dialog";
import { Input } from "../input/Input";
import { toast } from "react-toastify";
import AuthApi from "../../api/AuthApi";
import { getUser } from "../../util/getUser";
import AgencyApi, { Agency, PaymentStatus } from "../../api/AgencyApi";
import { AxiosError } from "axios";
import { HeaderLinksMain } from "./HeaderLinksMain";
import { HeaderLinksMobile } from "./HeaderLinksMobile";
import { Button } from "../button/Button";
import { User, UserType } from "../../interfaces/UserTypes";

export function Header() {
  const { t } = useTranslation();

  useState<boolean>(false);
  const [isShowingAddUserModal, setIsShowingAddUserModal] =
    useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [selectedAgency, setSelectedAgency] = useState<string>("");
  const [agencies, setAgencies] = useState<Agency[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = getUser();

    if (!user) {
      return;
    }

    if (user.type === UserType.ADMIN) {
      return;
    }

    AgencyApi.getCurrent().then((result) => {
      if (result && result.paymentStatus === PaymentStatus.NOT_PAID) {
        if (user.type === UserType.AGENCY_OWNER) {
          navigate(routes.paymentAndTierSelection);
        } else {
          navigate(routes.notPayed);
        }
      }
    });

    const htmlElement = document.getElementById("html");

    if (htmlElement) {
      htmlElement.classList.remove("no-scroll");
    }
  }, [location]);

  useEffect(() => {
    void getAgencies();
  }, []);

  async function getAgencies() {
    if (getUser()?.type === UserType.ADMIN) {
      const r = await AgencyApi.listAll();
      setAgencies(r);

      const agencyId = getUser()?.agencyId;
      if (agencyId) {
        setSelectedAgency(agencyId);
      }
    }
  }

  async function addUser() {
    if (user) {
      const toastId = toast.loading(t("header.userAddLoading"));

      try {
        await AuthApi.inviteUser(userEmail, user.agencyId);

        toast.update(toastId, {
          type: "success",
          render: t("header.userAdded"),
          isLoading: false,
          autoClose: 5000,
        });
      } catch (e) {
        let errorMessage = t("header.userAddFailed");

        if (e instanceof AxiosError && e.response) {
          if (e.response.status === 400) {
            errorMessage = t("general.invalidEmail");
          } else if (e.response.status === 402) {
            errorMessage = t("header.userLimitReached");
          }
        }

        toast.update(toastId, {
          type: "error",
          render: errorMessage,
          isLoading: false,
          autoClose: 5000,
        });
      }

      setIsShowingAddUserModal(false);
      setUserEmail("");
    }
  }

  useEffect(() => {
    setUser(getUser());
  }, []);

  return (
    <div className="header-wrapper">
      <div className="header flex">
        <Link to="/" className={"logo"}>
          <Logo />
        </Link>
        <HeaderLinksMain
          agencies={agencies}
          user={user}
          setIsShowingAddUserModal={setIsShowingAddUserModal}
          selectedAgency={selectedAgency}
          setSelectedAgency={setSelectedAgency}
        />
        <HeaderLinksMobile
          agencies={agencies}
          user={user}
          setIsShowingAddUserModal={setIsShowingAddUserModal}
          selectedAgency={selectedAgency}
          setSelectedAgency={setSelectedAgency}
        />
      </div>
      <Dialog
        isOpen={isShowingAddUserModal}
        onRequestClose={() => setIsShowingAddUserModal(false)}
        title={t("header.addUser")}
        size={"msmall"}
      >
        <div className={"add-user-dialog-content"}>
          <div className={"description"}>{t("header.addUserDescription")}</div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await addUser();
            }}
          >
            <div className={"email-input"}>
              <Input
                value={userEmail}
                onChange={setUserEmail}
                placeholder={"Email"}
                required={true}
                type={"email"}
              />
            </div>
            <div className={"button-wrapper"}>
              <Button type="submit" disabled={userEmail.trim().length === 0}>
                {t("header.addUser")}
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
}
