import React from "react";
import "./AddPropertyManually.css";
import { PropertyForm } from "../../components/property-form/PropertyForm";

export function AddPropertyManually() {
  return (
    <div className={"page page-content-no-margin"}>
      <div className={"form-container"}>
        <div className={"form-wrapper"}>
          <PropertyForm />
        </div>
      </div>
    </div>
  );
}
