import React, { useMemo, useState } from "react";
import "./AgencyInformation.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Input } from "../input/Input";
import { Select } from "../select/Select";
import { regions } from "../../constants/Regions";
import AgencyApi, { Agency } from "../../api/AgencyApi";
import { Button } from "../button/Button";

export function AgencyInformation({ agency }: { agency: Agency }) {
  const { t } = useTranslation();

  const defaultRegions: string[] = useMemo(
    () => Object.keys(regions).sort(),
    []
  );

  const [agencyName, setAgencyName] = useState<string>(agency.name);
  const [agencyEmail, setAgencyEmail] = useState<string>(agency.email);
  const [agencyReference, setAgencyReference] = useState<string>(
    agency.reference
  );
  const [agencyStreet, setAgencyStreet] = useState<string>(
    agency.address.street
  );
  const [agencyNumber, setAgencyNumber] = useState<string>(
    agency.address.number
  );
  const [agencyCity, setAgencyCity] = useState<string>(agency.address.city);
  const [agencyCountry, setAgencyCountry] = useState<string>(
    agency.address.country.name
  );
  const [agencyVat, setAgencyVat] = useState<string>(agency.vatNumber);
  const [defaultRegion, setDefaultRegion] = useState<string>(
    agency.defaultRegion
  );

  async function onFormSubmit() {
    try {
      await toast.promise(
        AgencyApi.updateAgency(
          agencyName,
          agencyReference,
          agencyEmail,
          agencyStreet,
          agencyNumber,
          agencyCity,
          agencyCountry,
          agencyVat,
          defaultRegion
        ),
        {
          pending: t("agencyInformation.updating"),
          error: t("general.somethingWrong"),
          success: t("agencyInformation.updated"),
        }
      );

      setAgencyVat(agencyVat);
    } catch (e) {
      // Toast will handle the error
    }
  }
  return (
    <div className={"agency-information-form"}>
      <div className={"title"}>{t("agencyInformation.agencyInformation")}</div>
      <form
        className={"agency-form"}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit();
        }}
      >
        <Input
          value={agencyName}
          onChange={setAgencyName}
          placeholder={t("agencyInformation.agencyName")}
          required={true}
          type="text"
        />
        <Input
          value={agencyEmail}
          onChange={setAgencyEmail}
          placeholder={t("agencyInformation.agencyEmail")}
          required={true}
          type="email"
        />
        <div className={"horizontal-input"}>
          <Input
            value={agencyReference}
            onChange={setAgencyReference}
            placeholder={t("agencyInformation.agencyReference")}
            required={false}
            type="text"
          />
          <Select
            value={defaultRegion}
            onChange={setDefaultRegion}
            label={t("agencyInformation.defaultRegion")}
            required={true}
            options={defaultRegions.map((region: string) => {
              return {
                label: region,
                value: region,
              };
            })}
          />
        </div>
        <div className={"horizontal-input"}>
          <Input
            value={agencyStreet}
            onChange={setAgencyStreet}
            placeholder={t("agencyInformation.street")}
            required={true}
            type="text"
          />
          <Input
            value={agencyNumber}
            onChange={setAgencyNumber}
            placeholder={t("agencyInformation.number")}
            required={true}
            type="text"
          />
        </div>
        <div className={"horizontal-input"}>
          <Input
            value={agencyCity}
            onChange={setAgencyCity}
            placeholder={t("agencyInformation.city")}
            required={true}
            type="text"
          />
          <Input
            value={agencyCountry}
            onChange={setAgencyCountry}
            placeholder={t("agencyInformation.country")}
            required={true}
            type="text"
            readOnly={true}
          />
        </div>
        <Input
          value={agencyVat}
          onChange={setAgencyVat}
          placeholder={t("agencyInformation.agencyVAT")}
          required={true}
          type="text"
        />
        <Button className="mt-4" type="submit">
          {t("agencyInformation.update")}
        </Button>
      </form>
    </div>
  );
}
