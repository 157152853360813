export function isXmlFeedUrl(url: string) {
  try {
    const urlObj = new URL(url);

    const newUrl = urlObj.protocol + "//" + urlObj.host + urlObj.pathname;

    return newUrl.startsWith("http") && newUrl.endsWith(".xml");
  } catch (_) {
    return false;
  }
}
