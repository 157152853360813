import { ReactElement } from "react";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import i18n from "../i18n";

export function parseProperty(
  property: string | number | undefined,
  squareMetres?: boolean,
  formatMoney?: boolean
): ReactElement {
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  if (!property) {
    return <>-</>;
  }

  if (squareMetres) {
    return (
      <>
        {property} m<sup>2</sup>
      </>
    );
  }

  if (formatMoney && typeof property === "number") {
    return <>{formatter.format(property)}</>;
  }

  if (typeof property === "string") {
    return <>{capitalizeFirstLetter(property.replaceAll("_", " "))}</>;
  }

  return <>{property}</>;
}
