import "./PasswordRequirements.css";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export function PasswordRequirements({
  password,
  className,
}: {
  password: string;
  className?: string;
}) {
  const { t } = useTranslation();
  const length = password.length > 8;
  const digit = password.match(/\d+/);
  const lowercase = password.match(/[a-z]+/);
  const uppercase = password.match(/[A-Z]+/);
  const specialChar = password.match(/[^a-zA-Z0-9]+/);

  return (
    <div className={`password-requirements ${className}`}>
      <div className={"item"}>
        {lowercase ? <FaCheck className={"success"} /> : <RxCross2 />}{" "}
        {t("password.lowercase")}
      </div>
      <div className={"item"}>
        {uppercase ? <FaCheck className={"success"} /> : <RxCross2 />}{" "}
        {t("password.uppercase")}
      </div>
      <div className={"item"}>
        {digit ? <FaCheck className={"success"} /> : <RxCross2 />}{" "}
        {t("password.digits")}
      </div>
      <div className={"item"}>
        {specialChar ? <FaCheck className={"success"} /> : <RxCross2 />}{" "}
        {t("password.special")}
      </div>
      <div className={"item"}>
        {length ? <FaCheck className={"success"} /> : <RxCross2 />}{" "}
        {t("password.length")}
      </div>
    </div>
  );
}
