import { Document } from "../../api/PropertyTypes";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import "./PropertyDocuments.css";
import { FaRegFilePdf } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export function PropertyDocuments({ documents }: { documents: Document[] }) {
  const { t } = useTranslation();

  return (
    <>
      {documents.length <= 0 ? (
        <div>{t("property.noDocuments")}</div>
      ) : (
        <ul className={"documents"}>
          {documents.map((d, index) => (
            <li key={index}>
              <a href={d.url} target={"_blank"}>
                <div className={"flex align-center"}>
                  {capitalizeFirstLetter(d.type.replaceAll("_", " "))}
                  <FaRegFilePdf className={"ml-3"} />
                </div>
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
