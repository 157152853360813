import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import "./Tooltip.css";

export function Tooltip({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={`tooltip ${className}`}>
      <FaQuestionCircle className="tooltip-icon" />
      <div className="tooltip-content">{children}</div>
    </div>
  );
}
