export enum Pool {
  UNDEFINED = "UNDEFINED",
  WITH_POOL = "WITH_POOL",
  NO_POOL = "NO_POOL",
  COMMUNAL = "COMMUNAL",
  PRIVATE = "PRIVATE",
}

export enum Garden {
  UNDEFINED = "UNDEFINED",
  WITH_GARAGE = "WITH_GARDEN",
  NO_GARDEN = "NO_GARDEN",
  COMMUNAL = "COMMUNAL",
  PRIVATE = "PRIVATE",
}

export enum Garage {
  UNDEFINED = "UNDEFINED",
  WITH_GARAGE = "WITH_GARAGE",
  NO_GARAGE = "NO_GARAGE",
  COMMUNAL = "COMMUNAL",
  PRIVATE = "PRIVATE",
  CARPORT = "CARPORT",
}

export enum Orientation {
  N = "NORTH",
  NE = "NORTH_EAST",
  E = "EAST",
  SE = "SOUTH_EAST",
  S = "SOUTH",
  SW = "SOUTH_WEST",
  W = "WEST",
  NW = "NORTH_WEST",
}
