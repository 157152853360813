import ApiInstance from "./ApiInstance";
import { PropertyItem } from "./PropertyTypes";
import { capitalizeFirstLetter } from "../util/capitalizeFirstLetter";
import { User } from "../interfaces/UserTypes";

export type ClientData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type Client = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type Registration = {
  id: string;
  client: Client;
  agent: User;
  property: PropertyItem;
  status: RegistrationStatus;
};

export enum RegistrationStatus {
  UNCONFIRMED_CLIENT = "UNCONFIRMED_CLIENT",
  CONFIRMED_CLIENT = "CONFIRMED_CLIENT",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
}

class RegistrationApi {
  public async registerClient(
    clientData: ClientData,
    propertyId: string
  ): Promise<Registration> {
    const data = {
      ...clientData,
      firstName: capitalizeFirstLetter(clientData.firstName),
      propertyId,
    };

    const response = await ApiInstance.getApi().post(
      "/client-registration",
      data
    );
    return response.data;
  }

  public async confirmClient(registrationId: string, token: string) {
    const data = {
      registrationId,
      token,
    };

    await ApiInstance.getApi().post("/client-registration/confirm", data);
  }

  public async acceptClient(registrationId: string) {
    const data = {
      status: RegistrationStatus.ACCEPTED,
    };

    await ApiInstance.getApi().post(
      `/client-registration/${registrationId}`,
      data
    );
  }

  public async refuseClient(registrationId: string) {
    const data = {
      status: RegistrationStatus.REFUSED,
    };

    await ApiInstance.getApi().post(
      `/client-registration/${registrationId}`,
      data
    );
  }
}

export default new RegistrationApi();
