export const routes = {
  root: "/",
  agencyHome: "/home",
  personalHome: "/home/personal",
  favoritesHome: "/home/favorites",
  login: "/login",
  propertyDetail: "/property/:propertyId",
  addProperties: "/properties/add",
  addPropertyManually: "/properties/add/manual",
  addPropertyExternal: "/properties/add/external/:propertyId",
  editProperty: "/properties/edit/:propertyId",
  properties: "/properties",
  resetPassword: "/reset-password",
  confirmAccount: "/confirm-account",
  registration: "/registration",
  completeAccount: "/complete-account",
  dashboard: "/dashboard",
  dashboardStatistics: "/dashboard/statistics",
  dashboardUsers: "/dashboard/users",
  dashboardInvoices: "/dashboard/invoices",
  adminStatistics: "/admin/statistics",
  adminUsers: "/admin/users",
  adminProperties: "/admin/properties",
  adminReportedProperties: "/admin/reported-properties",
  adminAgencies: "/admin/agencies",
  admin: "/admin",
  notPayed: "/not-payed",
  payment: "/payment",
  paymentAndTierSelection: "/select-payment-and-tier",
  paymentConfirmation: "/confirm-payment",
  settings: "/settings",
  subscriptionSettings: "/settings/subscription",
  inmobaliaSettings: "/settings/inmobalia",
  profileSettings: "/settings/profile",
  clientRegistrationConfirm: "/confirm-client",
  dashboardAgencyInformation: "/dashboard/agency-information",
};
