import React, { useEffect, useState } from "react";
import PropertyApi from "../../api/PropertyApi";
import { HomeWithStats } from "../../components/home-with-stats/HomeWithStats";
import { Config } from "../../Config";
import { getUser } from "../../util/getUser";
import { PropertyItem, PropertyStatistics } from "../../api/PropertyTypes";
import { SortingConfig } from "../../interfaces/SortingConfig";

export function Home() {
  const [agencyProperties, setAgencyProperties] = useState<PropertyItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [statistics, setStatistics] = useState<PropertyStatistics | null>(null);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = getUser();
    if (user && user.agencyId) {
      PropertyApi.listAgencyProperties(
        user.agencyId,
        1,
        Config.getDefaultPageSize()
      )
        .then((p) => {
          setLoading(false);
          setAgencyProperties(p.data);
          setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
        })
        .finally(() => {
          setLoading(false);
        });
    }

    PropertyApi.getStatistics().then((result) => {
      setStatistics(result);
    });
  }, []);

  function retrievePage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    const user = getUser();
    if (user) {
      PropertyApi.listAgencyProperties(
        user.agencyId,
        page,
        Config.getDefaultPageSize(),
        sortingConfig
      ).then((p) => {
        setAgencyProperties(p.data);
        setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
      });
    }
  }

  return (
    <HomeWithStats
      properties={agencyProperties}
      setProperties={setAgencyProperties}
      retrievePage={retrievePage}
      propertyTotalPages={totalPages}
      propertyStatistics={statistics}
      enableEditButtons={false}
      sortingConfig={sortingConfig}
      setSortingConfig={setSortingConfig}
      loading={loading}
    />
  );
}
