import ApiInstance from "./ApiInstance";

export type Language = {
  id: string;
  name: string;
  code: LanguageCode;
};

export enum LanguageCode {
  ENGLISH = "EN",
  SPANISH = "ES",
}

class LanguageApi {
  public async getLanguages(): Promise<Language[]> {
    const response = await ApiInstance.getApi().get("/language");
    return response.data;
  }
}

export default new LanguageApi();
