import "./AdminStatistics.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import AdminApi, {
  GlobalStats,
  PropertyViewStats,
  UserGrowthStats,
} from "../../api/AdminApi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { TimeValueGraph } from "../time-value-graph/TimeValueGraph";

ChartJS.register(
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export function AdminStatistics() {
  const { t } = useTranslation();

  const [globalStats, setGlobalStats] = useState<GlobalStats>({
    numberOfAgencies: 0,
    numberOfProperties: 0,
    numberOfUsers: 0,
  });
  const [userGrowthStats, setUserGrowthStats] = useState<UserGrowthStats>({
    data: [],
  });
  const [propertyViewStats, setPropertyViewStats] = useState<PropertyViewStats>(
    {
      data: [],
    }
  );

  useEffect(() => {
    AdminApi.getGlobalStats().then((g) => {
      setGlobalStats(g);
    });
    AdminApi.getUserGrowthStats().then((d) => setUserGrowthStats(d));
    AdminApi.getPropertyViewStats().then((d) => setPropertyViewStats(d));
  }, []);

  return (
    <div className={"admin-statistics"}>
      <div className={"statistics"}>
        <div className={"item"}>
          <div>
            <div className={"title"}>{t("statistics.nrAgencies")}</div>
            <div className={"value"}>{globalStats.numberOfAgencies}</div>
          </div>
        </div>
        <div className={"item"}>
          <div>
            <div className={"title"}>{t("statistics.nrProperties")}</div>
            <div className={"value"}>{globalStats.numberOfProperties}</div>
          </div>
        </div>
        <div className={"item"}>
          <div>
            <div className={"title"}>{t("statistics.nrUsers")}</div>
            <div className={"value"}>{globalStats.numberOfUsers}</div>
          </div>
        </div>
      </div>
      <div className={"statistics"}>
        <div className={"item"}>
          <TimeValueGraph
            stats={userGrowthStats}
            title={t("graphs.userGrowth")}
          />
        </div>
        <div className={"item"}>
          <TimeValueGraph
            stats={propertyViewStats}
            title={t("graphs.propertyViews")}
            noLabels={true}
            cumulative={false}
          />
        </div>
      </div>
    </div>
  );
}
