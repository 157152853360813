import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import user from "../../assets/user.svg";
import lock from "../../assets/lock.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import AuthApi from "../../api/AuthApi";
import axios from "axios";
import { Input } from "../../components/input/Input";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { LocalStorage } from "../../enums/LocalStorage";
import { Button } from "../../components/button/Button";
import { User } from "../../interfaces/UserTypes";

export function Login() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealedPassword, setIsRevealedPassword] = useState(false);
  const [toastIds, setToastIds] = useState<string[]>([]);
  const navigate = useNavigate();

  async function onSubmitLogin(email: string, password: string): Promise<void> {
    try {
      const { token, type } = await AuthApi.login(email, password);
      localStorage.setItem(LocalStorage.Token, token);
      localStorage.setItem(LocalStorage.Type, type);
      const user: User = jwt_decode(token);
      localStorage.setItem(LocalStorage.User, JSON.stringify(user));

      toastIds.forEach((id) => toast.dismiss(id));
      navigate(routes.agencyHome);
    } catch (error) {
      Sentry.captureException(error);

      if (axios.isAxiosError(error) && error.response?.status === 401) {
        const toastId = t("login.wrongCredentials") as string;
        setToastIds((prevState) => [...prevState, toastId]);
        toast(t("login.wrongCredentials") as string, {
          type: "error",
          toastId: toastId,
        });
      } else {
        const toastId = t("general.somethingWrong") as string;
        setToastIds((prevState) => [...prevState, toastId]);
        toast(t("general.somethingWrong") as string, {
          type: "error",
          toastId: toastId,
        });
      }
    }
  }

  return (
    <div className="login-upper-container flex">
      <div className="login-container flex">
        <div className="login-form">
          <Logo />
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await onSubmitLogin(email, password);
            }}
          >
            <Input
              value={email}
              onChange={setEmail}
              placeholder={t("login.email")}
              required={true}
              type="email"
              image={user}
            />
            <Input
              value={password}
              onChange={setPassword}
              placeholder={t("login.password")}
              required={true}
              type={isRevealedPassword ? "text" : "password"}
              image={lock}
              showPasswordIcon={true}
              isRevealedPassword={isRevealedPassword}
              setIsRevealedPassword={setIsRevealedPassword}
            />
            <Button className="mt-4" type="submit" fullWidth>
              {t("login.login")}
            </Button>
          </form>
          <Link
            className="password-reset-link mt-4 flex justify-center"
            to={routes.resetPassword}
          >
            {t("login.forgotPassword")}
          </Link>
          <Link
            className="password-reset-link mt-4 flex justify-center"
            to={routes.registration}
          >
            {t("login.registration")}
          </Link>
        </div>
      </div>
    </div>
  );
}
