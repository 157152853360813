import ApiInstance from "./ApiInstance";
import { PaginationResult } from "./PaginationContent";
import { Address } from "./PropertyTypes";
import { User } from "../interfaces/UserTypes";

export enum PaymentStatus {
  PAID = "PAID",
  NOT_PAID = "NOT_PAID",
  PAID_CANCELLED = "PAID_CANCELLED",
}

export enum Tier {
  ESSENTIAL = "ESSENTIAL",
  ADVANCED = "ADVANCED",
  PREMIUM = "PREMIUM",
}

export type Agency = {
  id: string;
  name: string;
  email: string;
  reference: string;
  ownerId: string;
  paymentStatus: PaymentStatus;
  agents: User[];
  paymentExpiration?: string;
  subscriptionTier: Tier;
  inmobaliaApiKey?: string;
  inmobaliaXmlFeedUrl?: string;
  vatNumber: string;
  address: Address;
  defaultRegion: string;
  createdAt: string;
};

class AgencyApi {
  public async list(
    page: number,
    pageSize: number
  ): Promise<PaginationResult<Agency>> {
    const response = await ApiInstance.getApi().get(
      `/agencies?page=${page - 1}&pageSize=${pageSize}`
    );
    return response.data;
  }

  public async listAll(): Promise<Agency[]> {
    const response = await ApiInstance.getApi().get(`/agencies/all`);
    return response.data;
  }

  public async getCurrent(): Promise<Agency | undefined> {
    const response = await ApiInstance.getApi().get(`/agencies/me`);
    return response.data;
  }

  public async updateInmobaliaXmlFeedUrl(url: string): Promise<void> {
    await ApiInstance.getApi().put(`/agencies/me/inmobalia`, {
      xmlFeedUrl: url,
    });
  }

  public async getById(agencyId: string): Promise<Agency | undefined> {
    const response = await ApiInstance.getApi().get(`/agencies/${agencyId}`);
    return response.data;
  }

  public async updateAgency(
    agencyName: string,
    agencyReference: string,
    agencyEmail: string,
    agencyStreet: string,
    agencyNumber: string,
    agencyCity: string,
    agencyCountry: string,
    agencyVat: string,
    defaultRegion: string
  ): Promise<Agency | undefined> {
    const data = {
      agencyName,
      agencyEmail,
      agencyReference,
      agencyStreet,
      agencyNumber,
      agencyCity,
      agencyCountry,
      agencyVat,
      defaultRegion,
    };
    const response = await ApiInstance.getApi().put(`/agencies/me`, data);
    return response.data;
  }
}

export default new AgencyApi();
