import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import Logo from "../assets/favicon.png";
import { PropertyItem } from "../api/PropertyTypes";
import i18n from "../i18n";
import { capitalizeFirstLetter } from "../util/capitalizeFirstLetter";
import { dateToRelativeString } from "../util/dateToRelativeString";
import { DateTime } from "luxon";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#2C4E4C",
  },
  logoContainer: {
    backgroundColor: "white",
    borderRadius: 200,
    padding: 5,
    width: 40,
    height: 40,
    left: 275,
    top: 40,
    fontWeight: "normal",
    fontSize: 14,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImage: {
    width: 25,
    height: 25,
    objectFit: "contain",
  },
  titleContainer: {
    fontSize: 30,
    textAlign: "center",
    color: "white",
    textTransform: "uppercase",
    width: 400,
    position: "absolute",
    left: 100,
    alignItems: "center",
    top: 110,
    fontWeight: "bold",
  },
  subTitleContainer: {
    fontSize: 18,
    textAlign: "center",
    color: "white",
    textTransform: "uppercase",
    width: 400,
    position: "absolute",
    left: 100,
    alignItems: "center",
    top: 190,
    fontWeight: "normal",
  },
  siteContainer: {
    backgroundColor: "white",
    borderRadius: 100,
    padding: 5,
    width: 200,
    left: 200,
    top: 230,
    fontWeight: "normal",
    fontSize: 14,
    position: "absolute",
    display: "flex",
    alignItems: "center",
  },
  bottomContainer: {
    backgroundColor: "white",
    borderRadius: 12,
    color: "#2C4E4C",
    position: "absolute",
    padding: 10,
    bottom: 50,
    left: 50,
    width: 500,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "thin",
    zIndex: 1,
  },
  overlayContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "65%",
    zIndex: 3,
  },
  overlayImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  page2: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    color: "#2C4E4C",
    width: "100%",
  },
  title: {
    fontSize: 30,
    textTransform: "uppercase",
    width: 400,
  },
  line: {
    height: 1,
    width: 200,
    backgroundColor: "#2C4E4C",
    marginTop: 15,
    marginBottom: 15,
  },
  lineFull: {
    height: 1,
    width: "100%",
    backgroundColor: "lightgray",
    marginTop: 5,
    marginBottom: 10,
  },
  headerLogo: {
    width: 12,
    height: 12,
    objectFit: "contain",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    fontSize: 12,
    marginBottom: 20,
    zIndex: 1,
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 7,
  },
  description: {
    fontSize: 12,
  },
  content: {
    padding: "15 25 15 25",
  },
  page2InfoContainer: {
    backgroundColor: "white",
    borderRadius: 12,
    color: "#2C4E4C",
    position: "absolute",
    padding: 10,
    bottom: 530,
    left: 150,
    width: 300,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "thin",
    zIndex: 1,
  },
  page2InfoContainerShadow: {
    position: "absolute",
    bottom: 529,
    left: 148,
    width: 304,
    height: 37,
    opacity: 0.1,
    backgroundColor: "black",
    borderRadius: 12,
    zIndex: 2,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 12,
    color: "gray",
  },
  specsTitle: {
    fontSize: 14,
    fontWeight: "heavy",
    marginBottom: 10,
  },
  image1: {
    position: "absolute",
    width: "60%",
    height: 210,
    objectFit: "cover",
    objectPosition: "center",
    bottom: 425,
    left: 0,
  },
  image2: {
    position: "absolute",
    width: "60%",
    height: 210,
    objectFit: "cover",
    objectPosition: "center",
    bottom: 210,
    left: 0,
  },
  image3: {
    position: "absolute",
    width: "100%",
    height: 205,
    objectFit: "cover",
    objectPosition: "center",
    bottom: 0,
    left: 0,
  },
  image4: {
    position: "absolute",
    width: "39%",
    height: 425,
    objectFit: "cover",
    objectPosition: "center",
    bottom: 210,
    right: 0,
  },
  image5: {
    position: "absolute",
    width: "100%",
    height: 200,
    objectFit: "cover",
    objectPosition: "center",
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

export function PropertyPDF({ property }: { property: PropertyItem }) {
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoContainer}>
          <Image style={styles.logoImage} src={Logo} />
        </View>
        <View style={styles.titleContainer}>
          <Text>
            {property.subType} in {property.subregion}
          </Text>
        </View>
        <View style={styles.subTitleContainer}>
          <Text>{property.address.city}</Text>
        </View>
        <View style={styles.siteContainer}>
          <Text>estatebridge.io</Text>
        </View>
        <View style={styles.bottomContainer}>
          <Text>
            {property.reference} | {formatter.format(property.price)} |{" "}
            {property.subType}
          </Text>
        </View>
        <View style={styles.overlayContainer}>
          <Image
            style={styles.overlayImage}
            src={property.pictureUrls[0] ?? ""}
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page2}>
        <View style={styles.content}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image style={styles.headerLogo} src={Logo} />
              <Text>Estate Bridge</Text>
            </View>
            <Text>estatebridge.io</Text>
          </View>
          <Text style={styles.title}>
            {property.generalType} in {property.subregion}
          </Text>
          <View style={styles.line} />
          <Text style={styles.description}>
            {property.descriptions[0]?.description.slice(0, 831)}{" "}
            <Link
              style={styles.description}
              src={`${window.location.origin}/property/${property.id}`}
            >
              Read more
            </Link>
          </Text>
        </View>
        <View style={styles.page2InfoContainer}>
          <Text>
            {property.bedrooms} bedrooms | {property.bathrooms} bathrooms{" "}
          </Text>
        </View>
        <View style={styles.page2InfoContainerShadow} />
        <View style={styles.overlayContainer}>
          {property.pictureUrls[1] ? (
            <Image
              style={styles.overlayImage}
              src={property.pictureUrls[1] ?? ""}
            />
          ) : null}
        </View>
      </Page>
      <Page size="A4" style={styles.page2}>
        <View style={styles.content}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image style={styles.headerLogo} src={Logo} />
              <Text>Estate Bridge</Text>
            </View>
            <Text>estatebridge.io</Text>
          </View>
          <Text style={styles.specsTitle}>Specifications</Text>
          <View style={styles.contentWrapper}>
            <Text>Bedrooms</Text>
            <Text>{property.bedrooms}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Bathrooms</Text>
            <Text>{property.bathrooms}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Built</Text>
            <Text>{property.builtSurface} m2</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Int. floor space</Text>
            <Text>{property.interiorSurface} m2</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Terrace</Text>
            <Text>{property.terraceSurface} m2</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Plot size</Text>
            <Text>{property.totalSurface} m2</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Floor</Text>
            <Text>{property.floor}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Number of floors</Text>
            <Text>{property.numberOfFloors}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Number of toilets</Text>
            <Text>{property.toilets}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Pool</Text>
            <Text>{capitalizeFirstLetter(property.pool)}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Garden</Text>
            <Text>{capitalizeFirstLetter(property.garden)}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Garage</Text>
            <Text>{capitalizeFirstLetter(property.garage)}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Orientation</Text>
            <Text>{capitalizeFirstLetter(property.orientation)}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Parking spaces</Text>
            <Text>{property.parkingSpaces}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Completion</Text>
            <Text>{property.completionYear}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Updated</Text>
            <Text>
              {dateToRelativeString(DateTime.fromISO(property.updatedAt))}
            </Text>
          </View>
          <View style={styles.lineFull} />
          <Text style={styles.specsTitle}>Location</Text>
          <View style={styles.contentWrapper}>
            <Text>Street</Text>
            <Text>{property.address.street}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Number</Text>
            <Text>{property.address.number}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>City</Text>
            <Text>{property.address.city}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Country</Text>
            <Text>{property.address.country.name}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Region</Text>
            <Text>{property.region}</Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Subregion</Text>
            <Text>{property.subregion}</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page2}>
        <View style={styles.content}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image style={styles.headerLogo} src={Logo} />
              <Text>Estate Bridge</Text>
            </View>
            <Text>estatebridge.io</Text>
          </View>
          <Text style={styles.specsTitle}>Costs</Text>
          <View style={styles.contentWrapper}>
            <Text>Community fees</Text>
            <Text>
              {property.communityFees !== undefined
                ? formatter.format(property.communityFees)
                : null}
            </Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>IBI</Text>
            <Text>
              {property.IBI !== undefined
                ? formatter.format(property.IBI)
                : null}
            </Text>
          </View>
          <View style={styles.lineFull} />
          <View style={styles.contentWrapper}>
            <Text>Garbage tax</Text>
            <Text>
              {property.garbageTax !== undefined
                ? formatter.format(property.garbageTax)
                : null}
            </Text>
          </View>
        </View>
        {property.pictureUrls[2] ? (
          <Image style={styles.image1} src={property.pictureUrls[2] ?? ""} />
        ) : null}
        {property.pictureUrls[3] ? (
          <Image style={styles.image2} src={property.pictureUrls[3] ?? ""} />
        ) : null}
        {property.pictureUrls[4] ? (
          <Image style={styles.image4} src={property.pictureUrls[4] ?? ""} />
        ) : null}
        {property.pictureUrls[5] ? (
          <Image style={styles.image3} src={property.pictureUrls[5] ?? ""} />
        ) : null}
      </Page>
      {property.pictureUrls[6] ? (
        <Page size="A4" style={styles.page2}>
          <View style={styles.content}>
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Image style={styles.headerLogo} src={Logo} />
                <Text>Estate Bridge</Text>
              </View>
              <Text>estatebridge.io</Text>
            </View>
          </View>
          {property.pictureUrls[6] ? (
            <Image style={styles.image5} src={property.pictureUrls[6] ?? ""} />
          ) : null}
          {property.pictureUrls[7] ? (
            <Image style={styles.image1} src={property.pictureUrls[7] ?? ""} />
          ) : null}
          {property.pictureUrls[8] ? (
            <Image style={styles.image2} src={property.pictureUrls[8] ?? ""} />
          ) : null}
          {property.pictureUrls[9] ? (
            <Image style={styles.image4} src={property.pictureUrls[9] ?? ""} />
          ) : null}
          {property.pictureUrls[10] ? (
            <Image style={styles.image3} src={property.pictureUrls[10] ?? ""} />
          ) : null}
        </Page>
      ) : null}
      {property.pictureUrls[11] ? (
        <Page size="A4" style={styles.page2}>
          <View style={styles.content}>
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Image style={styles.headerLogo} src={Logo} />
                <Text>Estate Bridge</Text>
              </View>
              <Text>estatebridge.io</Text>
            </View>
          </View>
          {property.pictureUrls[11] ? (
            <Image style={styles.image5} src={property.pictureUrls[11] ?? ""} />
          ) : null}
          {property.pictureUrls[12] ? (
            <Image style={styles.image1} src={property.pictureUrls[12] ?? ""} />
          ) : null}
          {property.pictureUrls[13] ? (
            <Image style={styles.image2} src={property.pictureUrls[13] ?? ""} />
          ) : null}
          {property.pictureUrls[14] ? (
            <Image style={styles.image4} src={property.pictureUrls[14] ?? ""} />
          ) : null}
          {property.pictureUrls[15] ? (
            <Image style={styles.image3} src={property.pictureUrls[15] ?? ""} />
          ) : null}
        </Page>
      ) : null}
      {property.pictureUrls[16] ? (
        <Page size="A4" style={styles.page2}>
          <View style={styles.content}>
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <Image style={styles.headerLogo} src={Logo} />
                <Text>Estate Bridge</Text>
              </View>
              <Text>estatebridge.io</Text>
            </View>
          </View>
          {property.pictureUrls[16] ? (
            <Image style={styles.image5} src={property.pictureUrls[16] ?? ""} />
          ) : null}
          {property.pictureUrls[17] ? (
            <Image style={styles.image1} src={property.pictureUrls[17] ?? ""} />
          ) : null}
          {property.pictureUrls[18] ? (
            <Image style={styles.image2} src={property.pictureUrls[18] ?? ""} />
          ) : null}
          {property.pictureUrls[19] ? (
            <Image style={styles.image4} src={property.pictureUrls[19] ?? ""} />
          ) : null}
          {property.pictureUrls[20] ? (
            <Image style={styles.image3} src={property.pictureUrls[20] ?? ""} />
          ) : null}
        </Page>
      ) : null}
    </Document>
  );
}
