import ApiInstance from "./ApiInstance";
import { PaginationResult } from "./PaginationContent";
import { AxiosError } from "axios";
import { ExternalPropertyItem } from "./ExternalPropertyTypes";

class ExternalPropertyApi {
  public async getById(
    agencyId: string,
    propertyId: string
  ): Promise<ExternalPropertyItem | undefined> {
    const response = await ApiInstance.getApi().get(
      `/agencies/${agencyId}/external-properties/${propertyId}`
    );
    return response.data;
  }

  public async list(
    agencyId: string,
    page: number,
    pageSize: number
  ): Promise<PaginationResult<ExternalPropertyItem>> {
    const response = await ApiInstance.getApi().get(
      `/agencies/${agencyId}/external-properties?page=${
        page - 1
      }&pageSize=${pageSize}`
    );
    return response.data || [];
  }

  public async syncExternalProperties(agencyId: string): Promise<{
    errors: Array<{ propertyReference: string | undefined; error: string }>;
  } | void> {
    try {
      await ApiInstance.getApi().post(
        `/agencies/${agencyId}/external-properties/sync`
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          const { errors } = error.response.data;
          return {
            errors,
          };
        } else if (error.response?.status === 204) {
          // If the response status is 204, return `undefined` to indicate success
          return;
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
}

export default new ExternalPropertyApi();
