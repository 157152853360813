import { useState } from "react";
import { Input } from "../input/Input";
import { useTranslation } from "react-i18next";
import "./ClientForm.css";
import { ClientData } from "../../api/RegistrationApi";
import { toast } from "react-toastify";
import { Button } from "../button/Button";
import { isValidPhoneNumber } from "../../util/isValidPhoneNumber";
import { AxiosError } from "axios";

export function ClientForm({
  onSubmit,
}: {
  onSubmit: (clientData: ClientData) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  return (
    <form
      className={"client-form"}
      onSubmit={async (e) => {
        e.preventDefault();
        if (!isValidPhoneNumber(phoneNumber)) {
          toast(t("clientForm.invalidPhoneNumber"), { type: "error" });
          return;
        }

        const toastId = t("clientForm.termsNotAccepted");
        if (!termsAccepted) {
          toast(toastId, { type: "error", toastId: toastId });
          return;
        }

        toast.dismiss(toastId);
        try {
          await onSubmit({ firstName, lastName, email, phoneNumber });
        } catch (err) {
          const errorMsg =
            err instanceof AxiosError && err.response?.status === 409
              ? t("property.cannotRegisterSelf")
              : t("property.registerError");
          toast(errorMsg, { type: "error" });
        }
      }}
    >
      <div className={"row flex-wrap"}>
        <Input
          className={"input-field"}
          value={firstName}
          onChange={setFirstName}
          placeholder={t("clientForm.firstName")}
          required={true}
          type={"text"}
        />
        <Input
          className={"input-field"}
          value={lastName}
          onChange={setLastName}
          placeholder={t("clientForm.lastName")}
          required={true}
          type={"text"}
        />
      </div>
      <div className={"row flex-wrap"}>
        <Input
          className={"input-field"}
          value={email}
          onChange={setEmail}
          placeholder={t("clientForm.email")}
          required={true}
          type={"email"}
        />
        <Input
          className={"input-field"}
          value={phoneNumber}
          onChange={setPhoneNumber}
          placeholder={t("clientForm.phoneNumber")}
          required={true}
          type={"tel"}
        />
      </div>
      <div className={"flex mt-2 align-center"}>
        <input
          checked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
          type={"checkbox"}
          id={"terms-checkbox"}
        />
        <label htmlFor={"terms-checkbox"} className={"ml-1"}>
          I accept the general{" "}
          <a
            href={"/TermsAndConditions.pdf"}
            className={"link"}
            target="_blank"
          >
            terms and conditions
          </a>
        </label>
      </div>

      <Button className="ml-auto mt-4" type="submit">
        {t("clientForm.register")}
      </Button>
    </form>
  );
}
