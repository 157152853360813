import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { FilterName } from "../../enums/FilterName";
import "./MultiSelect.css";
import { RxCaretUp, RxCaretDown } from "react-icons/rx";
import { useTranslation } from "react-i18next";

export type MultiSelectItem = {
  label: string;
  value: string;
};

function SubMultiSelect({
  items,
  selectedValues,
  setSelectedValues,
}: {
  items: MultiSelectItem[];
  selectedValues: string[];
  setSelectedValues: (newSelectedValues: string[]) => void;
}) {
  function handleItemClick(item: MultiSelectItem) {
    if (selectedValues.includes(item.value)) {
      setSelectedValues(selectedValues.filter((i) => i !== item.value));
    } else {
      setSelectedValues([...selectedValues, item.value]);
    }
  }

  return (
    <div className="sub-multi-select">
      {items.map((item) => (
        <label key={item.value}>
          <input
            type="checkbox"
            className="checkbox"
            checked={selectedValues.includes(item.value)}
            onChange={() => handleItemClick(item)}
          />
          {item.label}
        </label>
      ))}
    </div>
  );
}

export function MultiSelect({
  filterName,
  subFilterName,
  filterParams,
  setFilterParams,
  items,
  subItems,
  initialSelectedValues,
}: {
  filterName: FilterName;
  subFilterName?: FilterName;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
  items: MultiSelectItem[];
  subItems?: Record<string, MultiSelectItem[]>; // for multi select with sub items
  initialSelectedValues: string[];
}) {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);
  const [selectedSubValues, setSelectedSubValues] = useState<
    Record<string, string[]>
  >({});
  const [showAll, setShowAll] = useState(false);
  const initialShowNumber = 5;

  function handleItemClick(item: MultiSelectItem) {
    if (selectedValues.includes(item.value)) {
      setSelectedValues(selectedValues.filter((i) => i !== item.value));
      setSelectedSubValues((prev) => ({
        ...prev,
        [item.value]: [],
      }));
    } else {
      setSelectedValues([...selectedValues, item.value]);
    }
  }

  useEffect(() => {
    if (selectedValues.length === 0) {
      // if no values are selected, remove the filter and subfilters
      setFilterParams((prev) => ({
        ...prev,
        [filterName]: undefined,
      }));
      return;
    }

    setFilterParams((prev) => ({
      ...prev,
      [filterName]: selectedValues.join(","),
    }));
  }, [selectedValues]);

  useEffect(() => {
    if (subFilterName) {
      // remove filter if no sub values are selected
      for (const [key, value] of Object.entries(selectedSubValues)) {
        if (value.length === 0) {
          delete selectedSubValues[key];
        }
      }

      if (Object.values(selectedSubValues).flat().length === 0) {
        setFilterParams((prev) => ({
          ...prev,
          [subFilterName]: undefined,
        }));
        return;
      }

      setFilterParams((prev) => ({
        ...prev,
        [subFilterName]: Object.values(selectedSubValues)
          .reduce((acc, values) => [...acc, ...values], [])
          .join(","),
      }));
    }
  }, [selectedSubValues]);

  useEffect(() => {
    const newSelectedValues = filterParams[filterName]?.split(",") || [];
    if (newSelectedValues.join(",") === selectedValues.join(",")) {
      // prevent infinite loop
      return;
    }

    setSelectedValues(newSelectedValues);

    if (subFilterName) {
      // if sub filter is not present, set all sub values to empty array
      if (!filterParams[subFilterName]) {
        for (const [key, value] of Object.entries(selectedSubValues)) {
          setSelectedSubValues((prev) => ({
            ...prev,
            [key]: [],
          }));
        }
        return;
      }

      const newSelectedValues = filterParams[subFilterName]?.split(",") || [];
      setSelectedSubValues((prev) => ({
        ...prev,
        [subFilterName]: newSelectedValues,
      }));
    }
  }, [filterParams]);

  return (
    <div className="multi-select">
      {items.slice(0, initialShowNumber).map((item) => (
        <div key={item.value}>
          <label>
            <input
              type="checkbox"
              className="checkbox"
              checked={selectedValues.includes(item.value)}
              onChange={() => handleItemClick(item)}
            />
            {item.label}
          </label>
          {subItems &&
          subItems[item.value] &&
          selectedValues.includes(item.value)
            ? SubMultiSelect({
                items: subItems[item.value],
                selectedValues: selectedSubValues[item.value] || [],
                setSelectedValues: (newSelectedValues) => {
                  setSelectedSubValues((prev) => ({
                    ...prev,
                    [item.value]: newSelectedValues,
                  }));
                },
              })
            : null}
        </div>
      ))}
      {items.length > initialShowNumber && (
        <>
          <div
            className={"show-button"}
            onClick={() => setShowAll((prevState) => !prevState)}
          >
            {showAll ? <RxCaretUp /> : <RxCaretDown />}
            {showAll ? t("filters.showLess") : t("filters.showMore")}
          </div>
          {showAll
            ? items.slice(initialShowNumber).map((item) => (
                <div key={item.value}>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectedValues.includes(item.value)}
                      onChange={() => handleItemClick(item)}
                    />
                    {item.label}
                  </label>
                  {subItems &&
                  subItems[item.value] &&
                  selectedValues.includes(item.value)
                    ? SubMultiSelect({
                        items: subItems[item.value],
                        selectedValues: selectedSubValues[item.value] || [],
                        setSelectedValues: (newSelectedValues) => {
                          setSelectedSubValues((prev) => ({
                            ...prev,
                            [item.value]: newSelectedValues,
                          }));
                        },
                      })
                    : null}
                </div>
                // sub items
              ))
            : null}
        </>
      )}
    </div>
  );
}
