import React, { HTMLInputTypeAttribute, useRef } from "react";
import "./Input.css";
import hidePassword from "../../assets/hidePassword.svg";
import showPassword from "../../assets/showPassword.svg";
import { Tooltip } from "../tooltip/Tooltip";

export function Input({
  className,
  value,
  onChange,
  placeholder,
  required,
  type,
  image,
  alt,
  disabled,
  showPasswordIcon = false,
  isRevealedPassword,
  setIsRevealedPassword,
  minLength,
  maxLength,
  min,
  max,
  readOnly,
  step,
  showAsterisk,
  children,
  tooltip = false,
  noPadding,
  onBlur,
}: {
  className?: string;
  value: string;
  onChange: (v: string) => void;
  placeholder: string;
  required: boolean;
  type: HTMLInputTypeAttribute;
  image?: string;
  alt?: string;
  disabled?: boolean;
  showPasswordIcon?: boolean;
  isRevealedPassword?: boolean;
  setIsRevealedPassword?: (v: boolean) => void;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  readOnly?: boolean;
  step?: number;
  showAsterisk?: boolean;
  children?: React.ReactNode; // for tooltip content
  tooltip?: boolean;
  noPadding?: boolean;
  onBlur?: () => void;
}) {
  const ref = useRef<HTMLInputElement>(null);

  const tooltipContent = children; // rename for clarity

  return (
    <div
      className={`input-with-img ${className ?? ""} ${
        tooltip ? "tooltip-on-focus" : ""
      } ${noPadding ? "no-padding" : ""}`}
    >
      {image ? <img className="input-img" src={image} alt={alt} /> : null}
      <input
        ref={ref}
        className={`form-field ${readOnly ? "readonly" : ""} ${
          className ? className : ""
        } ${image ? "image" : ""}`}
        placeholder={placeholder}
        type={type}
        value={value}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        readOnly={readOnly}
        step={step}
        onBlur={onBlur}
      />
      {showPasswordIcon ? (
        <img
          src={isRevealedPassword ? showPassword : hidePassword}
          className={"input-eye pointer"}
          alt={"show or hide password icon"}
          onClick={() => {
            if (!setIsRevealedPassword) {
              return;
            }

            setIsRevealedPassword(!isRevealedPassword);
          }}
        />
      ) : null}
      <div className="label-container">
        <label
          className={`label ${image ? "with-img" : ""}`}
          onClick={() => {
            if (ref.current) {
              ref.current.focus();
            }
          }}
        >
          {placeholder} {showAsterisk && required ? "*" : ""}
        </label>
        {tooltipContent ? (
          <Tooltip className="input-tooltip">{tooltipContent}</Tooltip>
        ) : null}
      </div>
    </div>
  );
}
