function getConfigVar(key: string): string {
  const val = process.env[key];
  if (val === undefined) {
    throw new Error(`The variable with name ${key} is not set in .env.`);
  }
  return val;
}

export class Config {
  static getBackendUrl(): string {
    return getConfigVar("REACT_APP_BACKEND_URL");
  }

  static getSentryDsn(): string {
    return getConfigVar("REACT_APP_SENTRY_DSN");
  }

  static isDebug(): boolean {
    return getConfigVar("REACT_APP_DEBUG") === "true";
  }

  static getStripePublicKey(): string {
    return getConfigVar("REACT_APP_STRIPE_PUBLIC_KEY");
  }

  static getDefaultPageSize(): number {
    return parseInt(getConfigVar("REACT_APP_DEFAULT_PAGE_SIZE"));
  }
}
