export const regions: { [key: string]: string[] } = {
  "Marbella - Malaga": [
    "Atrium",
    "Casco antiguo",
    "Cañada Homes",
    "Cipreses del Mar",
    "Divina Pastora",
    "Don Gonzalo",
    "Don Miguel",
    "El Higueral",
    "El Mirador",
    "El Mirador de la Cañada",
    "El Pinillo",
    "Gran Marbella",
    "Huerta Belón",
    "Huerta del Prado",
    "La Cantera",
    "La Merced",
    "La Mina",
    "La Montua",
    "La patera",
    "Las Cancelas",
    "Los patios",
    "Marbella Centro",
    "Marbella House",
    "Marbesun",
    "Mare Nostrum",
    "Marina Marbella",
    "Miraflores",
    "Pavona Real",
    "Playa Bajadilla - Puertos",
    "Playa de la Fontanilla",
    "Plaza de toros - La Ermita",
    "Ricardo Soriano",
    "San Antonio",
    "San Miguel",
    "Sierramar",
    "Torrecilla - La Cañada",
    "Valdeolletas",
    "Xarblanca",
  ],
  "Marbella - Puerto Banus": [
    "Alcazaba",
    "Alcazaba Gardens",
    "Alcazaba Rio Verde",
    "Andalucia del Mar",
    "Azalea Beach",
    "Bahia de Banus",
    "Benabola",
    "Casa Nova",
    "El Embrujo",
    "El Embrujo Banús",
    "El Embrujo Marbella",
    "El Embrujo Playa",
    "Fuentes del Rodeo",
    "Gray D'Albion",
    "Guadalpin Banus",
    "Jardines de Ventura del Mar",
    "Jardines de la Aldaba",
    "Jardines de las Fuentes",
    "Jardines del Puerto",
    "La Herradura",
    "La Isla",
    "La Pepina",
    "Laguna de Banus",
    "Las Gaviotas",
    "Las Mimosas",
    "Lorea Playa",
    "Los Granados",
    "Malibu",
    "Marina Banus",
    "Medina Gardens",
    "Playa Rocio",
    "Playas del Duque",
    "Plaza Banús",
    "Privilegio de Marbella",
    "Puerto",
    "Tembo",
    "Tembo Banus",
    "Terrazas de Banus",
    "Ventura del Mar",
    "Villa Marina",
  ],
  "Marbella East": [
    "Alborada",
    "Alicate Playa",
    "Altamira",
    "Altos de Elviria",
    "Andasol",
    "Arenas de Bahia Marbella",
    "Artola",
    "Atrium Bahia Marbella",
    "Bahia Real",
    "Bahia Vista Hermosa",
    "Bahia de Marbella",
    "Bello Horizonte",
    "Birdie Club",
    "Bosque mar",
    "Cabo Royale",
    "Cabopino",
    "Carib Playa",
    "Cerrado de Elviria",
    "Club del Casco",
    "Colorado Hills",
    "Costabella",
    "Cumbres de Elviria",
    "Don Carlos",
    "Dunas de Bahia Marbella",
    "Dunes Beach",
    "Eden Hill",
    "El Manantial de Santa Maria Golf",
    "El Real Panorama",
    "El Rosario",
    "El Soto de Marbella",
    "Elements",
    "Elviria",
    "Elviria Hills",
    "Elviria Playa",
    "Estrella del Mar",
    "Funny Beach",
    "Golden Beach",
    "Golf Gardens",
    "Gran Bahia",
    "Green Life Village",
    "Hacienda Cortez",
    "Hacienda Elviria",
    "Hacienda Playa",
    "Hacienda San Manuel",
    "Hacienda las Chapas",
    "Higueral de Artola",
    "Jardines de Don Carlos",
    "Jardines de Santa Maria Golf",
    "Jardines de las Golondrinas",
    "La Finca de Marbella",
    "La Florida",
    "La Mairena",
    "La Montesa de Marbella",
    "La Morera",
    "La Reserva de Marbella",
    "La Reserva de los Monteros",
    "Las Carmenes",
    "Las Chapas",
    "Las Lomas de Pozuelo",
    "Las Lomas de Rio Real",
    "Las Palmas",
    "Las Terrazas de Santa Maria Golf",
    "Lindasol",
    "Lomas de los Monteros",
    "Los Altos de Marbella",
    "Los Altos de los Monteros",
    "Los Chopitos",
    "Los Granados de Cabopino",
    "Los Molineros",
    "Los Monteros",
    "Los Monteros Hill Club",
    "Los Monteros Palm Beach",
    "Los Monteros Playa",
    "Los Patios de Santa Maria Golf",
    "Los Pinos",
    "Marbella Golf",
    "Marbellita",
    "Marbesa",
    "Orion",
    "Panorama D",
    "Park Club Suites",
    "Parque Elviria",
    "Pinomar",
    "Playa Real",
    "Pueblo Mediterraneo",
    "Pueblo los Arcos",
    "Puerto de Cabopino",
    "Rancho Park",
    "Rancho Wellington",
    "Real de Zaragoza",
    "Ricmar",
    "Rio Real",
    "Rio Real Golf",
    "Riva Residences",
    "Romana Playa",
    "Samara",
    "Samisol",
    "Santa Clara",
    "Santa Maria",
    "Santa Maria Golf",
    "The Cape",
    "The Retreat",
    "Torre Real",
    "Vista Golf",
    "White Pearl Beach",
  ],
  "Marbella Golden Mile": [
    "Al Khaldiah",
    "Alhambra del Mar",
    "Altos Reales",
    "Altos de Puente Romano",
    "Altos de Salamanca",
    "Ancon Playa",
    "Ancon Sierra",
    "Ancon Sierra IV",
    "Arco Iris",
    "Arroyo de las Piedras",
    "Balcones de Puente Romano",
    "Balcones de Sierra Blanca",
    "Beach Side Golden Mile",
    "Casablanca",
    "Cascada de Camojan",
    "Club Sierra",
    "Concept",
    "Condado de Sierra Blanca",
    "Coral Beach",
    "Corona de Nagüeles",
    "Cortijo Nagüeles",
    "Costa Nagüeles I",
    "Costa Nagüeles II",
    "Costa Nagüeles III",
    "Coto Real",
    "Coto Real II",
    "El Alfar",
    "El Batatal",
    "El Capricho",
    "El Infantado",
    "El Mirador del Principe",
    "El Oasis Club",
    "El Portalon",
    "El Retiro de Nagüeles",
    "El Tomillar de Nagüeles",
    "El Venero",
    "El Vicario",
    "El Virrey de Nagüeles",
    "Epic Marbella",
    "Gala Placidia",
    "Golden Mile",
    "Gran Ducado",
    "Guadalpin Suites",
    "Hacienda Nagüeles I",
    "Hacienda Nagüeles II",
    "Hotel Puente Romano",
    "Imara",
    "Jardines Colgantes",
    "Jardines de Doña Maria",
    "Jardines de Sierra Blanca",
    "Jardines de las Lomas",
    "Jardines del Mar",
    "Jardines del Principe",
    "Jardín del Mediterráneo",
    "Kings Hills",
    "La Capellania",
    "La Caridad",
    "La Carolina",
    "La Coneja",
    "La Gitana",
    "La Joya",
    "La Meridiana",
    "La Quinta de Sierra Blanca",
    "La Quinta del Virrey",
    "La Reserva de los Granados",
    "La Trinidad",
    "La Virginia",
    "Lagos de Sierra Blanca",
    "Las Cascadas",
    "Las Cañas",
    "Las Cañas Beach",
    "Las Lomas de Marbella",
    "Las Lomas del Marbella Club",
    "Las Mariposas",
    "Las Terrazas",
    "Las Torres",
    "Le Blanc",
    "Llanos de Nagueles",
    "Lomas Pueblo",
    "Lomas de Magna Marbella",
    "Lomas de Puente Romano",
    "Lomas de Sierra Blanca",
    "Lomas del Rey",
    "Lomas del Virrey",
    "Los Picos",
    "Los Picos de Nagüeles",
    "Los Pinos de Nagüeles",
    "Los Verdiales",
    "Manchones Nagüeles",
    "Mansion Club",
    "Marbelah Pueblo",
    "Marbella Club",
    "Marbella Hill Club",
    "Marbella Hill Village",
    "Marbella Hills",
    "Marbella Montaña",
    "Marbella Real",
    "Marbella Senses",
    "Marbellamar",
    "Marberia",
    "Marina Mariola",
    "Marina de Puente Romano",
    "Meisho Hills",
    "Montaña Marbella Club",
    "Monte Marbella Club",
    "Monte Paraiso",
    "Monte Paraiso Country Club",
    "Montepiedra",
    "Nagüeles",
    "Nazules",
    "Oasis de Banús",
    "Oasis de Marbella",
    "Oasis de Nagüeles",
    "Playa Esmeralda",
    "Port Oasis",
    "Puente Romano",
    "Puente Romano II",
    "Reserva de Sierra Blanca",
    "Residencial Palacio de Congresos",
    "Rio Verde",
    "Rio Verde Playa",
    "Rio verde alto",
    "Rocamar",
    "Rocio de Nagüeles",
    "San Patricio",
    "Santa Filomena",
    "Santa Margarita",
    "Santa Petronila",
    "Selma",
    "Señorio de Marbella",
    "Sierra Blanca",
    "Sierra Blanca del Mar",
    "Single Homes Nagüeles",
    "Solana de Nagüeles",
    "Terrazas de Coto Real",
    "Terrazas de Las Lomas",
    "Terrazas de Puente Romano",
    "The Collection",
    "The World Marbella",
    "Vilas 12",
    "Villa Magna",
    "Villas de Vasari",
    "Villas del Marqués",
    "Vistas Marinas",
  ],
  "Nueva Andalucia": [
    "9 Lions Residences",
    "Agrupadas",
    "Albatross Hill",
    "Alcores del Golf",
    "Aldea Blanca",
    "Aldea Dorada",
    "Alminar de Marbella",
    "Aloha",
    "Aloha Gardens",
    "Aloha Hill Club",
    "Aloha Lake Village",
    "Aloha Mirasierra",
    "Aloha Park",
    "Aloha Pueblo",
    "Aloha Royal",
    "Aloha Sur 21",
    "Aloha Sur 32",
    "Altos de Aloha",
    "Altos del Rodeo",
    "Amapura Villas",
    "Andalucia Alta",
    "Andalucia Garden Club",
    "Andalucia Garden Suites",
    "Atalaya de Rio Verde",
    "Azahar de Marbella",
    "Azahara I",
    "Azahara II",
    "Balcón del Golf",
    "Bellevue",
    "Brisas del Sur",
    "Casas Jardín",
    "Celeste Marbella",
    "Centro Plaza",
    "Cerro Blanco",
    "Condes de Iza",
    "Conjunto Casaño",
    "Cortijo Los Naranjos",
    "Country Club Las Brisas",
    "Cumbres del Rodeo",
    "El Alcornoque",
    "El Colorado",
    "El Dorado",
    "El Naranjal",
    "El Palmeral",
    "Embalse Las Tortugas",
    "Finca La Judía",
    "Fuente Aloha",
    "Guadaiza",
    "Hacienda El palmeral",
    "Haza del Conde",
    "Hotel del Golf",
    "Ivy Residence",
    "Jardines de Andalucia",
    "La Alzambra",
    "La Alzambra Hill Club",
    "La Biznaga",
    "La Campana",
    "La Cerquilla",
    "La Colina",
    "La Corniche",
    "La Dama de Noche",
    "La Maestranza",
    "La Morelia de Marbella",
    "La Pera",
    "La Quinta Village",
    "La Rinconada",
    "Lagomar",
    "Las Alamandas",
    "Las Brisas",
    "Las Brisas del Golf",
    "Las Cascadas",
    "Las Cocas",
    "Las Lolas",
    "Las Lomas de Nueva Andalucia",
    "Las Pérgolas",
    "Las Tortugas de Aloha",
    "Last Green",
    "Le Village",
    "Les Belvederes",
    "Lomas del Colorado",
    "Lorcrigolf",
    "Lorcrimar",
    "Los Algarrobos",
    "Los Arrayanes",
    "Los Dragos",
    "Los Granados Golf",
    "Los Jarales",
    "Los Naranjos",
    "Los Naranjos Country Club",
    "Los Naranjos Golf",
    "Los Naranjos Hill Club",
    "Los Naranjos Hills",
    "Los Naranjos de Marbella",
    "Los Olivos",
    "Los Palacetes de Banús",
    "Los Pinos de Aloha",
    "Los Potros",
    "Los Toreros",
    "Magna",
    "Malambo",
    "Marbella Country Club",
    "Medina de Banús",
    "Miragolf",
    "Nueva Peñablanca",
    "Palacetes Los Belvederes",
    "Parcelas del Golf",
    "Peñablanca",
    "Pueblo Arabesque",
    "Pueblo de Las Brisas",
    "Puerto Golf",
    "Reserva del Conde",
    "River Garden",
    "Rodeo Alto",
    "Royal Banús",
    "Royal Gardens",
    "San Javier",
    "Señorio de Aloha",
    "Señorio de Gonzaga",
    "Sierra Park",
    "Sol y Paz",
    "Soleuropa",
    "Supermanzana H",
    "Terrazas del Rodeo",
    "Torre de Andalucia",
    "Torres de Aloha",
    "Vista Real",
    "Zona Casino",
  ],
  "Benahavis - Malaga": [
    "Abantos Hills",
    "Acosta los Flamingos",
    "Alborada Homes",
    "Altos de La Quinta",
    "Altos del Paraiso",
    "Aqualina",
    "Atalaya Fairways",
    "Atalaya Hills",
    "Be Lagom",
    "Benahavis Centro",
    "Benahavis Hills Country Club",
    "Botanic",
    "Buenavista",
    "Byu Hills",
    "Capanes Sur",
    "Ctra. De Ronda",
    "Cumbres de Los Almendros",
    "Eagles Village",
    "El Almendro",
    "El Bosque",
    "El Casar",
    "El Herrojo",
    "El Madroñal",
    "Four Seasons",
    "Fuente del Espanto",
    "Fusionvista",
    "Gazules del Sol",
    "Hacienda del Señorio de Cifuentes",
    "Hoyo 19",
    "Infinity (Mirador del Paraíso)",
    "Jardines de Albaicín",
    "La Alqueria",
    "La Finca de Jasmine",
    "La Heredia",
    "La Quinta",
    "La Quinta Golf",
    "La Quinta Hills",
    "La Quinta Suites",
    "La Reserva de Alcuzcuz",
    "La Reserva de la Quinta",
    "La Zagaleta",
    "Las Colinas de Marbella",
    "Las Colinas de la Heredia",
    "Las Encinas",
    "Las Jacarandas",
    "Las lomas del Conde Luque",
    "Lomas de Guadalmina",
    "Lomas de La Quinta",
    "Lomas del Marqués",
    "Los Almendros",
    "Los Arcos (La Quinta)",
    "Los Arqueros",
    "Los Arrayanes Golf",
    "Los Capanes del Golf",
    "Los Flamingos",
    "Los Flamingos Golf",
    "Los Flamingos Tee6",
    "Los Flamingos Views",
    "Los Jaralillos",
    "Marbella Club Golf Resort",
    "Marbella Club Hills",
    "Mirabella Hills",
    "Mirador del Paraiso",
    "Mirador en Moraleda",
    "Monte Halcones",
    "Monte Mayor",
    "Montevideo Hills",
    "Ocean Pines",
    "Paraiso Alto",
    "Paraiso Park",
    "Paraiso Pueblo",
    "Paraíso Bellevue",
    "Puerto del Almendro",
    "Puerto del Capitan",
    "Real de La Quinta",
    "Riverside",
    "Royal Flamingos",
    "Soto de la Quinta",
    "Tee5",
    "Terrazas de la Quinta",
    "The Hills",
    "The View Marbella",
    "Valley Heights",
    "Vega del Colorado",
  ],
  "Estepona - Malaga": [
    "Altos de Estepona",
    "Estepona East",
    "Estepona Town",
    "Estepona West",
    "Seghers",
  ],
  "San Pedro de Alcantara - Malaga": [
    "Alta Vista",
    "Bahia Alcantara",
    "Breeze",
    "Campos de Guadalmina",
    "Casablanca Beach",
    "Castiglione",
    "Cortijo Blanco",
    "Ctra. Ronda",
    "El Arquillo",
    "El Capitan",
    "El Gamonal",
    "El Mirador",
    "Guadalcantara",
    "Guadalmina Alta",
    "Guadalmina Baja",
    "Guadalvillas",
    "Hoyo 15",
    "Isla de Guadalmina",
    "Jade Beach",
    "Las Adelfas",
    "Las Arenas",
    "Las Bovedas",
    "Las Petunias",
    "Linda Vista Alta",
    "Linda Vista Baja",
    "Lorcrisur",
    "Los Almendros I",
    "Los Almendros II",
    "Los Angeles",
    "Los Arqueros Beach",
    "Los Jazmines",
    "Los Naranjos",
    "Nueva Alcantara",
    "Parque del Sol",
    "Perlas del Mar",
    "S. Pedro Centro",
    "San Pedro Playa",
    "Valle del Sol",
  ],
  "Sotogrande - Cadiz": [
    "La Reserva",
    "Marina de Sotogrande",
    "San Diego",
    "Sotogrande Alto",
    "Sotogrande Costa",
    "Sotogrande Puerto Deportivo",
    "Torreguadiaro",
  ],
  "Ojen - Malaga": ["Ojen Centro", "Palo Alto"],
  "Mijas Costa - Malaga": [
    "Altos de Calahonda",
    "Balcones del Chaparral",
    "Buena Vista",
    "Butiplaya",
    "Calahonda",
    "Cala de Mijas",
    "Calahonda Playa",
    "Calanova Golf",
    "Calipso",
    "Cascadas de las Lomas",
    "Cerros del Aguila",
    "Doña Lola",
    "El Chaparral",
    "El Coto",
    "El Faro",
    "El Faro de Calaburras",
    "Entrerrios",
    "La Cala Golf Resort",
    "La Cala Hills",
    "La Cortijera",
    "La Farola",
    "La Ponderosa",
    "Las Farolas",
    "Las Lagunas",
    "Las Mimosas",
    "Los Claveles",
    "Los Pinos",
    "Marina del Sol",
    "Mijas Golf",
    "Miraflores",
    "Miraflores Golf",
    "Playamarina",
    "Pueblo Mijitas",
    "Rincon del hinojal",
    "Riviera del Sol",
    "Sierrezuela",
    "Sitio de Calahonda",
    "Torrenueva",
    "Vista Verde",
  ],
  "Istan - Malaga": [
    "Balcones del Lago",
    "Carretera de Istan",
    "Cerros del Lago",
    "Sierra Blanca Country Club",
    "Zahara de Istan",
  ],
  "Casares - Malaga": [
    "Alcazaba Lagoon",
    "Altos de Cortesín",
    "Bahia de Casares",
    "Camarate Golf",
    "Casares Golf",
    "Casares Montaña",
    "Casares Playa",
    "Casares del Mar",
    "Casares del Sol",
    "Doña Julia",
    "Finca Cortesin",
    "La Acedia",
    "La Perla de la Bahía",
    "Las Terrazas de Cortesín",
    "Majestic",
    "Marina de Casares",
    "Pueblo",
    "Secadero",
    "Terrazas de la Bahía",
  ],
  "Mijas - Malaga": [
    "Campo Mijas",
    "Carretera de Mijas - Alta",
    "Carretera de Mijas - Baja",
    "Cerrado del Aguila",
    "Doña Pilar",
    "El Hornillo",
    "El Porton de Mijas",
    "Finca San Antonio",
    "Huerta Alta",
    "La Alqueria",
    "La Loma",
    "La Noria",
    "Las Lomas de Mijas",
    "Loma del Flamenco",
    "Los Espartales",
    "Mijas La Nueva",
    "Mijas Pueblo",
    "Osunilla",
    "Rancho de la Luz",
    "Valtocado",
  ],
  "Manilva - Malaga": [
    "Alcorrín",
    "Bahia de las Rocas",
    "Castillo de la Duquesa",
    "Chullera",
    "Duquesa Suites",
    "Duquesa Village",
    "Guadalupe Hills",
    "Hacienda Guadalupe",
    "Jardin Tropical",
    "La Duquesa",
    "La Duquesa Golf",
    "La Noria III",
    "La Noria IV",
    "La Paloma",
    "Los Granados de Duquesa",
    "Los Hidalgos",
    "Magna Manilva",
    "Manilva Beach",
    "Manilva Pueblo",
    "Marina Castillo",
    "Marina Duquesa",
    "Marina Real",
    "Martagina",
    "Playa Paraiso",
    "Postal de la Paloma",
    "Princesa Kristina",
    "Pueblo Mexicano",
    "Puerto La Duquesa",
    "Sabinillas",
    "Sunrise Height",
  ],
  "Benalmadena - Malaga": [
    "Arroyo de la Miel",
    "Benalmadena Centro",
    "Benalmadena Costa",
    "Benalmadena Pueblo",
    "Hacienda San Fernando",
    "La Capellania",
    "La Perla",
    "Montealto",
    "Nueva Torrequebrada",
    "Puerto Marina",
    "Rancho Domingo",
    "Reserva del Higuerón",
    "Santangelo",
    "Torremuelle",
    "Torrequebrada",
  ],
  "Alcaidesa - Cadiz": ["Alcaidesa Alta", "Alcaidesa Costa", "Alcaidesa Golf"],
  "Fuengirola - Malaga": [
    "Carvajal",
    "El Castillo",
    "El Higueron",
    "Fuengirola Centro",
    "Fuengirola Puerto",
    "Los Boliches",
    "Los Pacos",
    "Torreblanca",
  ],
  "Alicante - Alicante": [
    "Albufereta",
    "Alicante Centro",
    "Cabo de las huertas",
    "El Palmeral",
    "Explanada de España",
    "Las Vegas",
    "Moralet",
    "Playa de San Juan",
    "San Rafael",
    "Vistahermosa",
  ],
  "Algeciras - Cadiz": [
    "Bajadilla - Fuente Nueva",
    "Casco Antiguo",
    "El Rinconcillo",
    "La Granja - La Colina - Los Pastores",
    "La Reconquista - El Rosario",
    "Pescadores - Saladillo",
    "San García",
  ],
  "La Linea de la Concepcion - Cadiz": ["Santa Margarita"],
  "Malaga - Malaga": [
    "Malaga - Bailén-Miraflores",
    "Campanillas",
    "Malaga - Carretera de Cádiz",
    "Malaga - Churriana",
    "Malaga - Ciudad Jardín",
    "Malaga - Cruz de Humilladero",
    "Malaga - Este",
    "Malaga - Martiricos-La Roca",
    "Puerto de la Torre",
    "Malaga - Teatinos",
  ],
  "Ronda - Malaga": ["Ronda Centro"],
  "San Roque - Cadiz": [
    "Emerald Greens",
    "Hoyo 17",
    "Puente Mayorga",
    "San Roque Club",
    "San Roque Golf",
  ],
  "Sevilla - Seville": [
    "Bellavista",
    "Centre",
    "Cerro Amate",
    "East",
    "Jardines de Hércules",
    "La Palmera - Los Bermejales",
    "Los Remedios",
    "Macarena",
    "Nervion",
    "Parque Alcosa",
    "Pino Montano",
    "Prado de San Sebastian - Felipe II",
    "San Jeronimo",
    "San Pablo",
    "Santa Clara",
    "Santa Justa - Miraflores - Cruz Roja",
    "Torreblanca",
    "Triana",
    "Valdezorras - El Gordillo",
  ],
};
