import { Tier } from "../api/AgencyApi";

export enum UserType {
  ADMIN = "ADMIN",
  AGENCY_OWNER = "AGENCY_OWNER",
  AGENT = "AGENT",
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  agencyId: string;
  agencyName: string;
  phoneNumber: string | null;
  nationality: string;
  type: UserType;
  tier?: Tier;
  createdAt: string;
}
