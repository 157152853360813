import "./PropertyAgent.css";
import { PropertyItem } from "../../api/PropertyTypes";
import { dateToReadableFormat } from "../../util/dateToReadableFormat";

export function PropertyAgent({ property }: { property: PropertyItem }) {
  return (
    <div className={"agent-info"}>
      <dl>
        <dt>Name:</dt>
        <dd>
          {property.agent.firstName} {property.agent.lastName}
        </dd>
        <dt>Agency:</dt>
        <dd>{property.agency.name}</dd>
        <dt>Email:</dt>
        <dd>
          <a href={`mailto:${property.agent.email}`}>{property.agent.email}</a>
        </dd>
        <dt>Phone number:</dt>
        <dd>
          <a href={`tel:${property.agent.phoneNumber}`}>
            {property.agent.phoneNumber}
          </a>
        </dd>
        <dt>Listed date:</dt>
        <dd>{dateToReadableFormat(new Date(property.createdAt), true)}</dd>
      </dl>
    </div>
  );
}
