import { useRef } from "react";
import "./FileUpload.css";
import { useTranslation } from "react-i18next";
import { Button } from "../button/Button";

export function FileUpload({
  label,
  required,
  setFiles,
  accept,
  multiple,
}: {
  label: string;
  required: boolean;
  setFiles: (value: File[]) => void;
  accept: string;
  multiple: boolean;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <div className={"upload-group"}>
      <Button
        className="upload-button"
        onClick={() => ref.current?.click()}
        color="secondary"
        type={"button"}
      >
        {t("fileUpload.chooseFiles")}
      </Button>
      <input
        ref={ref}
        id="asset-upload"
        className="mt-2"
        type="file"
        accept={accept}
        onChange={(e) => {
          if (e.target.files) {
            const files: File[] = [];
            for (let i = 0; i < e.target.files.length; i++) {
              const file = e.target.files.item(i);
              if (file) {
                files.push(file);
              }
            }
            setFiles(files);
          }
        }}
        multiple={multiple}
      />
      <label
        className={"label"}
        onClick={() => {
          if (ref.current) {
            ref.current.focus();
          }
        }}
      >
        {label} {required ? "*" : ""}
      </label>
    </div>
  );
}
