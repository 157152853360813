import { Language } from "./LanguageApi";
import { Garage, Garden, Orientation, Pool } from "../enums/PropertyEnums";
import { Agency } from "./AgencyApi";
import { FilterName } from "../enums/FilterName";
import { User } from "../interfaces/UserTypes";

export type PropertyItem = {
  id: string;
  descriptions: { id: string; description: string; language: Language }[];
  price: number;
  commission: number;
  commissionSplit: number;
  address: Address;
  status: PropertyStatus;
  region: string;
  subregion: string;
  generalType: string;
  subType: string;
  bedrooms?: number;
  bathrooms?: number;
  builtSurface?: number;
  interiorSurface?: number;
  terraceSurface?: number;
  totalSurface?: number;
  floor?: number;
  completionYear?: number;
  toilets?: number;
  numberOfFloors?: number;
  pool: Pool;
  garden: Garden;
  garage: Garage;
  orientation: Orientation;
  parkingSpaces?: number;
  communityFees?: number;
  IBI?: number;
  garbageTax?: number;
  agencyId: string;
  agency: Agency;
  pictureUrls: string[];
  documents: Document[];
  createdAt: string;
  updatedAt: string;
  agent: User;
  viewCount: number;
  googleMapsUrl?: string;
  reference: string;
  numberOfRegistrations: number;
};

export type PropertyData = {
  description: string;
  price: number;
  commission: number;
  commissionSplit: number;
  street: string;
  number: string;
  city: string;
  countryCode: string;
  status: PropertyStatus;
  region: string;
  subregion: string;
  propertyGeneralType: string;
  propertySubType: string;
  bedrooms?: number;
  bathrooms?: number;
  builtSurface?: number;
  interiorSurface?: number;
  terraceSurface?: number;
  totalSurface?: number;
  floor?: number;
  completionYear?: number;
  toilets?: number;
  numberOfFloors?: number;
  pool: Pool;
  garden: Garden;
  garage: Garage;
  orientation: Orientation;
  parkingSpaces?: number;
  communityFees?: number;
  IBI?: number;
  garbageTax?: number;
  reference?: string;
  pictureUrls: string[];
  documents: Document[];
  googleMapsUrl?: string;
  agentId: string;
};

export type PropertyStatistics = {
  numberOfProperties: number;
  numberOfSoldProperties: number;
  numberOfRegisteredClients: number;
};

export enum PropertyStatus {
  AVAILABLE = "AVAILABLE",
  SOLD_VISIBLE = "SOLD_VISIBLE",
  SOLD_ARCHIVED = "SOLD_ARCHIVED",
  DRAFT = "DRAFT",
  ARCHIVED = "ARCHIVED",
}

export enum DocumentType {
  FLOOR_PLAN = "FLOOR_PLAN",
  LAND_REGISTRY_EXTRACT = "LAND_REGISTRY_EXTRACT",
  ENERGY_CERTIFICATION = "ENERGY_CERTIFICATION",
}

export type Document = {
  url: string;
  type: DocumentType;
};

export type Address = {
  id: string;
  street: string;
  number: string;
  city: string;
  country: Country;
};

export type Country = {
  id: string;
  name: string;
  code: string;
};

export type PropertyFilterParams = Record<FilterName, string | undefined>;
