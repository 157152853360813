export type StrongPassword = {
  ok: boolean;
  length: boolean;
  digits: boolean;
  lowercase: boolean;
  uppercase: boolean;
  specialChars: boolean;
};

export function isStrongPassword(password: string): StrongPassword {
  if (password.length < 8) {
    return {
      ok: false,
      length: true,
      digits: false,
      lowercase: false,
      uppercase: false,
      specialChars: false,
    };
  }

  if (!password.match(/\d+/)) {
    return {
      ok: false,
      length: false,
      digits: true,
      lowercase: false,
      uppercase: false,
      specialChars: false,
    };
  }

  if (!password.match(/[a-z]+/)) {
    return {
      ok: false,
      length: false,
      digits: false,
      lowercase: true,
      uppercase: false,
      specialChars: false,
    };
  }

  if (!password.match(/[A-Z]+/)) {
    return {
      ok: false,
      length: false,
      digits: false,
      lowercase: false,
      uppercase: true,
      specialChars: false,
    };
  }

  if (!password.match(/[^a-zA-Z0-9]+/)) {
    return {
      ok: false,
      length: false,
      digits: false,
      lowercase: false,
      uppercase: false,
      specialChars: true,
    };
  }

  return {
    ok: true,
    length: false,
    digits: false,
    lowercase: false,
    uppercase: false,
    specialChars: false,
  };
}

export function getCorrectPasswordString(item: StrongPassword) {
  if (item.length) {
    return "password.length";
  }

  if (item.digits) {
    return "password.digits";
  }

  if (item.lowercase) {
    return "password.lowercase";
  }

  if (item.uppercase) {
    return "password.uppercase";
  }

  if (item.specialChars) {
    return "password.special";
  }

  return "password.default";
}
