import ApiInstance from "./ApiInstance";
import { PropertyFilterParams } from "./PropertyTypes";
import { filterVersions } from "../constants/filterVersions";

export type FilterCombination = {
  id: string;
  name: string;
  userId: string;
  filterParams: PropertyFilterParams;
  version: (typeof filterVersions)[number];
};

type NewFilterCombination = {
  name: string;
  filterParams: PropertyFilterParams;
  version: (typeof filterVersions)[number]; // convert array of string to union type
};

class FilterCombinationApi {
  async getFilterCombinations(): Promise<FilterCombination[]> {
    const result = await ApiInstance.getApi().get("/filter-combinations");
    return result.data;
  }

  async createFilterCombination(
    filterCombination: NewFilterCombination
  ): Promise<FilterCombination> {
    const result = await ApiInstance.getApi().post(
      "/filter-combinations",
      filterCombination
    );
    return result.data;
  }

  async deleteFilterCombination(id: string): Promise<void> {
    await ApiInstance.getApi().delete(`/filter-combinations/${id}`);
  }
}

export default new FilterCombinationApi();
