import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { FilterName } from "../../enums/FilterName";
import { Select } from "../select/Select";
import "./SingleSelect.css";
import { Simulate } from "react-dom/test-utils";
import submit = Simulate.submit;

type SelectItem = {
  value: string;
  label: string;
};

export function SingleSelect({
  filterName,
  filterParams,
  setFilterParams,
  items,
  subItems = [],
  initialSelectedValue,
  includeDefault = false,
  includeSubselect = false,
  subFilterName,
}: {
  filterName: FilterName;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
  items: SelectItem[];
  subItems?: SelectItem[];
  initialSelectedValue?: string;
  includeDefault?: boolean;
  includeSubselect?: boolean;
  subFilterName?: FilterName;
}) {
  const [selectedValue, setSelectedValue] = useState<string>(
    initialSelectedValue ?? includeDefault ? "" : items[0].value
  );
  const [subSelectedValue, setSubselectedValue] = useState<string>("");

  useEffect(() => {
    setSelectedValue(filterParams[filterName] || "");

    if (subFilterName !== undefined) {
      setSubselectedValue(filterParams[subFilterName] || "");
    }
  }, [filterParams]);

  const itemsWithDefault = [
    ...(includeDefault ? [{ value: "", label: "All" }] : []),
    ...items,
  ] as SelectItem[];

  const subItemsWithDefault = [
    ...(includeDefault ? [{ value: "", label: "All" }] : []),
    ...subItems,
  ];

  return (
    <Fragment>
      <Select
        label={null}
        className="single-select"
        value={selectedValue}
        onChange={(v) => {
          let data = { ...filterParams, [filterName]: v || undefined };
          if (subFilterName) {
            data = { ...data, [subFilterName]: undefined };
          }

          setFilterParams(data);
        }}
        options={itemsWithDefault}
      />
      {includeSubselect && selectedValue !== "" ? (
        <Select
          label={null}
          className="single-select"
          value={subSelectedValue}
          onChange={(v) => {
            if (!subFilterName) {
              return;
            }

            setFilterParams({
              ...filterParams,
              [subFilterName]: v || undefined,
            });
          }}
          options={subItemsWithDefault}
        />
      ) : null}
    </Fragment>
  );
}
