import ApiInstance from "./ApiInstance";

export type Invoice = {
  id: string;
  datetime: string;
  price: number;
  invoiceUrl: string;
  tierType: string;
};

class InvoicesApi {
  public async getInvoices(): Promise<Invoice[]> {
    const response = await ApiInstance.getApi().get(`/invoices`);
    return response.data.data;
  }
}

export default new InvoicesApi();
