import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import { ChartArea, ChartData } from "chart.js";
import "./BarGraph.css";

export type BarStats = {
  data: { propertyType: string; count: number }[];
};

export function getBarGraph(
  stats: BarStats,
  gradient: CanvasGradient | string = "#FFBA4980"
): ChartData<"bar", number[], string> {
  const datasets = [
    {
      label: "",
      data: stats.data.map((item) => item.count),
      backgroundColor: gradient,
      borderColor: gradient,
      tension: 0.4,
    },
  ];

  const labels = stats ? stats.data.map((item) => item.propertyType) : [];

  return {
    labels,
    datasets,
  };
}

export function BarGraph({ stats, title }: { stats: BarStats; title: string }) {
  const chartRef = useRef<any>(null);

  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const gradient = ctx.createLinearGradient(
      area.left,
      area.bottom,
      area.right,
      area.top
    );

    gradient.addColorStop(0, "#274642");
    gradient.addColorStop(1, "#4f6e68");

    return gradient;
  }

  const gradient = chartRef.current
    ? createGradient(chartRef.current.ctx, chartRef.current.chartArea)
    : "#274642";

  const usersGraphData = getBarGraph(stats, gradient);
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x" as const,
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x" as const,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        border: {
          display: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
        border: {
          display: true,
        },
        ticks: {
          stepSize: 1,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="graph">
      <div className={"graph-title flex items-center"}>{title}</div>
      <div className="graph-wrapper" id={"canvas-container"}>
        <Bar
          ref={chartRef}
          data={usersGraphData}
          options={options}
          style={{ maxWidth: "100%" }}
        />
      </div>
    </div>
  );
}
