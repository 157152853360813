import React from "react";
import { routes } from "../constants/routes";
import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../util/getUser";
import { UserType } from "../interfaces/UserTypes";

export function RedirectIfNoAgencySelected() {
  const location = useLocation();
  const user = getUser();

  if (
    location.pathname.startsWith(routes.dashboard) ||
    location.pathname.startsWith(routes.agencyHome) ||
    location.pathname.startsWith(routes.subscriptionSettings) ||
    location.pathname.startsWith(routes.inmobaliaSettings) ||
    location.pathname.startsWith(routes.addProperties)
  ) {
    if (user && user.type === UserType.ADMIN && user.agencyId === undefined) {
      return <Navigate to={routes.admin} />;
    }
  }

  return null;
}
