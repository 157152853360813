import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { FilterName } from "../../enums/FilterName";
import { getInitialMinAndMax } from "../../util/filters";
import "./Range.css";

export function Range({
  totalMin,
  totalMax,
  initialMin,
  initialMax,
  filterName,
  filterParams,
  setFilterParams,
}: {
  totalMin: number;
  totalMax: number;
  initialMin?: number;
  initialMax?: number;
  filterName: FilterName;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
}) {
  const { t } = useTranslation();
  const [min, setMin] = useState(initialMin);
  const [max, setMax] = useState(initialMax);

  function onMinChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value === "") {
      setMin(undefined);
      return;
    }

    setMin(Math.round(Number(value)));
  }

  function onMaxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value === "") {
      setMax(undefined);
      return;
    }

    setMax(Math.round(Number(value)));
  }

  function onMinBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      setMin(undefined);
      return;
    }

    setMin(
      Math.max(totalMin, Math.min(Number(event.target.value), max ?? totalMax))
    );
  }

  function onMaxBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      setMax(undefined);
      return;
    }

    setMax(
      Math.min(totalMax, Math.max(Number(event.target.value), min ?? totalMin))
    );
  }

  useEffect(() => {
    const minFilterValue = min === undefined ? "min" : min;
    const maxFilterValue = max === undefined ? "max" : max;
    setFilterParams((prev) => ({
      ...prev,
      [filterName]: `${minFilterValue},${maxFilterValue}`,
    }));
  }, [min, max]);

  useEffect(() => {
    const [newMin, newMax] = getInitialMinAndMax(filterParams[filterName]);

    setMin(newMin);
    setMax(newMax);
  }, [filterParams]);

  return (
    <div className="range-wrapper">
      <div className="input-wrapper">
        <label className="range-label">{t("filters.min")}</label>
        <input
          type="number"
          className="range-input"
          min={totalMin}
          max={totalMax}
          step={1}
          value={min ?? ""}
          onChange={onMinChange}
          onBlur={onMinBlur}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>
      <div className="input-wrapper">
        <label className="range-label">{t("filters.max")}</label>
        <input
          type="number"
          className="range-input"
          min={totalMin}
          max={totalMax}
          step={1}
          value={max ?? ""}
          onChange={onMaxChange}
          onBlur={onMaxBlur}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>
    </div>
  );
}
