import ApiInstance from "./ApiInstance";
import { User } from "../interfaces/UserTypes";

export type AccountData = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  nationality: string;
  password?: string;
};

class AccountApi {
  public async getAccount(): Promise<User> {
    const response = await ApiInstance.getApi().get("/account/me");
    return response.data;
  }

  public async updateAccount(accountData: AccountData): Promise<User> {
    const response = await ApiInstance.getApi().put("/account/me", accountData);
    return response.data;
  }

  public async deleteAccount(): Promise<void> {
    await ApiInstance.getApi().delete("/account/me");
  }
}

export default new AccountApi();
