import { useEffect, useState } from "react";
import RegistrationApi, {
  Registration,
  RegistrationStatus,
} from "../../api/RegistrationApi";
import PropertyApi from "../../api/PropertyApi";
import { capitalizeFirstLetter } from "../../util/capitalizeFirstLetter";
import "./PropertyClients.css";
import { getUser } from "../../util/getUser";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckMark } from "../../assets/checkmark.svg";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { PropertyItem } from "../../api/PropertyTypes";

export enum RegistrationInfo {
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  STATUS = "STATUS",
}

export function PropertyClients({ property }: { property: PropertyItem }) {
  const { t } = useTranslation();

  const [registrations, setRegistrations] = useState<Registration[]>([]);

  const headerTypeValues: RegistrationInfo[] = [
    RegistrationInfo.FIRST_NAME,
    RegistrationInfo.LAST_NAME,
    RegistrationInfo.EMAIL,
    RegistrationInfo.PHONE_NUMBER,
    RegistrationInfo.STATUS,
  ];

  useEffect(() => {
    PropertyApi.listRegistrations(property.id).then((r) => setRegistrations(r));
  }, [property]);

  const user = getUser();
  if (!user) {
    return null;
  }

  async function acceptClient(registrationId: string) {
    await RegistrationApi.acceptClient(registrationId);
    PropertyApi.listRegistrations(property.id).then((r) => setRegistrations(r));
  }

  async function refuseClient(registrationId: string) {
    await RegistrationApi.refuseClient(registrationId);
    PropertyApi.listRegistrations(property.id).then((r) => setRegistrations(r));
  }

  const canPerformAction = user.agencyId === property.agencyId;

  return (
    <div className={"registration-list"}>
      <div className={"header"}>
        {headerTypeValues.map((el, index) => {
          return (
            <div className={"item"} key={index}>
              {capitalizeFirstLetter(el.replaceAll("_", " "))}
            </div>
          );
        })}
        {canPerformAction ? (
          <div className={"item"}>{t("general.actions")}</div>
        ) : null}
      </div>
      {registrations.length <= 0 ? (
        <div className={"flex justify-center mt-4"}>
          {canPerformAction
            ? t("propertyClients.noData")
            : t("propertyClients.noClientRegistered")}
          {}
        </div>
      ) : (
        registrations.map((el, index) => {
          return (
            <div key={index} className={"registration-item"}>
              <div className={"content"}>
                <div className={"item"}>{el.client.firstName}</div>
                <div className={"item"}>{el.client.lastName}</div>
                <div className={"item"}>{el.client.email}</div>
                <div className={"item"}>{el.client.phoneNumber}</div>
                <div className={"item"}>
                  {capitalizeFirstLetter(el.status.replaceAll("_", " "))}
                </div>
                {canPerformAction ? (
                  el.status === RegistrationStatus.CONFIRMED_CLIENT ? (
                    <div className={"item"}>
                      <CheckMark
                        title={t("propertyClients.accept")}
                        onClick={() => acceptClient(el.id)}
                      />
                      <Cross
                        className={"cross"}
                        title={t("propertyClients.refuse")}
                        onClick={() => refuseClient(el.id)}
                      />
                    </div>
                  ) : (
                    <div className={"item"}>-</div>
                  )
                ) : null}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
