import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import "./NotPayed.css";
import { useTranslation } from "react-i18next";
import AgencyApi, { Agency } from "../../api/AgencyApi";

export function NotPayed() {
  const [agency, setAgency] = useState<Agency | null>();
  const { t } = useTranslation();

  useEffect(() => {
    AgencyApi.getCurrent().then((result) => {
      setAgency(result);
    });
  }, []);

  return (
    <div className="payed-upper-container flex">
      <div className="payed-container flex">
        <div className="payed-form">
          <Logo />
          <div className={"payed-message"}>
            {t("notPayed.message", { name: agency?.name })}
          </div>
        </div>
      </div>
    </div>
  );
}
