import "./ReportList.css";
import React, { useEffect, useState } from "react";
import ReportApi, { ReportItem } from "../../api/ReportApi";
import { Navigation } from "../navigation/Navigation";
import { ReactComponent as Ascending } from "../../assets/ascending.svg";
import { ReactComponent as Descending } from "../../assets/descending.svg";
import { useNavigate } from "react-router-dom";
import { dateToReadableFormat } from "../../util/dateToReadableFormat";
import { userToReadableFormat } from "../../util/userToReadableFormat";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa6";
import { toast } from "react-toastify";
import {
  getNextSortingOrder,
  SortingConfig,
} from "../../interfaces/SortingConfig";
import { SortingOrder } from "../../enums/SortingOrder";

enum ReportField {
  AGENCY = "agency",
  PROPERTY_REFERENCE = "propertyReference",
  REASON = "reason",
  AUTHOR = "author",
  TIMESTAMP = "timestamp",
}

function getReportFieldLabel(field: ReportField): string {
  switch (field) {
    case ReportField.AGENCY:
      return "Agency";
    case ReportField.PROPERTY_REFERENCE:
      return "Property reference";
    case ReportField.REASON:
      return "Reason";
    case ReportField.AUTHOR:
      return "Author";
    case ReportField.TIMESTAMP:
      return "Timestamp";
  }
}

export function ReportList({
  reports,
  retrievePage,
  totalPages,
  sortingConfig,
  setSortingConfig,
}: {
  reports: ReportItem[];
  retrievePage: (page: number, sortingConfig?: SortingConfig) => void;
  totalPages: number;
  sortingConfig?: SortingConfig;
  setSortingConfig: (sortingConfig: SortingConfig | undefined) => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);

  function headerClick(field: ReportField) {
    if (sortingConfig && field !== sortingConfig.field) {
      setSortingConfig({
        field,
        order: SortingOrder.ASC,
      });
      return;
    }

    const nextOrder = getNextSortingOrder(sortingConfig?.order);
    if (nextOrder === undefined) {
      setSortingConfig(undefined);
      return;
    }

    setSortingConfig({
      field,
      order: nextOrder,
    });
  }

  useEffect(() => {
    retrievePage(currentPage, sortingConfig);
  }, [currentPage, sortingConfig]);

  return (
    <div className={"report-list"}>
      <div className={"navigation"}>
        <Navigation
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
      <div className={"header"}>
        <div className={"items"}>
          {Object.values(ReportField).map((field) => {
            return (
              <div
                className={"item"}
                onClick={() => headerClick(field)}
                key={field}
              >
                {getReportFieldLabel(field)}
                {sortingConfig?.field === field &&
                sortingConfig?.order === SortingOrder.ASC ? (
                  <Ascending />
                ) : null}
                {sortingConfig?.field === field &&
                sortingConfig?.order === SortingOrder.DESC ? (
                  <Descending />
                ) : null}
              </div>
            );
          })}
          <div className="item small" />
        </div>
      </div>
      {reports.length <= 0 ? (
        <div className={"flex justify-center mt-4"}>
          {t("reportList.noData")}
        </div>
      ) : (
        reports.map((el, index) => {
          return (
            <div
              key={index}
              className={"report-item"}
              onClick={() => navigate(`/property/${el.property.id}`)}
            >
              <div className={"content"}>
                <div className={"item"}>{el.property.agency.name}</div>
                <div className={"item"}>{el.property.reference}</div>
                <div className={"item"}>{el.reason}</div>
                <div className={"item"}>{userToReadableFormat(el.author)}</div>
                <div className={"item"}>
                  {dateToReadableFormat(new Date(el.createdAt))}
                </div>
                <div className="item small">
                  <FaTrash
                    onClick={async (e) => {
                      e.stopPropagation();

                      await toast.promise(
                        ReportApi.removeReport(el.id).then(() => {
                          retrievePage(currentPage);
                        }),
                        {
                          pending: t("reportList.removing"),
                          error: t("reportList.removeFailed"),
                          success: t("reportList.successfullyRemoved"),
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
