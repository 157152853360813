import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropertyFilterParams } from "../../api/PropertyTypes";
import { FilterName } from "../../enums/FilterName";
import "./BooleanRadioGroup.css";

export function BooleanRadioGroup({
  filterName,
  filterParams,
  setFilterParams,
  initialFilterValue,
}: {
  filterName: FilterName;
  filterParams: PropertyFilterParams;
  setFilterParams: Dispatch<SetStateAction<PropertyFilterParams>>;
  initialFilterValue?: boolean;
}) {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState(initialFilterValue || false);

  useEffect(() => {
    setFilterValue(filterParams[filterName] === "true");
  }, [filterParams]);

  return (
    <div className="boolean-filter">
      <label>
        <input
          type="radio"
          className="radio"
          name="boolean-filter"
          checked={filterValue}
          onChange={() => {
            setFilterParams((prev) => ({
              ...prev,
              [filterName]: "true",
            }));
          }}
        />
        {t("filters.yes")}
      </label>
      <label>
        <input
          type="radio"
          className="radio"
          name="boolean-filter"
          checked={!filterValue}
          onChange={() => {
            setFilterParams((prev) => ({
              ...prev,
              [filterName]: "false",
            }));
          }}
        />
        {t("filters.no")}
      </label>
    </div>
  );
}
